import React, { useEffect, useContext, useState } from 'react'
import Sidebar from '../../Sidebar'
import Navbar from '../../Navbar'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import HandleContext from '../../../Context/HandleContext';
import Updatepassword from './Updatepassword';

export default function SpecificUser() {
    let count = 0;
    const url = "https://backend.zerowastecitizen.in/";


    const context = useContext(HandleContext);
    const { GetUserRole, data, GetSpecificUser, specificuser, PostData, States, state, District, district, City, city, Area, area } = context;

    const [apiData, setApiData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        email: '',
        phone_num: "",
        area: '',
        city: '',
        pincode: '',
        district: '',
        state: '',
        country: '',
        states: '',
        area_id: '',
        user_role_id: ''
    });

    useEffect(() => {
        GetSpecificUser("userDetail/get_user", JSON.stringify({ id: Cookies.get('specificuser_id') }))
        GetUserRole("userRole")
        console.log(specificuser);
    }, [])

    useEffect(() => {
        setApiData(specificuser);
        setFormData(specificuser);
    }, [specificuser])

    console.log(specificuser && specificuser.ad7);
    console.log(specificuser && specificuser.country);

    // console.log(formData.states);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('hb');
        console.log(formData);
        console.log(formData.user_role_id);
        console.log(formData.name);
        PostData("userDetail/update_user", formData)
    }

    return (
        <div>
            {/* Update Password Modal */}
            <div className="modal fade" id="staticBackdrop12" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Change User Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Updatepassword />
                    </div>
                </div>
            </div>
            <Navbar />
            <div className='page-content'>
                <Sidebar />
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="page-header page-header-light">
                            <div className="page-header-content header-elements-md-inline">
                                <div className="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                                    <h4> <span style={{ fontSize: '20px' }} className="font-weight-semibold">Citizens</span></h4>
                                </div>
                                <hr style={{ margin: "0px" }} />

                            </div>

                            <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                                <div className="d-flex">
                                    <ul className="breadcrumb"><Link to="/">Home</Link><Link to="/" className="breadcrumb-item"><i style={{ marginRight: '6px' }} className="ph-house-line mr-4"></i> Home </Link><span className="breadcrumb-item active">Citizens</span></ul>

                                    <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className='content'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='row' >
                                        <div className='col-md-12'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='profile_pic' style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <img src={specificuser && url + specificuser.profile_image} style={{ height: '130px', width: '130px', borderRadius: '50%', marginBottom: '15px' }} />

                                                    </div>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div style={{ fontWeight: '600', fontSize: '17px' }}>{specificuser && specificuser.name}</div>
                                                        <div style={{ fontWeight: '600', fontSize: '14px' }}>{specificuser && specificuser.email}</div>
                                                        <div style={{ fontWeight: '600', fontSize: '14px' }}>Balance : <span>{specificuser && specificuser.wallet_balance}</span></div>
                                                        <div></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <h5>Environment Saved</h5>
                                                    <div id="w0" className="grid-view">
                                                        <table
                                                            className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
                                                            id="myTable"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Labels</th>
                                                                    <th>Data</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {specificuser && specificuser.environment_saved_graph_lifetime &&
                                                                    specificuser.environment_saved_graph_lifetime.map((e) => {
                                                                        console.log(e);
                                                                        count++;
                                                                        return (
                                                                            <tr key={e.id} >
                                                                                <td>{count}</td>
                                                                                <td>{e.label}</td>
                                                                                <td>{e.value}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <h5>Bank Details</h5>
                                                    <div className='heads'>
                                                        <span className='lab'>Account No.</span>
                                                        <span className='value'>{specificuser && specificuser.bank_details.account_no}</span>
                                                    </div>
                                                    <div className='heads'>
                                                        <span className='lab'>IFSC Code</span>
                                                        <span className='value'>{specificuser && specificuser.bank_details.ifsc_code}</span>
                                                    </div>
                                                    <div className='heads'>
                                                        <span className='lab'>UPI Id</span>
                                                        <span className='value'>{specificuser && specificuser.bank_details.upi_id}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='heads'>
                                                    <span className='lab'>Name</span>
                                                    <span className='value'>{specificuser && specificuser.name}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>Email</span>
                                                    <span className='value'>{specificuser && specificuser.email}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>Phone Number</span>
                                                    <span className='value'>{specificuser && specificuser.phone_num}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>Address</span>
                                                    <span className='value'>{specificuser && specificuser.address}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>City</span>
                                                    <span className='value'>{specificuser && specificuser.city}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>District</span>
                                                    <span className='value'>{specificuser && specificuser.district}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>State</span>
                                                    <span className='value'>{specificuser && specificuser.state}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='lab'>Country</span>
                                                    <span className='value'>{specificuser && specificuser.country}</span>
                                                </div>
                                      {specificuser && specificuser.additional_fields.ad1 &&
                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad1}</span>
                                                    <span className='value'>{specificuser && specificuser.ad1}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad2 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad2}</span>
                                                    <span className='value'>{specificuser && specificuser.ad2}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad3 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad3}</span>
                                                    <span className='value'>{specificuser && specificuser.ad3}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad4 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad4}</span>
                                                    <span className='value'>{specificuser && specificuser.ad4}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad5 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad5}</span>
                                                    <span className='value'>{specificuser && specificuser.ad5}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad6 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad6}</span>
                                                    <span className='value'>{specificuser && specificuser.ad6}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad7 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad7}</span>
                                                    <span className='value'>{specificuser && specificuser.ad7}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad8 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad8}</span>
                                                    <span className='value'>{specificuser && specificuser.ad8}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad9 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad9}</span>
                                                    <span className='value'>{specificuser && specificuser.ad9}</span>
                                                </div>}
                                                {specificuser && specificuser.additional_fields.ad10 &&

                                                <div className='heads'>
                                                    <span className='lab'>{specificuser && specificuser.additional_fields.ad10}</span>
                                                    <span className='value'>{specificuser && specificuser.ad10}</span>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
