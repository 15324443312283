import Cookies from 'js-cookie';
import React ,{useState,useContext}from 'react'
import HandleContext from '../../../Context/HandleContext'


export default function UpdateRole() {

    const context = useContext(HandleContext);
    const {GetUserRole, PostData} = context;
  
    const [credentials, setCredentials] = useState({ name: "" ,role_id:""})
  
    const updateRole = (e) => {
      console.log("Submitted");
      e.preventDefault();
      console.log(credentials);
      PostData("userRole/update_role", JSON.stringify(credentials))
      setTimeout(() => { 
        GetUserRole("userRole")
        setCredentials({ name: "" })
      }, 200);
    }

  return (
    <form id="w0" onSubmit={updateRole}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className="form-group">
        <label>Role Name</label>
        <input type="text" className="form-control" name="name" placeholder='' value={credentials.name} onChange={(e) => {
          setCredentials({ ...credentials, name: e.target.value ,role_id:Cookies.get('role_id')})
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
  )
}
