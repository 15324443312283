import React, { useEffect, useContext } from 'react'
import Sidebar from './Sidebar'
import logo from '../Images/logo.png'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import HandleContext from '../Context/HandleContext'


export default function Navbar() {

  const context = useContext(HandleContext);
  const { GetAllBankBranch,BranchData,GetBankBranch, bankBranch, PostData } = context;

  useEffect(() => {
    GetAllBankBranch("bankBranch")
  
  }, [])

  const Logout = () => {
    Cookies.remove("num");
    Cookies.remove("id");
    Cookies.remove("areaid");
    Cookies.remove("stateid");
    Cookies.remove("cityid");
    Cookies.remove("city_id");
    Cookies.remove("districtid");
    Cookies.remove("district_id");
    Cookies.remove("countryid");
    Cookies.remove("country_id");
    Cookies.remove("state_id");
    Cookies.remove("product_id");
    Cookies.remove("branch_id");
    Cookies.remove("branch_type_id");
    Cookies.remove("branch_type_id");
    Cookies.remove("update_branch");
    Cookies.remove("area_id");
    Cookies.remove("waste_id");
    Cookies.remove("role_id");
    Cookies.remove("branch");
    Cookies.remove("branch_name");
  }

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  var now = new Date();
  var year = now.getFullYear();
  var month = months[now.getMonth()];
  var day = now.getDate();
  let time = new Date().toLocaleTimeString('en-GB', {
    hour12: true, hour: "numeric",
    minute: "numeric"
  })



  return (
    <div>
      {/* Main navbar */}
      <div className="navbar navbar-light navbar-expand-lg navbar-static border-bottom border-bottom-white border-opacity-10 nav-dash" style={{ margin: '0px', padding: '1px' }}>
        <div className="container-fluid" >
          <div className="d-flex d-lg-none me-2">
            <button type="button" className="navbar-toggler sidebar-mobile-main-toggle rounded-pill">
              <i className="ph-list" />
            </button>
          </div>
          <div className="navbar-brand flex-1 flex-lg-0">
            <Link to="/" className="d-inline-flex align-items-center">
              <img src={logo} alt />
              {/* <img src="../../../assets/images/logo_text_light.svg" className="d-none d-sm-inline-block h-16px ms-3" alt /> */}
            </Link>
          </div>
          <ul className="nav flex-row">

            <div style={{
              color: '#333',
              fontSize: '12px',
              fontWeight: '500'
            }}>
              {/* {day} {month} {year}, {time} */}
            </div>
          </ul>

          <div className="navbar-collapse justify-content-center flex-lg-1 order-2 order-lg-1 collapse" id="navbar_search" style={{
            color: '#333',
            fontSize: '14px',
            fontWeight: '500'
          }}>Welcome <span style={{color:'#367640'}}> &nbsp; {Cookies.get('user_name')} </span>&nbsp;
           {/* To  <span style={{color:'#244661'}}>&nbsp; {Cookies.get('branch_name')} </span> */}
           </div>
          <ul className="nav flex-row justify-content-end order-1 order-lg-2">

            <li className="nav-item ms-lg-2">

              {/* <a href="#" className="navbar-nav-link navbar-nav-link-icon rounded-pill" data-bs-toggle="offcanvas" data-bs-target="#notifications">
                <i className="ph-bell" />
                <span className="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1">2</span>
              </a> */}
              {/* <span className='justify-content-center' style={{ fontWeight: '500' }}>{Cookies.get('branch_name')}</span> */}
            </li>
            <li className="nav-item nav-item-dropdown-lg dropdown  ms-lg-2" style={{ marginRight: '16px' }}>

              <a href="#" className="navbar-nav-link align-items-center rounded-pill p-1" data-bs-toggle="dropdown">
               
              </a>
             {bankBranch && bankBranch.length!=1?
              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                Cookies.set('branch', e.target.value)
                Cookies.remove('fromdate')
                Cookies.remove('todate')
                Cookies.remove('gap')
                window.location.reload();
              }} required >
                <option>-- Select Branch --</option>
                <option value={0}>All Branch</option>
                {bankBranch &&
									bankBranch.map((e, index) => {
										let selected=false;
										if(e.id==Cookies.get('branch')){
											selected=true;
										}
										// Cookies.set("branch_name", e.branch_name);
										return (
											<option key={index} value={e.id} selected={selected}>
												{e.branch_name}
											</option>
										);
									})}
              </select>:''}
            </li>
            <li className="nav-item nav-item-dropdown-lg dropdown ms-lg-2">

              <a href="#" className="navbar-nav-link align-items-center rounded-pill p-1" data-bs-toggle="dropdown">
                {/* <div className="status-indicator-container"> */}
                {/* <img src="../../../assets/images/demo/users/face11.jpg" className="w-32px h-32px rounded-pill" alt /> */}
                {/* <span className="status-indicator bg-success" /> */}
                {/* </div> */}
                <span style={{
                  color: '#333',
                  fontSize: '13px',
                  fontWeight: '500'
                }} className="d-none d-lg-inline-block mx-lg-2 dropdown-toggle">{Cookies.get('user_name')} <i className='nav-group-sub collapse'></i></span>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="/profile" className="dropdown-item">
                  <i className="ph-user-circle me-2" />
                  My profile
                </Link>
                <Link to="/changepassword" className="dropdown-item">
                  <i className="ph-lock me-2" />
                  Change Password
                </Link>
                <Link to="/login" className="dropdown-item" onClick={Logout}>
                  <i className="ph-sign-out me-2" />
                  Logout
                </Link>
                {/* <a href="#" className="dropdown-item">
                  <i className="ph-currency-circle-dollar me-2" />
                  My subscription
                </a>
                <a href="#" className="dropdown-item">
                  <i className="ph-shopping-cart me-2" />
                  My orders
                </a>
                <a href="#" className="dropdown-item">
                  <i className="ph-envelope-open me-2" />
                  My inbox
                  <span className="badge bg-primary rounded-pill ms-auto">26</span>
                </a> */}
                {/* <div className="dropdown-divider" /> */}
                {/* <a href="#" className="dropdown-item">
                  <i className="ph-gear me-2" />
                  Account settings
                </a>
               */}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>

      </div>
      {/* /main navbar */}
      {/* <Sidebar /> */}
    </div>

  )
}
