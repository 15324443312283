import React, { useEffect, useContext } from "react";
import landfill from "../Images/landfill.jpg";
import carbon from "../Images/carbon.jpg";
import paper from "../Images/paper.jpg";
import plastic from "../Images/plastic.jpg";
import water from "../Images/water.png";
import earth from "../Images/earth.jpg";
import miniearth from "../Images/mini/miniearth.png";
import minilandfill from "../Images/mini/minilandfill.jpg";
import miniwater from "../Images/mini/miniwater.png";
import minioil from "../Images/mini/minioil.jpg";
import miniplastic from "../Images/mini/miniplastic.jpg";
import minipaper from "../Images/mini/minipaper.jpg";
import Cookies from "js-cookie";
import HandleContext from "../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Carousel from "react-bootstrap/Carousel";

export default function EnvSavedGraph() {
	// $('.carousel').carousel({
	//   interval: 2000
	// })
	let navigate = useNavigate();
	const context = useContext(HandleContext);
	const { Dashboard, dashboard, drilldown, barchart } = context;

	useEffect(() => {
		Dashboard("dashboard", JSON.stringify({ branch: Cookies.get("branch"),fromdate:Cookies.get('fromdate') ,todate:Cookies.get('todate') ,gap:Cookies.get('gap')}));
	}, []);
	// console.log(dashboard && dashboard);
	// dashboard ?
	// console.log( dashboard.environment_saved_graph) :// console.log('nothing to print');
	// console.log(dashboard && dashboard.environment_saved_graph);
	return (
		<>
			{dashboard && dashboard.environment_saved_graph.length !== 0 && (
				<div
					className="card"
					style={{
						height: "400px",
						justifyContent: "center",
						alignItems: "center",
						padding: "0",
					}}
				>
					<div className="card-body" style={{ width: "100%" }}>
						<Carousel controls={false}>
							<Carousel.Item>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									Environmental Savings
								</span>
								<img
									src={plastic}
									className="d-block w-100"
									alt="..."
									style={{
										margin: "8px 0px",
										height: "250px",
										textAlign: "center",
									}}
								/>
								<span
									style={{
										fontWeight: "600",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
										color: "red",
									}}
								>
									{dashboard.environment_saved_graph.data[0]} Kgs
								</span>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph &&
										dashboard.environment_saved_graph.scaleLabels[0]} Recycled
								</span>
							</Carousel.Item>
							<Carousel.Item>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									Environmental Savings
								</span>
								<img
									src={paper}
									className="d-block w-100"
									alt="..."
									style={{
										margin: "8px 0px",
										height: "250px",
										textAlign: "center",
									}}
								/>
								<span
									style={{
										fontWeight: "600",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
										color: "red",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.data[1]}{" "}
									Kgs
								</span>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.scaleLabels[1]} Recycled
								</span>
							</Carousel.Item>
							<Carousel.Item>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									Environmental Savings
								</span>
								<img
									src={carbon}
									className="d-block w-100"
									alt="..."
									style={{
										margin: "12px 0px",
										height: "250px",
										textAlign: "center",
									}}
								/>
								<span
									style={{
										fontWeight: "600",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
										color: "red",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.data[2]} Kgs
								</span>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.scaleLabels[2]} 
								</span>
							</Carousel.Item>
							<Carousel.Item>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									Environmental Savings
								</span>
								<img
									src={water}
									className="d-block w-100"
									alt="..."
									style={{
										margin: "12px 0px",
										height: "250px",
										textAlign: "center",
									}}
								/>
								<span
									style={{
										fontWeight: "600",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
										color: "red",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.data[3]} Ltr
								</span>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.scaleLabels[3]} 
								</span>
							</Carousel.Item>
							<Carousel.Item>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									Environmental Savings
								</span>
								<img
									src={landfill}
									className="d-block w-100"
									alt="..."
									style={{
										margin: "12px 0px",
										height: "250px",
										textAlign: "center",
									}}
								/>
								<span
									style={{
										fontWeight: "600",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
										color: "red",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.data[4]}{" "}
									Cubic Feet
								</span>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "15px",
										alignItems: "center",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{dashboard &&
										dashboard.environment_saved_graph.length !== 0 &&
										dashboard.environment_saved_graph.scaleLabels[4]}
								</span>
							</Carousel.Item>
						
						

							{/* <Carousel.Item>
								<div className="row">
									<div className="col-md-6">
										<img
											src={miniplastic}
											className="d-block w-100"
											alt="..."
											style={{
												margin: "12px 0px",
												height: "60px",
												textAlign: "center",
											}}
										/>
										<span
											style={{
												fontWeight: "500",
												color: "red",
												fontSize: "10px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.data[0]}{" "}
											Kgs
										</span>
										<span
											style={{
												fontWeight: "bold",
												fontSize: "12px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.scaleLabels[0]}
										</span>
									</div>
									<div className="col-md-6">
										<img
											src={minipaper}
											className="d-block w-100"
											alt="..."
											style={{
												margin: "12px 0px",
												height: "60px",
												textAlign: "center",
											}}
										/>
										<span
											style={{
												fontWeight: "500",
												color: "red",
												fontSize: "10px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.data[1]}{" "}
											Cubic Feet
										</span>
										<span
											style={{
												fontWeight: "bold",
												fontSize: "12px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.scaleLabels[1]}
										</span>
									</div>
								</div>
								<div className="row" style={{ marginTop: "50px" }}>
									<div className="col-md-6">
										<img
											src={minilandfill}
											className="d-block w-100"
											alt="..."
											style={{
												margin: "8px 0px",
												height: "60px",
												textAlign: "center",
											}}
										/>
										<span
											style={{
												fontWeight: "500",
												color: "red",
												fontSize: "10px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.data[4]}{" "}
											Ltr
										</span>
										<span
											style={{
												fontWeight: "bold",
												fontSize: "12px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.scaleLabels[4]}
										</span>
									</div>
									<div className="col-md-6">
										<img
											src={miniwater}
											className="d-block w-100"
											alt="..."
											style={{
												margin: "8px 0px",
												height: "60px",
												textAlign: "center",
											}}
										/>
										<span
											style={{
												fontWeight: "500",
												color: "red",
												fontSize: "10px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.data[3]} Ltr
										</span>
										<span
											style={{
												fontWeight: "bold",
												fontSize: "12px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.scaleLabels[3]}
										</span>
									</div>
									<div className="col-md-6">
										<img
											src={minioil}
											className="d-block w-100"
											alt="..."
											style={{
												margin: "8px 0px",
												height: "60px",
												textAlign: "center",
											}}
										/>
										<span
											style={{
												fontWeight: "500",
												color: "red",
												fontSize: "10px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.data[4]} 
										</span>
										<span
											style={{
												fontWeight: "bold",
												fontSize: "12px",
												alignItems: "center",
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
											}}
										>
											{dashboard &&
												dashboard.environment_saved_graph.length !== 0 &&
												dashboard.environment_saved_graph.scaleLabels[4]}
										</span>
									</div>
								</div>
							</Carousel.Item> */}
						</Carousel>
					</div>
				</div>
			)}
		</>
	);
}
