import React, { useState, useContext, useEffect } from "react";
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Step5() {
	const context = useContext(HandleContext);
	const { GetWasteProcess, wasteprocess, PostData } = context;

	useEffect(() => {
		GetWasteProcess("wasteProcess");
	}, []);

	console.log(wasteprocess);

	const [credentials, setcredentials] = useState({ branch_id: "" });
	const [selectedValues, setSelectedValues] = useState([]);

	function handleCheckboxChange(event) {
		setcredentials({
			...credentials,
			branch_id: Cookies.get("branch_type_id"),
		});
		const value = event.target.value;

		if (event.target.checked) {
			setSelectedValues([...selectedValues, value]);
		} else {
			setSelectedValues(selectedValues.filter((val) => val !== value));
		}
	}

	function letsGo() {
		navigate("/bankbranch");
	}

	function handleSubmit(event) {
		event.preventDefault();
		console.log(selectedValues);
		PostData(
			"bankBranch/assign_waste_type",
			JSON.stringify({
				branch_id: credentials.branch_id,
				waste_type: selectedValues,
			}),
			letsGo
		);
	}

	let navigate = useNavigate();

	return (
		<div className="card">
			<div className="card-body">
				<h5>Select Waste Process Applicable In The Branch</h5>
				<div>
					<form id="w0" className="row" onSubmit={handleSubmit}>
						{/* <div className="modal-body"> */}
						<input
							type="hidden"
							name="_csrf-frontend"
							defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
						/>

						{wasteprocess &&
							wasteprocess.map((e) => {
								console.log(e);
								return (
									<div className="col-md-4" style={{ marginBottom: "20px" }}>
										<input
											type="checkbox"
											id="vehicle2"
											name={e.process_name}
											value={e.id}
											onChange={handleCheckboxChange}
										/>
										<label style={{ marginLeft: "5px" }}>
											{e.process_name}
										</label>
									</div>
								);
							})}

						{/* </div> */}
						{/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
						<button type="submit" className="btn btn-primary">
							Submit
						</button>
						{/* </div> */}
					</form>
				</div>
			</div>
		</div>
	);
}
