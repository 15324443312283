import React, { useState ,useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import HandleContext from '../../../Context/HandleContext'


export default function AddBranchTypes() {
    const context = useContext(HandleContext);
    const {  PostData,GetAllBranchType} = context;
    const [credentials, setCredentials] = useState({ branch_type: "", ad1: "", ad2: "", ad3: "", ad4: "", ad5: "", ad6: "", ad7: "", ad8: "", ad9: "", ad10: "" })

    let navigate = useNavigate()
    const handleOnchange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const addBranch = (e) => {
        console.log("Submitted");
        e.preventDefault();
        console.log(credentials);
        PostData("branchType/add_branchType", JSON.stringify(credentials))
        setTimeout(() => { 
            GetAllBranchType("branchType")
        }, 200);
      }

    return (
        <form onSubmit={addBranch}>
            <div className="modal-body">
                <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />

                <div className="form-group ">
                    <label>Type of Branch</label>
                    <input type="text" className="form-control" placeholder='' value={credentials.branch_type} name="branch_type" onChange={handleOnchange} required />
                </div>

                <h5>Select Additional Feilds</h5>
                <div className='row'>
                    <div className="form-group col-md-6">
                        <label>Field One </label>
                        <input type="text" className="form-control" name="ad1" value={credentials.ad1} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Two</label>
                        <input type="text" className="form-control" name="ad2" value={credentials.ad2} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Three</label>
                        <input type="text" className="form-control" name="ad3" value={credentials.ad3} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Four </label>
                        <input type="text" className="form-control" name="ad4" value={credentials.ad4} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Five</label>
                        <input type="text" className="form-control" name="ad5" value={credentials.ad5} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Six</label>
                        <input type="text" className="form-control" name="ad6" value={credentials.ad6} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Seven</label>
                        <input type="text" className="form-control" name="ad7" value={credentials.ad7} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Eight</label>
                        <input type="text" className="form-control" name="ad8" value={credentials.ad8} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Nine</label>
                        <input type="text" className="form-control" name="ad9" value={credentials.ad9} onChange={handleOnchange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Field Ten</label>
                        <input type="text" className="form-control" name="ad10" value={credentials.ad10} onChange={handleOnchange} />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
        </form>

    )
}
