import React,{useEffect,useContext,useState} from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { Link,useNavigate } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import Cookies from 'js-cookie'

export default function AddArea() {

  let navigate=useNavigate()
  const context = useContext(HandleContext);
  const { GetData, data, States, state, PostData,Area} = context;

  const [credentials, setCredentials] = useState({ name: "", city_id: "" })


  const addArea = (e) => {
    e.preventDefault();
    console.log("Submitted");
    console.log(credentials);
    PostData("area/add_area", JSON.stringify(credentials))
    setTimeout(() => { 
      Area(JSON.stringify({ city_id: Cookies.get('city_id') }))
      setCredentials({name:""})
      // window.location.reload();
    }, 200);
  }
  return (
    <form id="w0" onSubmit={addArea}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className="form-group">
        <label>Area Name</label>
        <input type="text" className="form-control" name="name" value={credentials.name} placeholder='' onChange={(e) => {
          setCredentials({ ...credentials, name: e.target.value ,city_id:Cookies.get('city_id')})
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
  )
}
