import Cookies from 'js-cookie';
import React, { useEffect, useContext } from 'react'
import HandleContext from '../../../Context/HandleContext'
import { useNavigate } from 'react-router-dom';
import UpdateBranch from './UpdateBranch';
import $ from 'jquery'

export default function AllBankBranch() {
  $(document).ready(function () {
    $('#myTable4').DataTable();
  });
  let navigate = useNavigate()
  let nav = () => {
    navigate('/addbranch')
  }

  const certificate = async (id) => {
    // Set the cookie value
    await Cookies.set('branch_certificate_id', id);

    // Navigate to a new page
    navigate('/branchcertificate');
  };

  const handleButtonClick = (id) => {
    // Update the cookie with a new value and get the updated value
    const updatedCookieValue = Cookies.set('branch_id', id);
    // Check if the updated cookie value is not null or undefined
    if (updatedCookieValue) {
      // Set the cookie value state to the updated value
      // Navigate to a new page
      // history.push('/new-page');
      navigate('/getbranch')
    } else {
      // Log an error message or display an error to the user
      console.error('Failed to update cookie');
    }
  };
  const context = useContext(HandleContext);
  const { GetAllBankBranch, bankBranch, PostData } = context;

  useEffect(() => {
    GetAllBankBranch("bankBranch")
  }, [])
  let count = 0;
  return (
    <div>
      {/* update */}
      <div class="modal fade" id="staticBackdrop22" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update Branch Type</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UpdateBranch />
          </div>
        </div>
      </div>
      <div className='card' style={{ marginTop: '30px' }}>

        {/* Table with togglable columns */}
        {bankBranch ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>   All list of <code>Bank Branches</code></div>
            <div style={{ cursor: 'pointer' }} onClick={nav} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Branch</div>

          </div>
          <div id="w0" className="grid-view">
            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable4">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Branch Name</th>
                  <th>Branch Contact</th>
                  <th>Branch Email</th>
                  <th>Branch Address</th>
                  <th>Branch Type</th>
                  <th>Certificate</th>
                  <th className="action-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {bankBranch && bankBranch.map((e) => {
                  // console.log(e);
                  count++;
                  return <tr key={e.id} >
                    <td onClick={() => { handleButtonClick(e.id) }}>{count}</td>
                    <td onClick={() => { handleButtonClick(e.id) }}>{e.branch_name}</td>
                    <td onClick={() => { handleButtonClick(e.id) }}>{e.branch_contact}</td>
                    <td onClick={() => { handleButtonClick(e.id) }}>{e.branch_email}</td>
                    <td onClick={() => { handleButtonClick(e.id) }}>{e.branch_address}</td>
                    <td onClick={() => { handleButtonClick(e.id) }}>{e.branch_type}</td>
                    <td
                        onClick={() => {
                          certificate(e.id)
                          Cookies.set('branch_certificate_id', e.id)
                        }} ><button title='View Certificate' className='btn btn-success'><i className='icon-file-text3
                    '></i></button></td>
                    <td onClick={() => { handleButtonClick(e.id) }}>
                     
                      <button  className='btn btn-success btn-sm'

                        style={{ marginRight: '6px' }}  ><i className='ph-note-pencil'></i></button>

                    </td>
                    
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
    </div>
  )
}
