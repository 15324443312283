import React, { useEffect, useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import $ from 'jquery'


export default function Aggregators() {

  const handleButtonClick = async (id) => {
    // Set the cookie value
    await Cookies.set('specificuser_id', id);
  
    // Navigate to a new page
    navigate('/specificuser');
  };

  setTimeout(() => {
    let searchid=document.getElementById('myTable1_filter')
    let id=document.getElementById('myTable1_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);
  $(document).ready(function () {
    $('#myTable1').DataTable();
  });

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const {BranchUsers, bankbranchusers } = context;

  useEffect(() => {
    BranchUsers("dashboard/users",JSON.stringify({role:"aggregators",branch:Cookies.get('branch')}))
  }, [])

  console.log(bankbranchusers);

  let count = 0;

 
 
  return (
    <div>
        <div>
     <Navbar />
     <div className='page-content'>
       <Sidebar />
       <div class="page-content">
         <div class="content-wrapper">
           <div class="page-header page-header-light">
             <div class="page-header-content header-elements-md-inline">
               <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                 <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">Aggregators</span></h4>
    
                 <div class="header-elements ">
        
                 </div>
               </div>
               <hr style={{ margin: "0px" }} />
    
             </div>
    
             <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
               <div class="d-flex">
                 <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Aggregators</span></ul>
    
                 <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
               </div>
             </div>
           </div>
           <div className='content'>
           <>
      <div className='card'>
       
        {bankbranchusers ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>   All list of <code>Aggregators</code></div>
            
          </div>
          <div id="w0" className="grid-view">
            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable1">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Users</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Role Name</th>
                  <th>Pincode</th>
                  <th>State</th>
                  <th>City</th>
                  <th>District</th>
                  <th>Area</th>
                  <th className="action-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {bankbranchusers && bankbranchusers.map((e) => {
                  console.log(e);
                  count++;
                  return <tr key={e.id} onClick={() => { handleButtonClick(e.id) }}>
                    <td>{count}</td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.phone_num}</td>
                    <td>{e.address}</td>
                    <td>{e.role_name}</td>
                    <td>{e.pincode}</td>
                    <td>{e.state}</td>
                    <td>{e.city}</td>
                    <td>{e.district}</td>
                    <td>{e.area}</td>
                   
                  
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
    </>
           </div>
         </div>
       </div>
     </div>
    </div>
    </div>
   
  )
}
