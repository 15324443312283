import React, { useEffect, useContext, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
// import Content from '../Content'
import { Link } from "react-router-dom";
import HandleContext from "../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import DataTables from "datatables.net-dt";

export default function ViewProfile() {

    const url = 'https://api.zerowastecitizen.in/';

    const context = useContext(HandleContext);
    const { GetSpecificUser, specificuser, Profile, profile, } = context;

    useEffect(() => {
        GetSpecificUser("transaction/get_transfer", JSON.stringify({ id: Cookies.get('transfer_id') }))
    }, [])

    console.log(specificuser);
    let count = 0;
    let count1 = 0;
    return (
        <div>
            <div>
                <Navbar />
                <div className="page-content">
                    <Sidebar />
                    <div class="page-content">
                        <div class="content-wrapper">
                            <div class="page-header page-header-light">
                                <div class="page-header-content header-elements-md-inline">
                                    <div
                                        class="page-title d-flex"
                                        style={{ justifyContent: "space-between" }}
                                    >
                                        <h4>
                                            {" "}
                                            <span
                                                style={{ fontSize: "18px" }}
                                                class="font-weight-semibold"
                                            >
                                                User Profile

                                            </span>
                                        </h4>

                                        <div class="header-elements "></div>
                                    </div>
                                    <hr style={{ margin: "0px" }} />
                                </div>

                                <div
                                    class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
                                    style={{
                                        paddingLeft: "14px",
                                        marginBottom: "6px",
                                        paddingRight: "20px",
                                    }}
                                >
                                    <div class="d-flex">
                                        <ul class="breadcrumb">
                                            <Link to="/">Home</Link>
                                            <Link to="/" class="breadcrumb-item"> <i
                                                style={{ marginRight: "6px" }}
                                                class="ph-house-line mr-4" ></i>
                                                Home
                                            </Link>
                                            <span class="breadcrumb-item active">User Profile</span>
                                        </ul>

                                        <a
                                            href="#"
                                            class="header-elements-toggle text-default d-md-none"
                                        >
                                            <i className="icon-more"></i>
                                        </a>
                                        <div style={{ flexGrow: '1' }}></div>

                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">


                                                        <div className='heads'>
                                                            <span className='label'>Name</span>
                                                            <span className='value'>{profile && profile.name}</span>
                                                        </div>
                                                        <div className='heads'>
                                                            <span className='label'>Email</span>
                                                            <span className='value'>{profile && profile.email}</span>
                                                        </div>
                                                        <div className='heads'>
                                                            <span className='label'>Phone No.</span>
                                                            <span className='value'>{profile && profile.phone_num}</span>
                                                        </div>
                                                        <div className='heads'>
                                                            <span className='label'> Address</span>
                                                            <span className='value'>{profile && profile.address}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">


                                                        <div className='heads'>
                                                            <span className='label'>Area</span>
                                                            <span className='value'>{profile && profile.area}</span>
                                                        </div>
                                                        <div className='heads'>
                                                            <span className='label'>District</span>
                                                            <span className='value'>{profile && profile.district}</span>
                                                        </div>
                                                        <div className='heads'>
                                                            <span className='label'>City</span>
                                                            <span className='value'>{profile && profile.city}</span>
                                                        </div>
                                                        <div className='heads'>
                                                            <span className='label'> State</span>
                                                            <span className='value'>{profile && profile.state}</span>
                                                    </div>
                                                        {/* <div className='heads'>
                                                            <span className='label'> Country</span>
                                                            <span className='value'>{profile && profile.country}</span>
                                                    </div> */}
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
