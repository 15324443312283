import Cookies from 'js-cookie';
import React, { useState, useContext, useEffect } from 'react'
import HandleContext from '../../../Context/HandleContext'

export default function UpdateWaste() {
    const context = useContext(HandleContext);
    const {GetAllWaste, GetAllWasteType, allWasteType, GetAllUnits, allUnits, GetGSt, gst,GetWasteCategory,wastecategory, PostData } = context;

    useEffect(() => {
        GetAllWasteType("product/waste_type")
        GetAllUnits("product/units")
        GetGSt("product/gst_slabs")
        GetWasteCategory("category")
    }, [])

    const updateWaste = (e) => {
        console.log("Submitted");
        e.preventDefault();
        console.log(credentials);
        PostData("product/update_product", JSON.stringify(credentials))
        setTimeout(() => { 
            GetAllWaste("product")
        }, 200);
      }

    const [credentials, setCredentials] = useState({id:"", category_id: "", product_name: "", unit_id: "", waste_type_id: "", sale_price: "", purchase_price: "", gst_slab_id: "" })
    return (
        <form id="w0" className='row' onSubmit={updateWaste}>
            <div className="modal-body">
                <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
                <div className="form-group col-md-12">
                    <label>Select Waste Category</label>
                    <select id="usercities-state" class="form-control select-search" name="category_id" onChange={(e) => {
                        setCredentials({ ...credentials, category_id: e.target.value })
                    }} required >
                        <option >-- Select Waste Category --</option>
                        {wastecategory && wastecategory.map((e) => {
                            return <option value={e.id}>{e.category_name}</option>
                        })}
                    </select>
                </div>
                <div className="form-group col-md-12">
                    <label>Name Of Waste</label>
                    <input type="text" className="form-control" name="product_name" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, product_name: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-12">
                    <label>Select Unit of Measure</label>
                    <select id="usercities-state" class="form-control select-search" name="unit_id" onChange={(e) => {
                        setCredentials({ ...credentials, unit_id: e.target.value ,id:Cookies.get('waste_id')})
                    }} required >
                        <option >-- Select Unit of Measure --</option>
                        {allUnits && allUnits.map((e) => {
                            return <option value={e.id}>{e.unit}</option>
                        })}

                    </select>
                </div>
                <div className="form-group col-md-12">
                    <label>Select Waste Type</label>
                    <select id="usercities-state" class="form-control select-search" name="waste_type_id" onChange={(e) => {
                        setCredentials({ ...credentials, waste_type_id: e.target.value })
                    }} required >
                        <option >-- Select Waste Type --</option>
                        {allWasteType && allWasteType.map((e) => {
                            return <option value={e.id}>{e.waste}</option>
                        })}

                    </select>
                </div>
                <div className="form-group col-md-12">
                    <label>Sale Price </label>
                    <input type="text" className="form-control" name="sale_price" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, sale_price: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-12">
                    <label>Purchase Price</label>
                    <input type="text" className="form-control" name="purchase_price" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, purchase_price: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-12">
                    <label>Select GST</label>
                    <select id="usercities-state" class="form-control select-search" name="gst_slab_id" onChange={(e) => {
                        setCredentials({ ...credentials, gst_slab_id: e.target.value })

                    }} required >
                        <option >-- Select GST --</option>
                        {gst && gst.map((e) => {
                            return <option value={e.gst}>{e.gst}%</option>
                        })}
                    </select>
                </div>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>

                <button type="submit" className="btn btn-primary "  >Submit</button>
            </div>
        </form>
    )
}
