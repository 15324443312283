import React, { useEffect, useContext, useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import AddDistrict from './AddDistrict'
import UpdateDistrict from './UpdateDistrict'

export default function District() {

  const context = useContext(HandleContext);
  const {  States, state, District, district, PostData } = context;

  useEffect(() => {
    States(JSON.stringify({ username: '' }))
  }, [])


  const Delete = (id) => {
    PostData('/district/delete_district', JSON.stringify({ district_id: id }))
    setTimeout(() => {
      District(JSON.stringify({ state_id: Cookies.get('state_id') }))
    }, 200);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitted");
    District(JSON.stringify({ state_id: Cookies.get('state_id') }))
  }


  let count = 0;

  return (
    <>

      <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add District</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddDistrict />
          </div>
        </div>
      </div>

      {/*Update District Modal */}
      <div class="modal fade" id="staticBackdrop2" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update District</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UpdateDistrict />
          </div>
        </div>
      </div>


      <div className='card' style={{ marginTop: '30px' }}>
        <div className='card-header'>
          <form id="w0" onSubmit={handleSubmit}>
            <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
            <div className='row'>
              {/* <div className='col-md-5'> */}
              {/* <div className="form-group">
              <label className='label'>Select Country</label>
              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e)=>{
                    Cookies.set('country_id',e.target.value)
                    States( JSON.stringify({ country_id: Cookies.get('country_id') }))
              }}  required >
                <option >India</option>
                {data && data.map((e)=>{
                  console.log(e);
                  return <option value={e.id}>{e.name}</option>
                })}
              </select>
            </div> */}
              {/* </div> */}
              <div className='col-md-6'>
                <div className="form-group">
                  <label className='label'>Select State</label>
                  <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                    Cookies.set('state_id', e.target.value)
                  }} required >
                    <option>-- Select State --</option>
                    {state && state.map((e) => {
                      // console.log(e);
                      return <option value={e.id}>{e.name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-2' style={{ marginTop: "22px" }}>

                <button type='submit' className="btn btn-success">Submit</button>
              </div>

            </div>
          </form>
        </div>
        {/* </div> */}

        {/* Table with togglable columns */}
        {district ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>   All list of <code>District</code></div>
            <div style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#staticBackdrop"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add District</div>

          </div>
          <div id="w0" className="grid-view">
            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="DataTables_Table_0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>District</th>
                  <th className="action-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {district && district.map((e) => {
                  // console.log(e);
                  count++;
                  return <tr key={e.id}>
                    <td>{count}</td>
                    <td>{e.name}</td>
                    <td >
                      <button className='btn btn-success btn-sm' onClick={() => {
                        Cookies.set('districtid', e.id)
                        console.log(e.id);
                      }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop2"><i className='ph-note-pencil'></i></button>
                      <button className='btn btn-danger btn-sm' onClick={() => {
                        console.log(e.id);
                        Delete(e.id)
                      }}><i className='ph-trash'></i></button>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
      {/* /table with togglable columns */}



    </>
  )
}
