import React, { useState, useContext, useEffect } from 'react'
import HandleContext from '../../../Context/HandleContext'
import Cookies from 'js-cookie';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import { Link } from 'react-router-dom';

export default function AddUser() {

  const context = useContext(HandleContext);
  const {GetUser, GetUserRole, data, PostData, States, state, District, district, City, city, Area, area } = context;

  const [credentials, setCredentials] = useState({ name: "", email: "", phone_num: "", area_id: "", role_id: "", password: "", confirm_password: "", address: "" })

  useEffect(() => {
    States()
    GetUserRole("userRole")
  }, [])

  const handleOnchange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  const addUser = (e) => {
    console.log("Submitted");
    e.preventDefault();
    console.log(credentials);
    PostData("userDetail/add_user", JSON.stringify(credentials))
    setTimeout(() => {
      GetUser("userDetail")
      setCredentials({ name: "", email: "", phone_num: "", area_id: "", role_id: "", password: "", confirm_password: "", address: "" })
    }, 200);
  }

  return (
    <div>
    <div>
 <Navbar />
 <div className='page-content'>
   <Sidebar />
   <div class="page-content">
     <div class="content-wrapper">
       <div class="page-header page-header-light">
         <div class="page-header-content header-elements-md-inline">
           <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
             <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">User Management</span></h4>

             <div class="header-elements ">
               {/* <div class="d-flex justify-content-center">
           <Link class="btn btn-link btn-float text-default" to="/addarea"><i class="ph-plus-circle text-primary"></i>
           <div style={{color:'black',fontWeight:'500'}}>Add Area</div></Link>    
                       </div> */}
             </div>
           </div>
           <hr style={{ margin: "0px" }} />

         </div>

         <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
           <div class="d-flex">
             <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">User Management</span></ul>

             <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
           </div>
         </div>
       </div>
       <div className='content'>
       <div className='card'>
    
       <div className='card-body'>
       <form id="w0" onSubmit={addUser}>
      {/* <div className="modal-body"> */}
        <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
        <div className='row'>
          <div className="form-group col-md-4">
            <label>User Name</label>
            <input type="text" className="form-control" name="name" onChange={handleOnchange} placeholder='' required />
          </div>
          <div className="form-group col-md-4">
            <label>Email</label>
            <input type="email" className="form-control" name="email" onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Phone Number</label>
            <input type="text" className="form-control" name="phone_num" onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Password</label>
            <input type="password" className="form-control" name="password" onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Confirm Password</label>
            <input type="password" className="form-control" name="confirm_password" onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label className=''>Select User Role</label>
            <select id="usercities-state" class="form-control select-search" name="role_id" onChange={(e) => {
              setCredentials({ ...credentials, role_id: e.target.value })
            }} required >
              <option>-- Select User Role --</option>
              {data && data.map((e) => {
                // console.log(e);
                return <option value={e.id}>{e.role_name}</option>
              })}
            </select>

          </div>
          <div className='col-md-4'>
            <div className="form-group">
              <label className=''>Select State</label>
              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                Cookies.set('state_id', e.target.value)
                District(JSON.stringify({ state_id: Cookies.get('state_id') }))
              }} required >
                <option>-- Select State --</option>
                {state && state.map((e) => {
                  // console.log(e);
                  return <option value={e.id}>{e.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className='col-md-4'>
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <label className=''>Select District</label>

              </div>
              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                Cookies.set('district_id', e.target.value)
                City(JSON.stringify({ district_id: Cookies.get('district_id') }))
              }} required >
                <option>-- Select District --</option>
                {district && district.map((e) => {
                  console.log(e);
                  return <option value={e.id}>{e.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className='col-md-4'>
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <label className=''>Select City</label>
              </div>              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                Cookies.set('city_id', e.target.value)
                Area(JSON.stringify({ city_id: Cookies.get('city_id') }))

              }} required >
                <option>-- Select City --</option>
                {city && city.map((e) => {
                  console.log(e);
                  return <option value={e.id}>{e.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <label className=''>Select Area</label>
              </div>
              <select id="usercities-state" class="form-control select-search" name="area_id" onChange={(e) => {
              setCredentials({ ...credentials, area_id: e.target.value })
            }} required >
                <option>-- Select Area --</option>
                {area && area.map((e) => {
                  console.log(e);
                  return <option value={e.id}>{e.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label>Address</label>
            <input type="text" className="form-control"  name="address" onChange={handleOnchange} required />
          </div>
         
        </div>
      {/* </div> */}
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
        <button type="submit" className="btn btn-primary">Add</button>
      {/* </div> */}
    </form>
       </div>
       </div>
       </div>
     </div>
   </div>
 </div>
</div>
</div>
  )
}
