import React ,{useState,useContext}from 'react'
import HandleContext from '../../Context/HandleContext'

export default function AddProcess() {

    const context = useContext(HandleContext);
    const {GetWasteProcess, PostData} = context;
  
    const [credentials, setCredentials] = useState({ process_name: "" ,machine_name:""})
  
    const addProcess = (e) => {
      console.log("Submitted");
      e.preventDefault();
      console.log(credentials);
      PostData("wasteProcess/add_process", JSON.stringify(credentials))
      setTimeout(() => { 
        GetWasteProcess("wasteProcess")
        setCredentials({ process_name: "" ,machine_name:""})
          }, 200);
    }

  return (
    <form id="w0" onSubmit={addProcess}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className="form-group">
        <label>Process Name</label>
        <input type="text" className="form-control" name="process_name" value={credentials.process_name} onChange={(e) => {
          setCredentials({ ...credentials, process_name: e.target.value })
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
      <div className="form-group">
        <label>Solution Name</label>
        <input type="text" className="form-control" name="machine_name" value={credentials.machine_name}onChange={(e) => {
          setCredentials({ ...credentials, machine_name: e.target.value })
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
  )
}
