import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import Login from "./Components/Login";
import ForgotPassword from "./Components/ForgotPassword";
import HandleState from "./Context/HandleState";
import Profile from "./Components/Profile";
import Dashboard from "./Components/Dashboard";
import City from "./Components/City/City";
import Country from "./Components/Country/Country";
import District from "./Components/District/District";
import State from "./Components/State/State";
import Area from "./Components/Area/Area";
import ChangePassword from "./Components/ChangePassword";
import AreaMaster from "./Components/AreaMaster";
import Navs from "./Components/Navs";
import UserMaster from "./Components/Users/UserMaster";
import SpecificUser from "./Components/Users/User/SpecificUser";
import WasteManagement from "./Components/Waste/WasteManagement";
import AddWaste from "./Components/Waste/AllWaste/AddWaste";
import Step2 from "./Components/Waste/AllWaste/Step2";
import BankBranch from "./Components/Bank Branch/BankBranch";
import WasteCategory from "./Components/Waste/WasteCategory/WasteCategory";
import AllWaste from "./Components/Waste/AllWaste/AllWaste";
import AddBranchTypes from "./Components/Bank Branch/Branch Types/AddBranchTypes";
import GetBranch from "./Components/Bank Branch/Branches/GetBranch";
import Branches from "./Components/Bank Branch/Branches/Branches";
import UserRoles from "./Components/Users/UserRoles/UserRoles";
import AllUser from "./Components/Users/User/AllUser";
import Citizens from "./Components/BranchUsers/Citizens";
import Aggregators from "./Components/BranchUsers/Aggregators";
import Recyclers from "./Components/BranchUsers/Recyclers";
import Warriors from "./Components/BranchUsers/Warriors";
import Brands from "./Components/BranchUsers/Brands";
import Collectors from "./Components/BranchUsers/Collectors";
import Excel from "./Components/Excel";
import AddSignature from "./Components/AddSignature";
import Transitions from "./Components/Transitions/Transitions";
import Deposit from "./Components/Transitions/Deposits/Deposit";
import UploadDeposits from "./Components/Transitions/Deposits/UploadDeposits";
import AddUser from "./Components/Users/User/AddUser";
import AddDeposit from "./Components/Transitions/Deposits/AddDeposit";
import AddCitizens from "./Components/BranchUsers/AddCitizens";
import DepositDetail from "./Components/Transitions/Deposits/DepositDetail";
import StockTransfer from "./Components/Transitions/Stock/StockTransfer";
import Transfer from "./Components/Transitions/Stock/Transfer";
import TransferDetails from "./Components/Transitions/Stock/TransferDetails";
import UploadTransfer from "./Components/Transitions/Stock/UploadTransfer";
import Journal from "./Components/Transitions/Journal/Journal";
import ViewProfile from "./Components/ViewProfile";
import WasteCollection from "./Components/Reports/WasteCollection";
import Enviourment from "./Components/Reports/Enviourment";
import Collectionwise from "./Components/Reports/Collectionwise";
import Certificate from "./Components/BranchUsers/Certificate";
import All from "./Components/RedeemRequests/All";
import Rejected from "./Components/RedeemRequests/Rejected";
import Completed from "./Components/RedeemRequests/Completed";
import Pending from "./Components/RedeemRequests/Pending";
import BranchCertificate from "./Components/Bank Branch/Branches/BranchCertificate";
import AdvancedReports from "./Components/Reports/AdvancedReports";
import AllCitizens from "./Components/BranchUsers/AllCitizens";


function App() {
	const data = [
		{ name: "John", age: 25 },
		{ name: "Jane", age: 30 },
		{ name: "Bob", age: 40 },
	];

	return (
		<div>
			<div className="App">
				{/* <Excel data={data} fileName="myData" /> */}
				<HandleState>
					<BrowserRouter>
						<Routes>
							<Route exact path="/navs" element={<Navs />}></Route>
							<Route exact path="/areamaster" element={<AreaMaster />}></Route>
							<Route exact path="/usermaster" element={<UserMaster />}></Route>
							<Route exact path="/citizens" element={<Citizens />}></Route>
							<Route exact path="/userroles" element={<UserRoles />}></Route>
							<Route exact path="/addcitizen" element={<AddCitizens />}></Route>
							<Route exact path="/warriors" element={<Warriors />}></Route>
							<Route exact path="/recyclers" element={<Recyclers />}></Route>
							<Route exact path="/adduser" element={<AddUser />}></Route>
							<Route exact path="/brands" element={<Brands />}></Route>
							<Route exact path="/wastecollection" element={<WasteCollection />}></Route>
							<Route exact path="/enviourment" element={<Enviourment />}></Route>
							<Route exact path="/collectionwise" element={<Collectionwise />}></Route>
							<Route exact path="/collectors" element={<Collectors />}></Route>
							<Route exact path="/viewprofile" element={<ViewProfile />}></Route>
							<Route exact path="/usercertificate" element={<Certificate />}></Route>
							<Route exact path="/branchcertificate" element={<BranchCertificate />}></Route>
							<Route exact path="/advancedreports" element={<AdvancedReports />}></Route>
							<Route exact path="/allcitizens" element={<AllCitizens />}></Route>
							
							<Route
								exact
								path="/aggregators"
								element={<Aggregators />}
							></Route>
							<Route
								exact
								path="/specificuser"
								element={<SpecificUser />}
							></Route>
							<Route
								exact
								path="/transitions"
								element={<Transitions />}
							></Route>
							<Route
								exact
								path="/stocktransfer"
								element={<StockTransfer />}
							></Route>
							<Route
								exact
								path="/uploaddeposits"
								element={<UploadDeposits />}
							></Route>
							<Route exact path="/transfers" element={<Transfer />}></Route>
							<Route
								exact
								path="/depositdetail"
								element={<DepositDetail />}
							></Route>
							<Route
								exact
								path="/transferdetails"
								element={<TransferDetails />}
							></Route>
							<Route
								exact
								path="/uploadtransfer"
								element={<UploadTransfer />}
							></Route>
							<Route exact path="/adddeposits" element={<AddDeposit />}></Route>
							<Route
								exact
								path="/wastemanagement"
								element={<WasteManagement />}
							></Route>
							<Route exact path="/addwaste" element={<AddWaste />}></Route>
							<Route exact path="/bankbranch" element={<BankBranch />}></Route>
							<Route
								exact
								path="/addbranchtypes"
								element={<AddBranchTypes />}
							></Route>
							<Route exact path="/getbranch" element={<GetBranch />}></Route>
							<Route exact path="/addbranch" element={<Branches />}></Route>
							<Route exact path="/allwaste" element={<AllWaste />}></Route>
							<Route exact path="/step2" element={<Step2 />}></Route>
							<Route exact path="/" element={<Dashboard />}></Route>
							<Route exact path="/login" element={<Login />}></Route>
							<Route exact path="/allrequests" element={<All />}></Route>
							<Route exact path="/rejectedrequests" element={<Rejected />}></Route>
							<Route exact path="/completedrequests" element={<Completed />}></Route>
							<Route exact path="/pendingrequests" element={<Pending />}></Route>
							<Route
								exact
								path="/forgotpassword"
								element={<ForgotPassword />}
							></Route>
							<Route
								exact
								path="/changepassword"
								element={<ChangePassword />}
							></Route>
							<Route exact path="/profile" element={<Profile />}></Route>
							<Route exact path="/city" element={<City />}></Route>
							<Route exact path="/deposits" element={<Deposit />}></Route>
							<Route exact path="/stock_journal" element={<Journal />} />
							{/* <Route exact path="/addcity" element={<Addcity />}></Route>
            <Route exact path="/selectdistrict" element={<SelectDistrict />}></Route>
            <Route exact path="/selectcity" element={<SelectCity />}></Route> */}
							<Route exact path="/countries" element={<Country />}></Route>
							{/* <Route exact path="/updatecity" element={<Updatecity />}></Route>
            <Route exact path="/addcountry" element={<AddCountry />}></Route> */}
							{/* <Route exact path="/updatecountry" element={<UpdateCountry />}></Route> */}
							<Route exact path="/district" element={<District />}></Route>
							{/* <Route exact path="/selectstate" element={<SelectState />}></Route>
            <Route exact path="/adddistrict" element={<AddDistrict />}></Route>
            <Route exact path="/updatedistrict" element={<UpdateDistrict />}></Route> */}
							{/* <Route exact path="/addstate" element={<AddState />}></Route> */}
							<Route exact path="/state" element={<State />}></Route>
							{/* <Route exact path="/selectcountry" element={<SelectCountry />}></Route>
            <Route exact path="/updatestate" element={<UpdateState />}></Route> */}
							<Route exact path="/area" element={<Area />}></Route>
							<Route exact path="/excel" element={<Excel />}></Route>
							<Route
								exact
								path="/addsignature"
								element={<AddSignature />}
							></Route>
							{/* <Route exact path="/addarea" element={<AddArea />}></Route>
            <Route exact path="/updatearea" element={<UpdateArea />}></Route> */}
							{/* <Route exact path="/side" element={<Sidebar />}></Route> */}
						</Routes>
					</BrowserRouter>
				</HandleState>
			</div>
		</div>
	);
}

export default App;
