import React, { useEffect, useContext, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Content from "./Content";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import HandleContext from "../Context/HandleContext";
import filter from '../Images/filter.png'
// import Dashboardfilter from "./Dashboardfilter";

export default function Dashboard() {
	const context = useContext(HandleContext);
	
	const { Profile, profile, GetAllBankBranch, bankBranch, PostData,Dashboard } = context;
	const [credentials, setCredentials] = useState({fromdate: "",todate: "",branch: "",gap:"day"});

	let navigate = useNavigate();

	useEffect(() => {
		Cookies.get("num") ? navigate("/") : navigate("/login");
		GetAllBankBranch("bankBranch");
		// Profile();
	}, []);
	console.log(profile);

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Submitted");
		console.log(credentials);
		Dashboard("dashboard", JSON.stringify(credentials));
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};
	return (
		<div style={{ overflow: "auto" }}>
			<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								Filter Data
							</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form id="w0" onSubmit={handleSubmit}>
							<div class="modal-body">
								<input
									type="hidden"
									name="_csrf-frontend"
									defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
								/>
								<div className="row">
									<div className="form-group col-md-6">
										<label>From Date</label>
										<input
											type="date"
											className="form-control"
											name="fromdate"
											placeholder=""
											onChange={(e) => {
												setCredentials({
													...credentials,
													fromdate: e.target.value,
												});
												Cookies.set('fromdate',e.target.value)

											}}
											style={{ border: "2px solid darkseagreen" }}
											required
										/>
									</div>
									<div className="form-group col-md-6">
										<label>To Date</label>
										<input
											type="date"
											className="form-control"
											name="todate"
											placeholder=""
											onChange={(e) => {
												setCredentials({
													...credentials,
													todate: e.target.value,
												});
											Cookies.set('todate',e.target.value)
											}}

											style={{ border: "2px solid darkseagreen" }}
											required
										/>
									</div>
								</div>
								{bankBranch && bankBranch.length!==1?
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label className="label">Select Branch</label>
											<select
												id="usercities-state"
												className="form-control select-search"
												name="branch"
												onChange={(e) => {
													{
														setCredentials({
															...credentials,
															branch: e.target.value,
														});
														Cookies.set('branch',e.target.value)
													}
												}}
												required
											>
												<option>-- Select Branch --</option>
												{bankBranch &&
													bankBranch.map((e) => {
														// console.log(e);
														return (
															<option value={e.id}>{e.branch_name}</option>
														);
													})}
											</select>
										</div>
									</div>
								</div>
:''}

							</div>
							<div className="col-md-12">
										<div className="form-group">
											<label className="label">Data Frequency</label>
											<select
												id="usercities-state"
												className="form-control select-search"
												name="gap"
												onChange={(e) => {
													{
														setCredentials({
															...credentials,
															gap: e.target.value,
														});
														Cookies.set('gap',e.target.value)
													}
												}}
												required
											>
												<option >-- Select Frequency --</option>
												<option value='day'>Daily</option>
												<option value='week'>Weekly</option>
												<option value='month'>Monthly</option>
												<option value='year'>Yearly</option>
											
												
											</select>
										</div>
									</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-secondary"
									data-dismiss="modal"
								>
									Close
								</button>
								<button type="submit" class="btn btn-primary">
									Filter
								</button>
							</div>
						</form>					</div>
				</div>
			</div>
			<Navbar />
			<div className="page-content">
				<Sidebar />
				<Content />
				<span  style={{cursor: "pointer",right: "16px",position: "fixed",background: "#f5c929",borderRadius: '50%',padding: "10px"}} data-toggle="modal" data-target="#exampleModal">
					<img src={filter} style={{width:'50px',height:'50px'}}/>
					{/* <svg

						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						fill="currentColor"
						class="bi bi-filter"
						viewBox="0 0 16 16"
					>
						<path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
					</svg> */}
				</span>
			</div>
		</div>
	);
}
