import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import HandleContext from "../../../Context/HandleContext";
import axios from "axios";
import toastr from "toastr";

const Journal = () => {
	const context = useContext(HandleContext);
	const { GetAllWaste, allWaste, BranchUsers, bankbranchusers } = context;

	const [date, setDate] = useState(null);
	const [user, setUser] = useState(null);
	const [donation, setDonation] = useState("0");

	const [singleItem, setSingleItem] = useState({
		product: "",
		quantity: "",
		rate: "",
	});
	const [singleItem2, setSingleItem2] = useState({
		product: "",
		quantity: "",
		rate: "",
	});

	const [items, setItems] = useState([]);
	const [items2, setItems2] = useState([]);

	const [credentials, setCredentials] = useState({
		user_id: "",
		branch_id: "",
		tdate: "",
		product: [],
		quantity: [],
		rate: [],
	});

	useEffect(() => {
		GetAllWaste("product");
		BranchUsers(
			"dashboard/users",
			JSON.stringify({ role: "citizens", branch: Cookies.get("branch") })
		);
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		setItems((old) => {
			return [...old, singleItem];
		});

		setSingleItem({
			product: "",
			quantity: "",
			rate: "",
		});
	};

	const handleSubmit2 = (e) => {
		e.preventDefault();

		setItems2((old) => {
			return [...old, singleItem2];
		});

		setSingleItem2({
			product: "",
			quantity: "",
			rate: "",
		});
	};

	const formateData = () => {
		const data_to_send = {};

		data_to_send.branch_id = Number(Cookies.get("branch"));

		data_to_send.user_id = Number(user.id);

		data_to_send.is_donation = Number(donation);

		data_to_send.tdate = date;

		data_to_send.products = items.map((val) => {
			return Number(val.product.id);
		});

		data_to_send.quantity = items.map((val) => {
			return Number(val.quantity);
		});

		data_to_send.rates = items.map((val) => {
			return Number(val.rate);
		});

		return data_to_send;
	};

	const handleFinalSubmit = () => {
		const data = formateData();

		axios({
			method: "post",
			url: "https://api.zerowastecitizen.in/transaction/accept_waste",
			data: data,
			headers: {
				"Content-Type": "application/json",
				"Client-Service": "ZWCAPP",
				"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
				uid: Cookies.get("id"),
				token: Cookies.get("num"),
			},
		})
			.then((res) => {
				toastr.success(res.data.message);
				setDate(null);
				setUser(null);
				setDonation("0");
				setSingleItem({
					product: "",
					quantity: "",
					rate: "",
				});
				setItems([]);
			})
			.catch((error) => {
				toastr.success(error.data.message);
			});
	};

	return (
		<div>
			<div>
				<Navbar />
				<div className="page-content">
					<Sidebar />
					<div class="page-content">
						<div class="content-wrapper">
							<div class="page-header page-header-light">
								<div class="page-header-content header-elements-md-inline">
									<div
										class="page-title d-flex"
										style={{ justifyContent: "space-between" }}
									>
										<h4>
											<span
												style={{ fontSize: "18px" }}
												class="font-weight-semibold"
											>
												Stock Journal
											</span>
										</h4>

										<div class="header-elements "></div>
									</div>
									<hr style={{ margin: "0px" }} />
								</div>

								<div
									class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
									style={{
										paddingLeft: "14px",
										marginBottom: "6px",
										paddingRight: "20px",
									}}
								>
									<div class="d-flex">
										<ul class="breadcrumb">
											<Link to="/">Home</Link>
											<Link to="/" class="breadcrumb-item">
												<i
													style={{ marginRight: "6px" }}
													class="ph-house-line mr-4"
												></i>
												Home
											</Link>
											<span class="breadcrumb-item active">Stock Journal</span>
										</ul>

										<a
											href="#"
											class="header-elements-toggle text-default d-md-none"
										>
											<i class="icon-more"></i>
										</a>
										<div style={{ flexGrow: 1 }}></div>
									</div>
								</div>
							</div>
							<div className="content">
								<>
									<div
										style={{
											background: "transparent",
											boxShadow: "none",
											padding: "10px",
										}}
									>
										<div className="card-body">
											<div id="w0" className="grid-view">
												<div className="row">
													<div className="card">
														<div className="row">
															{/* to date */}
															<div className="col-md-4">
																<div className="form-group">
																	<label className="label">To Date</label>
																	<input
																		type="date"
																		class="form-control"
																		id="exampleCheck1"
																		name="tdate"
																		value={date}
																		onChange={(e) => {
																			setDate(e.target.value);
																			// setCredentials({
																			// 	...credentials,
																			// 	tdate: e.target.value,
																			// });
																		}}
																	/>
																</div>
															</div>
															{/* select user */}
															<div className=" col-md-4">
																<div className="form-group">
																	<label className="label">Select User</label>
																	<select
																		id="usercities-state"
																		class="form-control select-search"
																		name="user_id"
																		value={user ? user.id : ""}
																		onChange={(e) => {
																			setUser(
																				bankbranchusers.filter((val) => {
																					return val.id === e.target.value;
																				})[0]
																			);

																			// setCredentials({
																			// 	...credentials,
																			// 	user_id: e.target.value,
																			// 	branch_id: Cookies.get("branch_id"),
																			// });
																		}}
																		required
																	>
																		<option>-- Select User --</option>
																		{bankbranchusers &&
																			bankbranchusers.map((e) => {
																				console.log(e);
																				return (
																					<option
																						value={e.id}
																					>{`${e.name} (${e.phone_num})`}</option>
																				);
																			})}
																	</select>
																</div>
															</div>
															{/* donation */}
															<div className=" col-md-4">
																<div className="form-group">
																	<label className="label"> Donation</label>
																	<select
																		id="usercities-state"
																		class="form-control select-search"
																		name="user_id"
																		value={donation}
																		onChange={(e) => {
																			setDonation(e.target.value);
																			// setCredentials({
																			// 	...credentials,
																			// 	donation: e.target.value,
																			// });
																		}}
																		required
																	>
																		<option value="0">No</option>
																		<option value="1">Yes</option>
																	</select>
																</div>
															</div>
														</div>
													</div>
													<form
														id="w0"
														onSubmit={handleSubmit}
														style={{ padding: 0 }}
													>
														<input
															type="hidden"
															name="_csrf-frontend"
															defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
														/>
														<div
															className="card"
															style={{ padding: "5px 20px" }}
														>
															<h5>Table one</h5>
															<div className="row">
																{/* select Product */}
																<div className=" col-md-3">
																	<div className="form-group">
																		<label className="label">
																			Select Product
																		</label>
																		<select
																			id="usercities-state"
																			class="form-control select-search"
																			name="user_id"
																			value={
																				singleItem.product?.id
																					? singleItem.product?.id
																					: ""
																			}
																			onChange={(e) => {
																				setSingleItem((old) => {
																					let temp = allWaste.filter((val) => {
																						return val.id === e.target.value;
																					})[0];

																					return {
																						...old,
																						product: temp,
																					};
																				});
																				// setCredentials({
																				// 	...credentials,
																				// 	product: e.target.value,
																				// });
																			}}
																			required
																		>
																			<option> --Select Product --</option>
																			{allWaste &&
																				allWaste.map((e) => {
																					console.log(e);
																					return (
																						<option value={e.id}>
																							{e.product_name}
																						</option>
																					);
																				})}
																		</select>
																	</div>
																</div>

																{/* quantity */}
																<div className="col-md-3">
																	<div className="form-group">
																		<label className="label">
																			Enter Quantity
																		</label>
																		<input
																			type="text"
																			class="form-control"
																			id="exampleCheck1"
																			name="quantity"
																			placeholder={
																				!!singleItem.product
																					? singleItem.product.aunit
																					: ""
																			}
																			value={singleItem.quantity}
																			onChange={(e) => {
																				setSingleItem((old) => {
																					return {
																						...old,
																						quantity: e.target.value,
																						rate: Number(
																							old.product.purchase_price
																						),
																					};
																				});
																				// setCredentials({
																				// 	...credentials,
																				// 	quantity: e.target.value,
																				// });
																			}}
																		/>
																	</div>
																</div>
																{/* rate */}
																<div className="col-md-3">
																	<div className="form-group">
																		<label className="label">Enter Rate</label>
																		<input
																			type="text"
																			class="form-control"
																			id="exampleCheck1"
																			name="rate"
																			value={singleItem.rate}
																			onChange={(e) => {
																				setSingleItem((old) => {
																					return {
																						...old,
																						rate: e.target.value,
																					};
																				});
																				// setCredentials({
																				// 	...credentials,
																				// 	rate: e.target.value,
																				// });
																			}}
																		/>
																	</div>
																</div>
																<div
																	className="col-md-3"
																	style={{ marginTop: "20px" }}
																>
																	<div className="form-group">
																		<label> </label>
																		<button
																			type="submit"
																			name="first"
																			className="btn btn-success"
																			disabled={
																				singleItem.product &&
																				singleItem.quantity &&
																				singleItem.rate
																					? false
																					: true
																			}
																		>
																			ADD
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</form>
													{items.length !== 0 && (
														<table
															className="table datatable-reorder table-bordered table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
															id="myTable"
															style={{ marginBottom: "20px" }}
														>
															<thead>
																<tr>
																	<th>#</th>
																	<th>Product</th>
																	<th>Quantity</th>
																	<th>Rate</th>
																	<th>Total</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{items.map((val, index) => {
																	return (
																		<tr key={index}>
																			<td>{index + 1}</td>
																			<td>{val.product.product_name}</td>
																			<td>{val.quantity}</td>
																			<td>{val.rate}</td>
																			<td>{val.rate * val.quantity}</td>
																			<td align="center">
																				<i
																					className="ph-trash"
																					style={{
																						color: "red",
																						cursor: "pointer",
																					}}
																					onClick={() => {
																						setItems((old) => {
																							old.splice(index, 1);
																							return [...old];
																						});
																					}}
																				></i>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													)}
													<form
														id="w0"
														onSubmit={handleSubmit2}
														style={{ padding: 0 }}
													>
														<input
															type="hidden"
															name="_csrf-frontend"
															defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
														/>
														<div
															className="card"
															style={{ padding: "5px 20px" }}
														>
															<h5>Table two</h5>
															<div className="row">
																{/* select Product */}
																<div className=" col-md-3">
																	<div className="form-group">
																		<label className="label">
																			Select Product
																		</label>
																		<select
																			id="usercities-state"
																			class="form-control select-search"
																			name="user_id"
																			value={
																				singleItem2.product?.id
																					? singleItem2.product?.id
																					: ""
																			}
																			onChange={(e) => {
																				setSingleItem2((old) => {
																					let temp = allWaste.filter((val) => {
																						return val.id === e.target.value;
																					})[0];

																					return {
																						...old,
																						product: temp,
																					};
																				});
																				// setCredentials({
																				// 	...credentials,
																				// 	product: e.target.value,
																				// });
																			}}
																			required
																		>
																			<option> --Select Product --</option>
																			{allWaste &&
																				allWaste.map((e) => {
																					console.log(e);
																					return (
																						<option value={e.id}>
																							{e.product_name}
																						</option>
																					);
																				})}
																		</select>
																	</div>
																</div>

																{/* quantity */}
																<div className="col-md-3">
																	<div className="form-group">
																		<label className="label">
																			Enter Quantity
																		</label>
																		<input
																			type="text"
																			class="form-control"
																			id="exampleCheck1"
																			name="quantity"
																			placeholder={
																				!!singleItem2.product
																					? singleItem2.product.aunit
																					: ""
																			}
																			value={singleItem2.quantity}
																			onChange={(e) => {
																				setSingleItem2((old) => {
																					return {
																						...old,
																						quantity: e.target.value,
																						rate: Number(
																							old.product.purchase_price
																						),
																					};
																				});
																				// setCredentials({
																				// 	...credentials,
																				// 	quantity: e.target.value,
																				// });
																			}}
																		/>
																	</div>
																</div>
																{/* rate */}
																<div className="col-md-3">
																	<div className="form-group">
																		<label className="label">Enter Rate</label>
																		<input
																			type="text"
																			class="form-control"
																			id="exampleCheck1"
																			name="rate"
																			value={singleItem2.rate}
																			onChange={(e) => {
																				setSingleItem2((old) => {
																					return {
																						...old,
																						rate: e.target.value,
																					};
																				});
																				// setCredentials({
																				// 	...credentials,
																				// 	rate: e.target.value,
																				// });
																			}}
																		/>
																	</div>
																</div>
																<div
																	className="col-md-3"
																	style={{ marginTop: "20px" }}
																>
																	<div className="form-group">
																		<label> </label>
																		<button
																			type="submit"
																			value="second"
																			className="btn btn-success"
																			disabled={
																				singleItem2.product &&
																				singleItem2.quantity &&
																				singleItem2.rate
																					? false
																					: true
																			}
																		>
																			ADD
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</form>
													{items2.length !== 0 && (
														<table
															className="table datatable-reorder table-bordered table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
															id="myTable"
															style={{ marginBottom: "20px" }}
														>
															<thead>
																<tr>
																	<th>#</th>
																	<th>Product</th>
																	<th>Quantity</th>
																	<th>Rate</th>
																	<th>Total</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{items2.map((val, index) => {
																	return (
																		<tr key={index}>
																			<td>{index + 1}</td>
																			<td>{val.product.product_name}</td>
																			<td>{val.quantity}</td>
																			<td>{val.rate}</td>
																			<td>{val.rate * val.quantity}</td>
																			<td align="center">
																				<i
																					className="ph-trash"
																					style={{
																						color: "red",
																						cursor: "pointer",
																					}}
																					onClick={() => {
																						setItems2((old) => {
																							old.splice(index, 1);
																							return [...old];
																						});
																					}}
																				></i>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													)}
												</div>
											</div>

											{/* table part */}
										</div>
									</div>
								</>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Journal;
