import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import HandleContext from "../../../Context/HandleContext";
import axios from "axios";
import toastr from "toastr";

export default function StockTransfer() {
	const context = useContext(HandleContext);
	const {
		GetAllWaste,
		allWaste,
		BranchUsers,
		bankbranchusers,
		GetAllBankBranch,
		bankBranch,
	} = context;

	const [date, setDate] = useState(null);
	const [to_branch, setUser] = useState(null);
	const [donation, setDonation] = useState("0");

	const [singleItem, setSingleItem] = useState({
		product: "",
		quantity: "",
		rate: "",
	});

	const [items, setItems] = useState([]);

	const [credentials, setCredentials] = useState({
		from_branch: Cookies.get("branch"),
		to_branch: "",
		tdate: "",
		product: [],
		quantity: [],
		rate: [],
	});

	useEffect(() => {
		GetAllBankBranch("bankBranch");

		GetAllWaste("product");
		BranchUsers(
			"dashboard/users",
			JSON.stringify({ role: "citizens", branch: Cookies.get("branch") })
		);
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		setItems((old) => {
			return [...old, singleItem];
		});

		setSingleItem({
			product: "",
			quantity: "",
			rate: "",
		});
	};

	const formateData = () => {
		const data_to_send = {};

		data_to_send.from_branch = Number(Cookies.get("branch"));

		data_to_send.to_branch = Number(to_branch.id);

		data_to_send.tdate = date;

		data_to_send.products = items.map((val) => {
			return Number(val.product.id);
		});

		data_to_send.quantity = items.map((val) => {
			return Number(val.quantity);
		});

		data_to_send.rates = items.map((val) => {
			return Number(val.rate);
		});

		return data_to_send;
	};

	const handleFinalSubmit = () => {
		const data = formateData();

		axios({
			method: "post",
			url: "https://api.zerowastecitizen.in/transaction/stock_transfer",
			data: data,
			headers: {
				"Content-Type": "application/json",
				"Client-Service": "ZWCAPP",
				"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
				uid: Cookies.get("id"),
				token: Cookies.get("num"),
			},
		})
			.then((res) => {
				toastr.success(res.data.message);
				setDate(null);
				setUser(null);
				setDonation("0");
				setSingleItem({
					product: "",
					quantity: "",
					rate: "",
				});
				setItems([]);
			})
			.catch((error) => {
				console.log(error);
				toastr.success(error.data.message);
			});
	};

	return (
		<div>
			<div>
				<Navbar />
				<div className="page-content">
					<Sidebar />
					<div class="page-content">
						<div class="content-wrapper">
							<div class="page-header page-header-light">
								<div class="page-header-content header-elements-md-inline">
									<div
										class="page-title d-flex"
										style={{ justifyContent: "space-between" }}
									>
										<h4>
											<span
												style={{ fontSize: "18px" }}
												class="font-weight-semibold"
											>
												Transfer
											</span>
										</h4>

										<div class="header-elements "></div>
									</div>
									<hr style={{ margin: "0px" }} />
								</div>

								<div
									class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
									style={{
										paddingLeft: "14px",
										marginBottom: "6px",
										paddingRight: "20px",
									}}
								>
									<div class="d-flex">
										<ul class="breadcrumb">
											<Link to="/">Home</Link>
											<Link to="/" class="breadcrumb-item">
												<i
													style={{ marginRight: "6px" }}
													class="ph-house-line mr-4"
												></i>
												Home
											</Link>
											<span class="breadcrumb-item active">Transfer</span>
										</ul>

										<a
											href="#"
											class="header-elements-toggle text-default d-md-none"
										>
											<i class="icon-more"></i>
										</a>
										<div style={{ flexGrow: 1 }}></div>
									</div>
								</div>
							</div>
							<div className="content">
								<>
									<div className="card">
										<div className="card-body">
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
												}}
											></div>

											<div id="w0" className="grid-view">
												<form id="w0" onSubmit={handleSubmit}>
													<input
														type="hidden"
														name="_csrf-frontend"
														defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
													/>
													<div className="row">
														{/* to date */}
														<div className="col-md-4">
															<div className="form-group">
																<label className="label">To Date</label>
																<input
																	type="date"
																	class="form-control"
																	id="exampleCheck1"
																	name="tdate"
																	value={date}
																	onChange={(e) => {
																		setDate(e.target.value);
																		// setCredentials({
																		// 	...credentials,
																		// 	tdate: e.target.value,
																		// });
																	}}
																/>
															</div>
														</div>
														{/* select branch */}
														<div className=" col-md-4">
															<div className="form-group">
																<label className="label">Select Branch</label>
																<select
																	id="usercities-state"
																	class="form-control select-search"
																	name="to_branch"
																	value={to_branch ? to_branch.id : ""}
																	onChange={(e) => {
																		setUser(
																			bankbranchusers.filter((val) => {
																				return val.id === e.target.value;
																			})[0]
																		);

																		// setCredentials({
																		// 	...credentials,
																		// 	user_id: e.target.value,
																		// 	to_branch: Cookies.get("to_branch"),
																		// });
																	}}
																	required
																>
																	<option>-- Select Branch --</option>
																	{bankBranch &&
																		bankBranch.map((e) => {
																			console.log(e);
																			return (
																				<option
																					value={e.id}
																				>{`${e.branch_name} `}</option>
																			);
																		})}
																</select>
															</div>
														</div>

														{/* select Product */}
														<div className=" col-md-4">
															<div className="form-group">
																<label className="label">Select Product</label>
																<select
																	id="usercities-state"
																	class="form-control select-search"
																	name="user_id"
																	value={
																		singleItem.product?.id
																			? singleItem.product?.id
																			: ""
																	}
																	onChange={(e) => {
																		setSingleItem((old) => {
																			let temp = allWaste.filter((val) => {
																				return val.id === e.target.value;
																			})[0];

																			return {
																				...old,
																				product: temp,
																			};
																		});
																		// setCredentials({
																		// 	...credentials,
																		// 	product: e.target.value,
																		// });
																	}}
																	required
																>
																	<option> --Select Product --</option>
																	{allWaste &&
																		allWaste.map((e) => {
																			console.log(e);
																			return (
																				<option value={e.id}>
																					{e.product_name}
																				</option>
																			);
																		})}
																</select>
															</div>
														</div>

														{/* quantity */}
														<div className="col-md-3">
															<div className="form-group">
																<label className="label">Enter Quantity</label>
																<input
																	type="text"
																	class="form-control"
																	id="exampleCheck1"
																	name="quantity"
																	placeholder={
																		!!singleItem.product
																			? singleItem.product.aunit
																			: ""
																	}
																	value={singleItem.quantity}
																	onChange={(e) => {
																		setSingleItem((old) => {
																			return {
																				...old,
																				quantity: e.target.value,
																				rate: Number(
																					old.product.purchase_price
																				),
																			};
																		});
																	}}
																/>
															</div>
														</div>
														{/* rate */}
														<div className="col-md-3">
															<div className="form-group">
																<label className="label">Enter Rate</label>
																<input
																	type="text"
																	class="form-control"
																	id="exampleCheck1"
																	name="rate"
																	value={singleItem.rate}
																	onChange={(e) => {
																		setSingleItem((old) => {
																			return { ...old, rate: e.target.value };
																		});
																		// setCredentials({
																		// 	...credentials,
																		// 	rate: e.target.value,
																		// });
																	}}
																/>
															</div>
														</div>
														<div
															className="col-md-3"
															style={{ marginTop: "20px" }}
														>
															<div className="form-group">
																<label> </label>
																<button
																	type="submit"
																	className="btn btn-success"
																	disabled={
																		singleItem.rate &&
																		singleItem.quantity &&
																		singleItem.product
																			? false
																			: true
																	}
																>
																	ADD
																</button>
															</div>
														</div>
													</div>
												</form>
											</div>
											{items.length !== 0 && (
												<table
													className="table datatable-reorder table-bordered table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
													id="myTable"
												>
													<thead>
														<tr>
															<th>#</th>
															<th>Product</th>
															<th>Quantity</th>
															<th>Rate</th>
															<th>Total</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{items.map((val, index) => {
															return (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{val.product.product_name}</td>
																	<td>{val.quantity}</td>
																	<td>{val.rate}</td>
																	<td>{val.rate * val.quantity}</td>
																	<td align="center">
																		<i
																			className="ph-trash"
																			style={{
																				color: "red",
																				cursor: "pointer",
																			}}
																			onClick={() => {
																				setItems((old) => {
																					old.splice(index, 1);
																					return [...old];
																				});
																			}}
																		></i>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											)}
											{items.length !== 0 && (
												<button
													style={{ marginTop: "20px" }}
													type="submit"
													className="btn btn-success"
													onClick={handleFinalSubmit}
												>
													SAVE
												</button>
											)}
										</div>
									</div>
								</>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
