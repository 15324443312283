import React,{useState,useContext,} from 'react'
import Logo from '../Images/logo.png'
import HandleContext from '../Context/HandleContext'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Cookies from 'js-cookie'
import toastr from 'toastr'

export default function Login() {

  let navigate=useNavigate()

  const context = useContext(HandleContext);
  const { PostData} = context;

  const [credentials, setCredentials] = useState({username:"",password:""})

  const handleChange=(e)=>{
    setCredentials({...credentials,[e.target.name]:e.target.value})
  }
  
  const handleSubmit=async(e)=>{
    e.preventDefault();
      await axios.post(`https://api.zerowastecitizen.in/user/login`, credentials, {
       headers:{ 'Client-Service': 'ZWCAPP',
       'Auth-Key': '4UyYF2Sqz256901zrjoAAZpqf67'}
      }).then((response) => {
        console.log(response);
        toastr.success(response.data.message)
        Cookies.set("num", response.data.token)
        Cookies.set("id", response.data.uid)
        Cookies.set("user_role_id", response.data.user_role_id)
        Cookies.set("branch", response.data.branch_id)
        Cookies.set("user_name", response.data.name)
        Cookies.set("branch_name", response.data.branch_name)
        navigate('/')
        // console.log(response.data.message);
      }).catch((e) => {
        console.log(e);
        toastr.error(e.response.data.message)
        // console.log(e.response.data.message);
      })
    // }
  }
  
  return (
 <div className='page-content'>
  {/* Page content */}
  <div className="page-content">
    {/* Main content */}
    <div className="content-wrapper">
      {/* Inner content */}
      <div className="content-inner">
        {/* Content area */}
        <div className="content d-flex justify-content-center align-items-center" 
        // style={{transform:'translate(0px,50px)'
        // }}
        >
          {/* Login form */}
          <form className="login-form align-items-center" onSubmit={handleSubmit}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="text-center mb-3">
                  <div className="d-inline-flex align-items-center justify-content-center mb-4 mt-2">
                    <img src={Logo} className="h-48px" alt />
                  </div>
                  <h5 className="mb-0">Login to your account</h5>
                  <span className="d-block text-muted">Enter your credentials below</span>
                </div>
                <div className="mb-3">
                  <label className="form-label">Username</label>
                  <div className="form-control-feedback form-control-feedback-start">
                    <input type="email" className="form-control" placeholder="john@doe.com" name='username' value={credentials.username} required onChange={handleChange}/>
                    <div className="form-control-feedback-icon">
                      <i className="ph-user-circle text-muted" />
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-1">
                  <label className="form-label">Password</label>
                  <div className="form-control-feedback form-control-feedback-start">
                    <input type="password" className="form-control" placeholder="•••••••••••" name='password' value={credentials.password} required onChange={handleChange} />
                    <div className="form-control-feedback-icon">
                      <i className="ph-lock text-muted" />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3 mt-3">
                  {/* <label className="form-check">
                    <input type="checkbox" name="remember" className="form-check-input" defaultChecked />
                    <span className="form-check-label">Remember</span>
                  </label> */}
                  <Link to="/forgotpassword" className="text-center">Forgot password?</Link>
                </div>
                <div className="mb-3 mt-3">
                  <button type="submit" className="btn btn-success w-100 ">Sign in<i style={{marginLeft:"5px"}} class="ph-caret-circle-right ml-5"></i></button>
                </div>
                {/* <div className="text-center text-muted content-divider mb-3">
                  <span className="px-2">or sign in with</span>
                </div>
                <div className="text-center mb-3">
                  <button type="button" className="btn btn-outline-primary btn-icon rounded-pill border-width-2"><i className="ph-facebook-logo" /></button>
                  <button type="button" className="btn btn-outline-pink btn-icon rounded-pill border-width-2 ms-2"><i className="ph-dribbble-logo" /></button>
                  <button type="button" className="btn btn-outline-secondary btn-icon rounded-pill border-width-2 ms-2"><i className="ph-github-logo" /></button>
                  <button type="button" className="btn btn-outline-info btn-icon rounded-pill border-width-2 ms-2"><i className="ph-twitter-logo" /></button>
                </div> */}
                {/* <div className="text-center text-muted content-divider mb-3">
                  <span className="px-2">Don't have an account?</span>
                </div> */}
                {/* <div className="mb-3">
                  <a href="#" className="btn btn-light w-100">Sign up</a>
                </div> */}
                {/* <span className="form-text text-center text-muted">By continuing, you're confirming that you've read our <a href="#">Terms &amp; Conditions</a> and <a href="#">Cookie Policy</a></span> */}
              </div>
            </div>
          </form>
          {/* /login form */}
        </div>
        {/* /content area */}
     
      </div>
    {/* //   /inner content */}
     </div>
    {/* // /main content */}
   </div>
  {/* /page content */}
 </div>

  )
}
