import React, { useEffect, useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import $ from 'jquery';
import DataTables from 'datatables.net-dt'
import Updatepassword from '../Users/User/Updatepassword'
import { RadialChart } from 'react-vis';

export default function AllCitizens() {

    const data = [
        { angle: 10, label: 'Red' },
        { angle: 20, label: 'Blue' },
        { angle: 30, label: 'Yellow' }
      ];
    

  setTimeout(() => {
    let searchid = document.getElementById('myTable_filter')
    let id = document.getElementById('myTable_length');
    id.style.padding = '10px'
    searchid.style.padding = '10px'
  }, 2000);

  $(document).ready(function () {
    $('#myTable').DataTable();
  });

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const { allbankbranchusers,AllBranchUsers, } = context;

  useEffect(() => {
    AllBranchUsers("dashboard/branchwise_users", JSON.stringify({ role: "citizens", branch: Cookies.get('branch') }))
  }, [])

//   console.log(bankbranchusers);

  let count = 0;

  const handleButtonClick = async (id) => {
    // Set the cookie value
    await Cookies.set('branch', id);

    // Navigate to a new page
    navigate('/citizens');
  };


  return (
    <div>
      {/* Update Password Modal */}
      <div className="modal fade" id="staticBackdrop12" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Change User Password</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Updatepassword />
          </div>
        </div>
      </div>
      <div>
        <Navbar />
        <div className='page-content'>
          <Sidebar />
          <div class="page-content">
            <div class="content-wrapper">
              <div class="page-header page-header-light">
                <div class="page-header-content header-elements-md-inline">
                  <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                    <h4> <span style={{ fontSize: '18px' }} class="font-weight-semibold">All ZWC Accounts</span></h4>

                    <div class="header-elements ">

                    </div>
                  </div>
                  <hr style={{ margin: "0px" }} />

                </div>

                <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                  <div class="d-flex">
                    <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">All ZWC Accounts</span></ul>

                    <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                  </div>
                </div>
              </div>
              <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    {allbankbranchusers ? <>
                      <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>   All list of <code>ZWC Accounts</code>
                        </div>
                        {/* <Link to='/excel' style={{ cursor: 'pointer',color:'black',textDecoration:'none' }}><i style={{ color: 'green' }} className='ph-plus-circle'></i> Import ZWC Accounts</Link> */}
                      </div>
                      <div id="w0" className="grid-view">
                        <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable">
                          <thead>
                            <tr  >
                              <th>#</th>
                              <th>Branch Name</th>
                              <th>No. Of Users</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allbankbranchusers && allbankbranchusers.map((e) => {
                              count++;
                              return <tr key={e.branch_id} >
                                <td onClick={() => { handleButtonClick(e.branch_id) }}>{count}</td>
                                <td onClick={() => { handleButtonClick(e.branch_id) }}>{e.branch}</td>
                                <td onClick={() => { handleButtonClick(e.branch_id) }}>{e.users}</td>
                            
                             
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </div>
                    </> : ""}
                  </div>
                </div>
                {/* <div className='col-md-5'>
                            <div className='card' style={{display:'flex',textAlign:"center",justifyContent:'center'}}>
                            <RadialChart
        data={data}
        width={300}
        height={300}
        showLabels={true}
        labelsRadiusMultiplier={1.3}
        labelsStyle={{ fontSize: 14, fill: 'black' , fontWeight: 'bold', textAnchor: 'middle'}}
        animation={{ duration: 1000 }}
        colorRange={['#ff6f69', '#ffcc5c', '#88d8b0']}
        
      />                            </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
