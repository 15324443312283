// import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { writeFile } from 'xlsx';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import HandleContext from '../../Context/HandleContext';
import ExcelJS from 'exceljs';
// import { saveAs } from 'file-saver';

import Cookies from 'js-cookie';
import { elements } from 'chart.js';

function AdvancedReports() {

	const [credentials, setCredentials] = useState({fromdate: "",todate: "",branch: "",ad1:"",ad2:"",ad3:"",ad4:"",ad5:"",ad6:"",ad7:"",ad8:"",ad9:"",ad10:""});

	let count = 0;
	// let navigate = useNavigate()
	const context = useContext(HandleContext);
	const { branchfilter, BranchFilter ,advancereports,AdvanceReports} = context;

	useEffect(() => {
		BranchFilter("bankBranch/branch_filters", JSON.stringify({ branch: Cookies.get('branch') }))
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("submitted");
		AdvanceReports("report/wastecollectionadvanced", JSON.stringify(credentials));
	};

	
	return (
		// In your render method:
		<div>

			<div>
				<Navbar />
				<div className='page-content'>
					<Sidebar />
					<div class="page-content">
						<div class="content-wrapper">
							<div class="page-header page-header-light">
								<div class="page-header-content header-elements-md-inline">
									<div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
										<h4> <span style={{ fontSize: '18px' }} class="font-weight-semibold">User Certificate</span></h4>

										<div class="header-elements ">

										</div>
									</div>
									<hr style={{ margin: "0px" }} />

								</div>

								<div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
									<div class="d-flex">
										<ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">User Certificate</span></ul>

										<a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
									</div>
								</div>
							</div>
							<div className='content'>
								<div className='card'>
									<div className="card-body">
										<form id="w0" onSubmit={handleSubmit}>
											<input
												type="hidden"
												name="_csrf-frontend"
												defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
											/>
											<div className="row">
												{branchfilter && branchfilter.fields.map((e) => {
													count++;
													var fil = 'ad'+count;
													// console.log(e.values[count].value);
													return <div className="col-md-4">
														<div className="form-group">
															<label className="label"> {e.label}</label>
															<select id="usercities-state" class="form-control select-search" name={e.field} required onChange={
																()=>{
																	console.log(e.target.name);
																	console.log(e.target.value);
																	setCredentials({ ...credentials, [e.target.name]: e.target.value })

																}
															}>
																<option>-- Select {e.label}</option>
																{branchfilter[fil].map((el)=>{

																return <option name={e.field} value={el.value}>{el.value}</option>
																})}
															</select>
														</div>
													</div>
												})}
													<div className="col-md-4">
													<div className="form-group">
														<label className="label">From Date</label>
														<input
															type="date"
															class="form-control"
															id="exampleCheck1"
															name="fromdate"
															onChange={(e) => {
																setCredentials({
																	...credentials,
																	fromdate: e.target.value,
																});
															}}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label className="label">To Date</label>
														<input
															type="date"
															class="form-control"
															id="exampleCheck1"
															name="todate"
															onChange={(e) => {
																setCredentials({
																	...credentials,
																	todate: e.target.value,
																});
															}}
														/>
													</div>
												</div>

												<div className="col-md-2">
													<button
														type="submit"
														style={{ marginTop: "20px" }}
														className="btn btn-success"
													>
														Submit
													</button>
												</div>

												<div className="col-md-2" style={{ marginTop: "22px" }}>

												
												</div>
											</div>
										</form>
										{ advancereports? (
											<>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<div>
															{" "}
															All list of <code>Waste Collection</code>
														</div>
													</div>
											
												<div id="w0" className="grid-view">
													<table
														className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
														id="myTable"
													>
														<thead>
															<tr>
																<th>#</th>
																<th>Date</th>
																<th>Quantity</th>
																
															</tr>
														</thead>
														<tbody>
															{advancereports &&
																advancereports.map((e) => {
																	
																	console.log(e);
																	count++;
																	return (
																		<tr key={e.id} >
																			<td>{count}</td>
																			<td>{e.date}</td>
																			<td>{e.quantity}</td>
																			
																		</tr>
																	);
																})}
														</tbody>
													</table>
												</div>
											</>
										) : (
											""
											)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdvancedReports;