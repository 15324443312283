import React, { useEffect, useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export default function Country() {

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const { GetData, data } = context;

  useEffect(() => {
   Cookies.get('num')? GetData('country', JSON.stringify({ username: '' })):navigate('/login')
  }, [])
  
  console.log(data);
  console.log(data && data.name);

  let count = 0;

  const Delete = (id) => {
    GetData('/country/delete_country', JSON.stringify({ country_id: id }))
    setTimeout(() => {
      
      GetData('country', JSON.stringify({ username: '' }))  
    }, 200);
  }

  return (
    <>
      <Navbar />
      <div className='page-content'>
        <Sidebar />
        <div class="page-content">
          <div class="content-wrapper">
            {/* <div class="content-wrapper"> */}
            <div class="page-header page-header-light">
              <div class="page-header-content header-elements-md-inline">
                <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                  <h4> <span class="font-weight-semibold">Countries</span></h4>

                  <div class="header-elements ">
                    <div class="d-flex justify-content-center">
                      <Link class="btn btn-link btn-float text-default" to="/addcountry"><i class="ph-plus-circle text-primary"></i>
                        <div style={{ color: 'black', fontWeight: '500' }}>Add Country</div></Link>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "0px" }} />

              </div>

              <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                <div class="d-flex">
                  <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Countries</span></ul>

                  <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                </div>
              </div>
            </div>

            <div class="content">

              {/* Table with togglable columns */}
              <div className="card" style={{ paddingBottom: 30 }}>
                <div className="card-body">
                  All list of <code>Countries</code>
                </div>
                <div id="w0" className="grid-view">
                  <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="DataTables_Table_0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Country</th>
                        <th className="action-column">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((e) => {
                        count++
                        console.log(e);
                        return <tr key={e.id}>
                          <td>{count}</td>
                          <td>{e.name}</td>
                          <td >
                            <button className='btn btn-success btn-sm' onClick={() => {
                              navigate('/updatecountry')
                              Cookies.set('countryid',e.id)
                            }} style={{ marginRight: '6px' }}><i className='ph-note-pencil'></i></button>
                            <button className='btn btn-danger btn-sm' onClick={() => {
                              console.log(e.id);
                              Delete(e.id)
                            }}><i className='ph-trash'></i></button>
                          </td>
                        </tr>
                      })}

                    </tbody>
                  </table>
                </div>
              </div>
              {/* /table with togglable columns */}

            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  )
}
