// import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { writeFile } from 'xlsx';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import HandleContext from '../Context/HandleContext';
import ExcelJS from 'exceljs';

import Cookies from 'js-cookie';

function Excel() {
  const [credentials, setCredentials] = useState({ branch_id: Cookies.get('branch'), file: "" })
  const context = useContext(HandleContext);
  const { GetAllBankBranch, bankBranch, PostData, userExcel, BranchUserExcel, AddExcel } = context;

  useEffect(() => {
    GetAllBankBranch("bankBranch")
    BranchUserExcel("bankBranch/get_branch_user_excel", JSON.stringify({ id: Cookies.get("branch") }))
  }, [])
  console.log(userExcel);
  const writeExcelFile = async (data) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.columns = [
      userExcel.map((e) => {
        return { header: e }
      })

    ];

    data.forEach(item => {
      worksheet.addRow(item);
    });

    const filename = 'data.xlsx';
    const workbookOutput = await workbook.xlsx.writeBuffer();
    const blob = new Blob([workbookOutput], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  };

  const handleClick = async () => {
    await BranchUserExcel("bankBranch/get_branch_user_excel", JSON.stringify({ id: Cookies.get("branch") }))
    console.log(userExcel);
    writeExcelFile(
      [userExcel]
    );
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = async (event) => {
  //     const data = event.target.result;
  //     const workbook = new ExcelJS.Workbook();
  //     await workbook.xlsx.load(data);
  //     const worksheet = workbook.getWorksheet(1);
  //     const rows = worksheet.getSheetValues();
  //     console.log(rows);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  // const handleFileUpload = (event) => {
  //   setFile(event.target.files[0]);
  // };
  const handleUpload = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    const { file } = credentials
    formData.append("branch_id", Cookies.get('branch'))
    formData.append("file", file)
    console.log(credentials.file);
    console.log(formData);
    AddExcel(formData)


  };

  return (
    // In your render method:
    <div>
      <div>
        <Navbar />
        <div className='page-content'>
          <Sidebar />
          <div class="page-content">
            <div class="content-wrapper">
              <div class="page-header page-header-light">
                <div class="page-header-content header-elements-md-inline">
                  <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                    <h4> <span class="font-weight-semibold">Import Users</span></h4>

                    <div class="header-elements ">

                    </div>
                  </div>
                  <hr style={{ margin: "0px" }} />

                </div>

                <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                  <div class="d-flex">
                    <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Import Users</span></ul>

                    <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <>
                    <div className='card-header' style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4>Upload Excel File to Import Users</h4>

                      {Cookies.get('branch') &&
                        <button className='btn btn-success' onClick={handleClick}>Download Sample File</button>
                      }
                    </div>
                    <div className="card-body" >
                    
                      <div>
                        <form onSubmit={handleUpload}>
                          <div className='row'>
                            {Cookies.get('branch') ? '' :
                              <div className='col-md-6'>
                                <div className="form-group">
                                  <label className='label'>Select Branch</label>
                                  <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                                    Cookies.set('branch')
                                  }} required >
                                    <option>-- Select Branch --</option>
                                    {bankBranch && bankBranch.map((e) => {
                                      return <option onClick={() => { Cookies.set('branch', e.id) }} value={e.id}>{e.branch_name}</option>
                                    })}
                                  </select>
                                </div>
                              </div>
                            }
                            <div className='col-md-3'>
                              <div className="form-group">
                                <input type="file" name='file' class="form-control-file" id="exampleFormControlFile1" onChange={(e) => {
                                  setCredentials({ ...credentials, file: e.target.files[0] })
                                }} required />
                              </div>
                            </div>
                            <div className='col-md-2'>

                              <button type="submit" className="btn btn-primary">Upload</button>
                            </div>
                          </div>
                        </form>
                      </div>
                     

                    </div>

                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Excel;