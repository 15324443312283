import React, { useState, useContext, useEffect } from "react";
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Step4() {
	const context = useContext(HandleContext);
	const {
		GetWasteProcess,
		wasteprocess,
		PostData,
		States,
		state,
		District,
		district,
		City,
		city,
		Area,
		area,
	} = context;

	useEffect(() => {
		States();
	}, []);

	console.log(wasteprocess);

	const [credentials, setcredentials] = useState({ branch_id: "" });
	const [selectedValues, setSelectedValues] = useState([]);

	function handleCheckboxChange(event) {
		setcredentials({
			...credentials,
			branch_id: Cookies.get("branch_type_id"),
		});
		const value = event.target.value;

		if (event.target.checked) {
			setSelectedValues([...selectedValues, value]);
		} else {
			setSelectedValues(selectedValues.filter((val) => val !== value));
		}
	}

	function handleSubmit(event) {
		event.preventDefault();
		console.log(selectedValues);
		PostData(
			"bankBranch/assign_working_areas",
			JSON.stringify({
				branch_id: credentials.branch_id,
				areas: selectedValues,
			})
		);
	}
	return (
		<div className="card">
			<div className="card-body">
				<form id="w0" className="row" onSubmit={handleSubmit}>
					<input
						type="hidden"
						name="_csrf-frontend"
						defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
					/>

					<div className="form-group col-md-4">
						<label>Select State </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="country_id"
							onChange={(e) => {
								Cookies.set("state_id", e.target.value);
								District(JSON.stringify({ state_id: Cookies.get("state_id") }));
							}}
							required
						>
							<option>-- Select State --</option>
							{state &&
								state.map((e) => {
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					<div className="form-group col-md-4">
						<label>Select District </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="country_id"
							onChange={(e) => {
								Cookies.set("district_id", e.target.value);
								City(
									JSON.stringify({ district_id: Cookies.get("district_id") })
								);
							}}
							required
						>
							<option>-- Select District --</option>
							{district &&
								district.map((e) => {
									console.log(e);
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					<div className="form-group col-md-4">
						<label>Select City </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="country_id"
							onChange={(e) => {
								Cookies.set("city_id", e.target.value);
								Area(JSON.stringify({ city_id: Cookies.get("city_id") }));
							}}
							required
						>
							<option>-- Select City --</option>
							{city &&
								city.map((e) => {
									console.log(e);
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					{/* <div className="modal-body"> */}

					<h5>Check The Areas</h5>
					{area &&
						area.map((e) => {
							console.log(e);
							return (
								<div className="col-md-4" style={{ marginBottom: "20px" }}>
									<input
										type="checkbox"
										id="vehicle2"
										name={e.name}
										value={e.id}
										onChange={handleCheckboxChange}
									/>
									<label style={{ marginLeft: "5px" }}>{e.name}</label>
								</div>
							);
						})}

					{/* </div> */}
					{/* <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
					<button type="submit" className="btn btn-primary mt-4">
						Submit
					</button>
					{/* </div> */}
				</form>
			</div>
		</div>
	);
}
