// import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { writeFile } from 'xlsx';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import HandleContext from '../../Context/HandleContext';
import ExcelJS from 'exceljs';
// import { saveAs } from 'file-saver';

import Cookies from 'js-cookie';

function Certificate() {

    // let navigate = useNavigate()
  const context = useContext(HandleContext);
  const { certificate,Certificate } = context;

   

  useEffect(() => {
    Certificate("report/certificate", JSON.stringify({ uid: Cookies.get('user_id') }))
  }, [])

  const pdfUrl = certificate && certificate.pdf;
  const pdfName = `${certificate && certificate.filename}.pdf`;

  const downloadPdf = () => {
    saveAs(pdfUrl, pdfName);
  };
  
  return (
    // In your render method:
    <div>
      <div>
        <Navbar />
        <div className='page-content'>
          <Sidebar />
          <div class="page-content">
            <div class="content-wrapper">
              <div class="page-header page-header-light">
                <div class="page-header-content header-elements-md-inline">
                  <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                    <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">User Certificate</span></h4>

                    <div class="header-elements ">

                    </div>
                  </div>
                  <hr style={{ margin: "0px" }} />

                </div>

                <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                  <div class="d-flex">
                    <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">User Certificate</span></ul>

                    <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                <img src={certificate && certificate.image}/>
                </div>
                <div className='col-md-3' style={{marginTop:'10px'}}>
                <button className='btn btn-success' onClick={downloadPdf}>
      Download Certificate
    </button>
    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate;