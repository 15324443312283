import Cookies from 'js-cookie';
import React, { useState, useContext ,useEffect} from 'react'
import HandleContext from '../../../Context/HandleContext'

export default function Step2() {
    const context = useContext(HandleContext);
    const { GetAllCalculationStandard, allCalculationStandard, PostData } = context;

    useEffect(() => {
        GetAllCalculationStandard("product/calculation_standard")
    }, [])

    const handleSubmit = (e) => {
        console.log("Submitted");
        e.preventDefault();
        console.log(credentials);
        PostData("product/esformula", JSON.stringify(credentials))
        // setTimeout(() => { 
        //     GetAllWaste("product")
        // }, 200);
      }

    const [credentials, setCredentials] = useState({product_id:"", calculation_standard_id: "", btu: "" ,landfill_saves:"",oil_conserves:"",no_of_trees:"",water_saved:"",energy_saved:"",carbon_emmission:""})

    return (
        <div>
            <form id="w0" className='row' onSubmit={handleSubmit}>
                {/* <div className="modal-body"> */}
                <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />


                <div className="form-group col-md-6">
                    <label>Select Calculation Standard</label>
                    <select id="usercities-state" class="form-control select-search" name="calculation_standard_id" onChange={(e) => {setCredentials({ ...credentials, calculation_standard_id: e.target.value })
                    }} required >
                        <option >-- Select Calculation Standard --</option>
                        {allCalculationStandard && allCalculationStandard.map((e) => {
                            return <option value={e.id}>{e.standard_name}</option>
                        })}
                    </select>
                </div>

                <div className="form-group col-md-6">
                    <label>BTU </label>
                    <input type="text" className="form-control" name="btu" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, btu: e.target.value ,product_id:Cookies.get('product_id')})
                    }} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Landfill Saves </label>
                    <input type="text" className="form-control" name="landfill_saves" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, landfill_saves: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Oil Conserves </label>
                    <input type="text" className="form-control" name="oil_conserves" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, oil_conserves: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-4">
                    <label>No. of Trees </label>
                    <input type="text" className="form-control" name="no_of_trees" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, no_of_trees: e.target.value })
                    }} required />
                </div>

                <div className="form-group col-md-4">
                    <label>Water Saved</label>
                    <input type="text" className="form-control" name="water_saved" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, water_saved: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Energy Saved</label>
                    <input type="text" className="form-control" name="energy_saved" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, energy_saved: e.target.value })
                    }} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Carbon Emission</label>
                    <input type="text" className="form-control" name="carbon_emmission" placeholder='' onChange={(e) => {
                        setCredentials({ ...credentials, carbon_emmission: e.target.value })
                    }} required />
                </div>
               
                <button type="submit" className="btn btn-primary mt-4">Submit</button>
            </form>
        </div>
    )
}
