import React, { useState } from "react";
import HandleContext from "./HandleContext";
import axios from "axios";
import Cookies from "js-cookie";
import toastr from "toastr";
import parse from "html-react-parser";

export default function HandleState(props) {
	const url = "https://backend.zerowastecitizen.in/";

	const headers = {
		"Content-Type": "application/json",
		"Client-Service": "ZWCAPP",
		"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
		uid: Cookies.get("id"),
		token: Cookies.get("num"),
	};

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Client-Service": "ZWCAPP",
			"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
			uid: Cookies.get("id"),
			token: Cookies.get("num"),
		},
	};

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState();
	const [state, setState] = useState();
	const [district, setDistrict] = useState();
	const [city, setCity] = useState();
	const [area, setArea] = useState();
	const [profile, setProfile] = useState();
	const [user, setUser] = useState();
	const [specificuser, setspecificUser] = useState();
	const [wasteprocess, setwasteProcess] = useState();
	const [wastecategory, setwasteCategory] = useState();
	const [allWaste, setallWaste] = useState();
	const [allWasteType, setallWasteType] = useState();
	const [allUnits, setallUnits] = useState();
	const [gst, setGst] = useState();
	const [allCalculationStandard, setallCalculationStandard] = useState();
	const [branchType, setBranchType] = useState();
	const [bankBranch, setbankBranch] = useState();
	const [detailbankBranch, setdetailbankBranch] = useState();
	const [bankBranchCitizens, setdetailbankBranchCitizens] = useState();
	const [detailbankBranchWasteCategory, setdetailbankBranchWasteCategory] =
		useState();
	const [detailbankBranchWorkingAreas, setdetailbankBranchWorkingAreas] =
		useState();
	const [detailbankBranchWasteProcess, setdetailbankBranchWasteProcess] =
		useState();
	const [BranchData, setBranchData] = useState();
	const [dashboard, setDashboard] = useState();
	const [bankbranchusers, setbankbranchusers] = useState();
	const [allbankbranchusers, setallbankbranchusers] = useState();
	const [bankbranchusersAdditional, setbankbranchusersAdditional] = useState();
	
	const [branchfilter, setbranchfilter] = useState();
	const [apiCallSucceeded, setApiCallSucceeded] = useState(false);
	const [drilldown, setdrilldown] = useState();
	const [barchart, setbarchart] = useState();
	const [userExcel, setUserExcel] = useState();
	const [transitions, setTransitions] = useState();
	const [completed, setCompleted] = useState();
	const [pending, setPending] = useState();
	const [accepted, setAccepted] = useState();
	const [rejected, setRejected] = useState();
	const [deposit, setDeposit] = useState();
	const [wasteCollection, setwasteCollection] = useState();
	const [advancereports, setadvancereports] = useState();
	const [categoryCollection, setcategoryCollection] = useState();
	const [enviorment, setEnviorment] = useState();
	const [transfer, setTransfer] = useState();
	const [certificate, setCertificate] = useState();

	//forgotpassword,changepassword,addCountry,DeleteCountry,updatecountry,add state
	const PostData = async (id, credentials, last = null) => {
		console.log(id);
		console.log(credentials);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: headers,
			})
			.then((response) => {
				console.log(response);
				console.log(response.data.message);
				toastr.success(response.data.message);
				if (response.data.status == true) {
					setApiCallSucceeded(true);
				}

				let click_btn_id = null;

				if (id === "bankBranch/assign_user") {
					click_btn_id = "v-pills-step3-tab";
				}

				if (id === "bankBranch/assign_waste_type") {
					if (last) {
						// fifth one
						last();
					} else {
						// third one
						click_btn_id = "v-pills-step4-tab";
					}
				}

				if (id === "bankBranch/assign_working_areas") {
					click_btn_id = "v-pills-step5-tab";
				}

				document.getElementById(click_btn_id).click();
			})
			.catch((e) => {
				console.log(e);
				toastr.error(parse(e.response.data.message));
				// console.log(e.response.data.message);
			});
	};

	const Step1 = async (id, credentials) => {
		await axios
			.post(`${url}${id}`, credentials, {
				headers: headers,
			})
			.then((response) => {
				console.log(response);
				console.log(response.data.message);
				toastr.success(response.data.message);
				console.log(response.data.id);
				console.log("step1");
				Cookies.set("branch_type_id", response.data.id);
				document.getElementById("v-pills-profile-tab").click();
			})
			.catch((e) => {
				console.log(e);
				toastr.error(parse(e.response.data.message));
			});
	};
	const AddProduct = async (id, credentials) => {
		console.log(id);
		console.log(credentials);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: headers,
			})
			.then((response) => {
				toastr.success(response.data.message);

				Cookies.set("product_id", response.data.id);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(parse(e.response.data.message));
			});
	};

	const AddExcel = (formData) => {
		console.log(formData);
		axios
			.post(`${url}userDetail/upload_citizens`, formData, config)
			.then((response) => {
				console.log(response);
				console.log(headers);
				toastr.success(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(e.response.data.message);
			});
	};
	const AddDeposit = (formData) => {
		console.log(formData);
		axios
			.post(`${url}transaction/upload_deposits`, formData, config)
			.then((response) => {
				console.log(response);
				console.log(headers);
				toastr.success(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(e.response.data.message);
			});
	};
	const AddSignature = (formData) => {
		axios
			.post(`${url}bankBranch/add_other_signature`, formData, config)
			.then((response) => {
				console.log(response);
				console.log(headers);
				toastr.success(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(e.response.data.message);
			});
	};
	const AddCategory = (formData) => {
		axios
			.post(`${url}category/add_category`, formData, config)
			.then((response) => {
				console.log(response);
				console.log(headers);
				toastr.success(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(e.response.data.message);
			});
	};
	const UploadTransfer = (formData) => {
		axios
			.post(`${url}transaction/upload_transfer_doc`, formData, config)
			.then((response) => {
				console.log(response);
				console.log(headers);
				toastr.success(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(e.response.data.message);
			});
	};

	const UpdateCategory = (formData) => {
		axios
			.post(`${url}category/update_category`, formData, config)
			.then((response) => {
				console.log(response);
				console.log(headers);
				toastr.success(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				toastr.error(e.response.data.message);
			});
	};

	// User
	const GetSpecificUser = async (id, credentials) => {
		console.log(id);
		console.log(credentials);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setspecificUser(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const Dashboard = async (id, credentials) => {
		console.log(credentials);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(credentials);
				console.log(response);
				setDashboard(response.data.data);
				setdrilldown(response.data.data.waste_collection_graph.drilldown);
				setbarchart(response.data.data.waste_collection_graph.first);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const BranchUsers = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setbankbranchusers(response.data.data);
				setbankbranchusersAdditional(response.data.additional);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const AllBranchUsers = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setallbankbranchusers(response.data.data);
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const BranchFilter = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setbranchfilter(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetUser = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setUser(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const BranchUserExcel = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setUserExcel(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetUserRole = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setData(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetAllBranchType = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setBranchType(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetAllBankBranch = async (id, credentials) => {
		// console.log(id);
		setLoading(true);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setbankBranch(response.data.data);
				setLoading(false);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				setLoading(false);
				console.log(e);
			});
	};

	const GetBankBranch = async (id, credentials) => {
		// console.log(id);
		setLoading(true);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setBranchData(response.data.data);
				setdetailbankBranch(response.data.data.users);
				setdetailbankBranchCitizens(response.data.data.citizens);
				setdetailbankBranchWasteCategory(response.data.data.waste_accepted);
				setdetailbankBranchWorkingAreas(response.data.data.working_areas);
				setdetailbankBranchWasteProcess(response.data.data.waste_process);
				setLoading(false);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	};
	const Transitions = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setTransitions(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Completed = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setCompleted(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Pending = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setPending(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Accepted = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setAccepted(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Rejected = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setRejected(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Deposit = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setDeposit(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const WasteCollection = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setwasteCollection(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const AdvanceReports = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setadvancereports(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Certificate = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setCertificate(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Enviourment = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setEnviorment(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const CategoryCollection = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setcategoryCollection(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const Transfer = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setTransfer(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetWasteCategory = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setwasteCategory(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetAllWaste = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setallWaste(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetAllWasteType = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setallWasteType(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetAllUnits = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setallUnits(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetGSt = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setGst(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetAllCalculationStandard = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setallCalculationStandard(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const GetWasteProcess = async (id, credentials) => {
		// console.log(id);
		await axios
			.post(`${url}${id}`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setwasteProcess(response.data.data);
				// toastr.success(response.data.message)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const States = async (credentials) => {
		// console.log(id);
		console.log(credentials);
		await axios
			.post(`${url}state`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setState(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const District = async (credentials) => {
		// console.log(id);
		await axios
			.post(`${url}district`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setDistrict(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const City = async (credentials) => {
		// console.log(id);
		await axios
			.post(`${url}city`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setCity(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const Area = async (credentials) => {
		// console.log(id);
		await axios
			.post(`${url}area`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setArea(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const Profile = async (credentials) => {
		// console.log(id);
		console.log(credentials);
		await axios
			.post(`${url}user/profile`, credentials, {
				headers: {
					"Content-Type": "application/json",
					"Client-Service": "ZWCAPP",
					"Auth-Key": "4UyYF2Sqz256901zrjoAAZpqf67",
					uid: Cookies.get("id"),
					token: Cookies.get("num"),
				},
			})
			.then((response) => {
				console.log(response);
				setProfile(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	return (
		<HandleContext.Provider
			value={{
				AddSignature,CategoryCollection,branchfilter,BranchFilter,categoryCollection,certificate,Certificate,Enviourment,enviorment,
				UploadTransfer,bankbranchusersAdditional,allbankbranchusers,AllBranchUsers,
				WasteCollection,wasteCollection,AdvanceReports,advancereports,
				Transfer,
				transfer,
				AddDeposit,
				Deposit,
				deposit,
				Rejected,
				rejected,
				Accepted,
				accepted,
				Pending,
				pending,
				Completed,
				completed,
				Transitions,
				transitions,
				barchart,
				drilldown,
				apiCallSucceeded,
				AddProduct,
				bankbranchusers,
				BranchUsers,
				GetBankBranch,
				Dashboard,
				dashboard,
				BranchData,
				detailbankBranchWasteProcess,
				bankBranchCitizens,
				detailbankBranchWorkingAreas,
				detailbankBranchWasteCategory,
				detailbankBranch,
				Step1,
				PostData,
				AddCategory,
				UpdateCategory,
				GetAllBankBranch,
				bankBranch,
				GetUserRole,
				GetAllBranchType,
				branchType,
				allCalculationStandard,
				GetAllCalculationStandard,
				GetGSt,
				gst,
				GetAllUnits,
				allUnits,
				GetWasteProcess,
				GetAllWasteType,
				allWasteType,
				allWaste,
				GetAllWaste,
				wasteprocess,
				GetWasteCategory,
				wastecategory,
				GetUser,
				GetSpecificUser,
				specificuser,
				data,
				user,
				States,
				City,
				District,
				Area,
				state,
				city,
				district,
				area,
				Profile,
				profile,
				BranchUserExcel,
				userExcel,
				AddExcel,
			}}
		>
			<div style={{ position: "relative" }}>
				{/* loader */}
				{loading && (
					<div
						style={{
							position: "fixed",
							zIndex: 10000,
							height: "100vh",
							width: "100vw",
							top: 0,
							left: 0,
							background: "rgba(0,0,0,.7)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							class="spinner-border"
							role="status"
							style={{
								width: "3rem",
								height: "3rem",
								zIndex: 10001,
								color: "#059669",
							}}
						>
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				)}
				{props.children}
			</div>
		</HandleContext.Provider>
	);
}
