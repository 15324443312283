import React, { useEffect, useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import $ from 'jquery';
import DataTables from 'datatables.net-dt'
import Updatepassword from '../Users/User/Updatepassword'

export default function Citizens() {
  setTimeout(() => {
    let searchid = document.getElementById('myTable_filter')
    let id = document.getElementById('myTable_length');
    id.style.padding = '10px'
    searchid.style.padding = '10px'
  }, 2000);

  $(document).ready(function () {
    $('#myTable').DataTable();
  });

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const { BranchUsers, bankbranchusers, bankbranchusersAdditional } = context;

  useEffect(() => {
    BranchUsers("dashboard/users", JSON.stringify({ role: "citizens", branch: Cookies.get('branch') }))
  }, [])

  console.log(bankbranchusers);

  let count = 0;

  const handleButtonClick = async (id) => {
    // Set the cookie value
    await Cookies.set('specificuser_id', id);

    // Navigate to a new page
    navigate('/specificuser');
  };
  const certificate = async (id) => {
    // Set the cookie value
    await Cookies.set('user_id', id);

    // Navigate to a new page
    navigate('/usercertificate');
  };

  return (
    <div>
      {/* Update Password Modal */}
      <div className="modal fade" id="staticBackdrop12" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Change User Password</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Updatepassword />
          </div>
        </div>
      </div>
      <div>
        <Navbar />
        <div className='page-content'>
          <Sidebar />
          <div class="page-content">
            <div class="content-wrapper">
              <div class="page-header page-header-light">
                <div class="page-header-content header-elements-md-inline">
                  <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                    <h4> <span style={{ fontSize: '18px' }} class="font-weight-semibold">All ZWC Accounts</span></h4>

                    <div class="header-elements ">

                    </div>
                  </div>
                  <hr style={{ margin: "0px" }} />

                </div>

                <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                  <div class="d-flex">
                    <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">All ZWC Accounts</span></ul>

                    <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                  </div>
                </div>
              </div>
              <div className='content'>
                <>
                  <div className='card' >

                    {bankbranchusers ? <>
                      <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>   All list of <code>ZWC Accounts</code>

                        </div>
                        {/* <Link to='/excel' style={{ cursor: 'pointer',color:'black',textDecoration:'none' }}><i style={{ color: 'green' }} className='ph-plus-circle'></i> Import ZWC Accounts</Link> */}
                      </div>
                      <div id="w0" className="grid-view">
                        <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable">
                          <thead>
                            <tr  >
                              <th>#</th>
                              <th>Users</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Address</th>
                              {bankbranchusersAdditional.map((item, index) => (
          <>
            {Object.values(item).map((value, i) => (
              <th key={i}>{value}</th>
            ))}
          </>
        ))}
                              <th>Certificate</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bankbranchusers && bankbranchusers.map((e) => {
                              count++;
                              return <tr key={e.id} >
                                <td onClick={() => { handleButtonClick(e.id) }}>{count}</td>
                                <td onClick={() => { handleButtonClick(e.id) }}>{e.name}</td>
                                <td onClick={() => { handleButtonClick(e.id) }}>{e.email}</td>
                                <td onClick={() => { handleButtonClick(e.id) }}>{e.phone_num}</td>
                                <td onClick={() => { handleButtonClick(e.id) }}>{e.address}</td>

                        {}      {e.hasOwnProperty('ad1') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad1}</td>}
                              {e.hasOwnProperty('ad2') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad2}</td>}
                              {e.hasOwnProperty('ad3')&&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad3}</td>}
                              {e.hasOwnProperty('ad4') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad4}</td>}
                              {e.hasOwnProperty('ad5') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad5}</td>}
                              {e.hasOwnProperty('ad6') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad6}</td>}
                              {e.hasOwnProperty('ad7') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad7}</td>}
                              {e.hasOwnProperty('ad8') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad8}</td>}
                              {e.hasOwnProperty('ad9') &&  <td onClick={() => { handleButtonClick(e.id) }}> {e.ad9}</td>}
                              { e.hasOwnProperty('ad10') &&  <td onClick={() => { handleButtonClick(e.id) }}>{e.ad10}</td>}

                                <td
                                  onClick={() => {
                                    certificate(e.id)
                                    Cookies.set('user_id', e.id)
                                  }} ><button title='View Certificate' className='btn btn-success'><i className='icon-file-text3
                    '></i></button></td>
                                <td data-toggle="modal" data-target="#staticBackdrop12" onClick={() => {
                                  Cookies.set('user_id', e.id)
                                }}><button title='Update Password' className='btn btn-success'><i className='ph-key'></i></button></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </div>
                    </> : ""}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
