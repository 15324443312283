import React, { useState, useRef, useContext, useEffect } from "react";
import $ from "jquery";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Step2() {
	const modalRef = useRef(null);

	const context = useContext(HandleContext);
	const {
		GetUserRole,
		data,
		GetUser,
		user,
		GetAllBranchType,
		branchType,
		PostData,
	} = context;

	useEffect(() => {
		GetUserRole("userRole");
		GetUser("userDetail");
	}, []);

	let navigate = useNavigate();
	const [credentials, setCredentials] = useState({
		branch_id: "",
		user_id: "",
		role_id: "",
	});

	const handleOnchange = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value });
	};

	const addStep2 = async (e) => {
		console.log("Submitted");
		e.preventDefault();
		console.log(credentials);
		await PostData(
			"bankBranch/assign_user",
			JSON.stringify({
				...credentials,
				branch_id: Cookies.get("branch_type_id"),
			})
		);
		$(modalRef.current).modal("hide");
	};

	return (
		<div className="card">
			<div className="card-body">
				<form id="w0" className="row" onSubmit={addStep2}>
					{/* <div className="modal-body"> */}
					<input
						type="hidden"
						name="_csrf-frontend"
						defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
					/>
					<div className="form-group col-md-6">
						<label>Select User</label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="user_id"
							onChange={(e) => {
								setCredentials({
									...credentials,
									user_id: e.target.value,
									branch_id: Cookies.get("branch_id"),
								});
							}}
							required
						>
							<option>-- Select User --</option>
							{user &&
								user.map((e) => {
									console.log(e);
									return (
										<option value={e.id}>
											{e.name} ({e.email},{e.phone_num})
										</option>
									);
								})}
						</select>
					</div>
					<div className="form-group col-md-6">
						<label>Select User Role</label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="role_id"
							onChange={(e) => {
								setCredentials({ ...credentials, role_id: e.target.value });
							}}
							required
						>
							<option>-- Select User Role --</option>
							{data &&
								data.map((e) => {
									return <option value={e.id}>{e.role_name}</option>;
								})}
						</select>
					</div>

					{/* </div> */}
					{/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
					<button type="submit" className="btn btn-primary mt-4">
						Submit
					</button>
					{/* </div> */}
				</form>
			</div>
		</div>
	);
}
