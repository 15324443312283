import React, { useEffect, useContext, useState } from "react";
import Sidebar from "./../../Sidebar";
import Navbar from "../.././Navbar";
// import Content from '../Content'
import { Link } from "react-router-dom";
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import DataTables from "datatables.net-dt";

export default function UploadTransfer() {
    const [credentials, setCredentials] = useState({
        stock_transfer_id: "",
        doc_type: "",
        doc_no: "",
        file: "",
    });



    let navigate = useNavigate();
    const context = useContext(HandleContext);
    const { UploadTransfer, transfer } = context;


    console.log(transfer);

    let count = 0;

    const handleSubmit = (e) => {
        const formData = new FormData();

        e.preventDefault();
        console.log("submitted");
        console.log("Submitted");
        const { doc_type,doc_no,file,stock_transfer_id } = credentials
        formData.append("doc_type", doc_type)
        formData.append("doc_no", doc_no)
        formData.append("file", file)
        formData.append("stock_transfer_id", stock_transfer_id)
        console.log(credentials);
        console.log(formData);
        UploadTransfer(formData);
        setTimeout(() => {
            setCredentials({
           
                doc_type: "",
                doc_no: "",
                file: "",
            })
        }, 2000);
    };



    return (
        <div>
            <div>
                <Navbar />
                <div className="page-content">
                    <Sidebar />
                    <div class="page-content">
                        <div class="content-wrapper">
                            <div class="page-header page-header-light">
                                <div class="page-header-content header-elements-md-inline">
                                    <div
                                        class="page-title d-flex"
                                        style={{ justifyContent: "space-between" }}
                                    >
                                        <h4>
                                            {" "}
                                            <span
                                                style={{ fontSize: "18px" }}
                                                class="font-weight-semibold"
                                            >
                                                Transfer
                                            </span>
                                        </h4>

                                        <div class="header-elements "></div>
                                    </div>
                                    <hr style={{ margin: "0px" }} />
                                </div>

                                <div
                                    class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
                                    style={{
                                        paddingLeft: "14px",
                                        marginBottom: "6px",
                                        paddingRight: "20px",
                                    }}
                                >
                                    <div class="d-flex">
                                        <ul class="breadcrumb">
                                            <Link to="/">Home</Link>
                                            <Link to="/" class="breadcrumb-item">
                                                <i
                                                    style={{ marginRight: "6px" }}
                                                    class="ph-house-line mr-4"
                                                ></i>
                                                Home
                                            </Link>
                                            <span class="breadcrumb-item active">Transfer</span>
                                        </ul>

                                        <a
                                            href="#"
                                            class="header-elements-toggle text-default d-md-none"
                                        >
                                            <i className="icon-more"></i>
                                        </a>
                                        <div style={{ flexGrow: 1 }}></div>

                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <form id="w0" onSubmit={handleSubmit}>
                                                <input
                                                    type="hidden"
                                                    name="_csrf-frontend"
                                                    defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
                                                />
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="label">Doc Type</label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="exampleCheck1"
                                                                name="doc_type"
                                                                value={credentials.doc_type}
                                                                onChange={(e) => {
                                                                    setCredentials({
                                                                        ...credentials,
                                                                        doc_type: e.target.value, stock_transfer_id: Cookies.get('transfer_id')
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="label">Doc Number</label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="exampleCheck1"
                                                                name="doc_no"
                                                                value={credentials.doc_no}
                                                                onChange={(e) => {
                                                                    setCredentials({
                                                                        ...credentials,
                                                                        doc_no: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3" style={{marginTop:'25px'}}>
                                                        <div className="form-group">
                                                            <input type="file" class="form-control-file" id="exampleFormControlFile1" name='file' onChange={(e) => {
                                                                setCredentials({ ...credentials, file: e.target.files[0] })
                                                            }} required />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <button
                                                            type="submit"
                                                            style={{ marginTop: "20px" }}
                                                            className="btn btn-success"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>

                                                    <div className="col-md-2" style={{ marginTop: "22px" }}>

                                                        {/* <Link to='/adddeposits' style={{ cursor: 'pointer',color:'black',textDecoration:'none' }}><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Deposits</Link> */}
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
