import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import HandleContext from "../Context/HandleContext";

export default function Sidebar() {
	const context = useContext(HandleContext);
	const {
		GetData,
		data,
		States,
		state,
		District,
		district,
		City,
		city,
		Profile,
		profile,
	} = context;

	function bank() {
		var element = document.getElementById("link");
		var ul = document.getElementById("ul");

		if (element.classList.contains("nav-item-open")) {
			element.classList.remove("nav-item-open");
			ul.classList.remove("show");
			ul.classList.remove("nav-item-expanded");
		} else {
			element.classList.add("nav-item-open");
			ul.classList.add("show");
			ul.classList.add("nav-item-expanded");
		}
	}
	function user() {
		var element = document.getElementById("link2");
		var ul = document.getElementById("ul2");

		if (element.classList.contains("nav-item-open")) {
			element.classList.remove("nav-item-open");
			ul.classList.remove("show");
			ul.classList.remove("nav-item-expanded");
		} else {
			element.classList.add("nav-item-open");
			ul.classList.add("show");
			ul.classList.add("nav-item-expanded");
		}
	}
	function transitions() {
		var element = document.getElementById("link3");
		var ul = document.getElementById("ul3");

		if (element.classList.contains("nav-item-open")) {
			element.classList.remove("nav-item-open");
			ul.classList.remove("show");
			ul.classList.remove("nav-item-expanded");
		} else {
			element.classList.add("nav-item-open");
			ul.classList.add("show");
			ul.classList.add("nav-item-expanded");
		}
	}
	function reports() {
		var element = document.getElementById("link4");
		var ul = document.getElementById("ul4");

		if (element.classList.contains("nav-item-open")) {
			element.classList.remove("nav-item-open");
			ul.classList.remove("show");
			ul.classList.remove("nav-item-expanded");
		} else {
			element.classList.add("nav-item-open");
			ul.classList.add("show");
			ul.classList.add("nav-item-expanded");
		}
	}
	function redeem() {
		var element = document.getElementById("link5");
		var ul = document.getElementById("ul5");

		if (element.classList.contains("nav-item-open")) {
			element.classList.remove("nav-item-open");
			ul.classList.remove("show");
			ul.classList.remove("nav-item-expanded");
		} else {
			element.classList.add("nav-item-open");
			ul.classList.add("show");
			ul.classList.add("nav-item-expanded");
		}
	}

	useEffect(() => {
		Profile();
	}, []);
	// console.log(profile);
	return (
		<div>
			{/* <Navbar/> */}
			{/* <div className='page-content'> */}
			{/* Main sidebar */}
			<div
				className="sidebar sidebar-dark sidebar-main sidebar-expand-lg"
				style={{ height: "100vh", position: "sticky" }}
			>
				{/* Sidebar content */}
				<div className="sidebar-content">
					{/* Sidebar header */}
					<div className="sidebar-section">
						<div className="sidebar-section-body d-flex justify-content-center">
							<h5 className="sidebar-resize-hide flex-grow-1 my-auto">
								Navigation
							</h5>
							<div>
								{/* <button type="button" className="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-control sidebar-main-resize d-none d-lg-inline-flex">
              <i className="ph-arrows-left-right" />
            </button>
            <button type="button" className="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-mobile-main-toggle d-lg-none">
              <i className="ph-x" />
            </button> */}
							</div>
						</div>
					</div>
					{/* /sidebar header */}
					{/* Main navigation */}
					<div id="sd" className="sidebar-section">
						<ul
							className="nav nav-sidebar"
							data-nav-type="accordion"
							style={{ fontSize: "14px", fontWeight: "bolder" }}
						>
							{/* Main */}
							<li className="nav-item-header pt-0">
								<div className="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">
									Main
								</div>
								<i className="ph-dots-three sidebar-resize-show" />
							</li>
							<li className="nav-item">
								<Link to="/" className="nav-link active">
									<i className="ph-house" />
									<span>
										Dashboard
										{/* <span className="d-block fw-normal opacity-50">No pending orders</span> */}
									</span>
								</Link>
							</li>
							{Cookies.get("user_role_id") == "1" && (
								<>
									<li className="nav-item">
										<Link to="/areamaster" className="nav-link ">
											<i className="icon-flag3" />
											<span>Area Master</span>
										</Link>
									</li>
									<li className="nav-item">
										<Link to="/wastemanagement" className="nav-link ">
											<i className="ph-recycle" />
											<span>Misplaced Resource Management</span>
										</Link>
									</li>

									<li
										onClick={bank}
										id="link"
										class=" nav-item nav-item-submenu "
									>
										<a href="#" class="nav-link">
											<i class="ph-bank"></i>
											<span>Branch Management</span>
										</a>

										<ul id="ul" class="nav-group-sub collapse ">
											<li className="nav-item">
												<Link to="/bankbranch" className="nav-link ">
													<i className="icon-vcard" />
													<span>Bank Branch</span>
												</Link>
											</li>
											<li className="nav-item">
												<Link to="/addsignature" className="nav-link ">
													<i className="icon-pen" />
													<span>Manage Signature</span>
												</Link>
											</li>
											{/* <li class="nav-item"><Link to="/citizens" class="nav-link">
                <i className="icon-user-tie" />
                 <span> ZWC Accounts</span>
                  </Link></li> */}
											{/* <li class="nav-item"><Link to="/aggregators" class="nav-link">         <i className="icon-users" />
                 <span> Aggregators</span></Link></li>
								<li class="nav-item"><Link to="/warriors" class="nav-link"><i className='icon-users4'></i><span>Warriors</span></Link></li>
								<li class="nav-item"><Link to="/collectors" class="nav-link"><i className="icon-collaboration" /><span>Collectors</span></Link></li>
								<li class="nav-item"><Link to="/brands" class="nav-link"><i className='icon-star-full2'></i><span>Brands</span></Link></li>
								<li class="nav-item"><Link to="/recyclers" class="nav-link"><i className='icon-spinner10'></i><span>Recyclers</span></Link></li> */}
										</ul>
									</li>
								</>
							)}
							<li onClick={user} id="link2" class=" nav-item nav-item-submenu ">
								<a href="#" class="nav-link">
									<i class="ph-person"></i>
									<span>User Management</span>
								</a>

								<ul id="ul2" class="nav-group-sub collapse ">
									<li class="nav-item">
										<li class="nav-item">
											<Link to="/addcitizen" class="nav-link">
												<i className="icon-user-plus"></i>
												<span>Add ZWC Accounts</span>
											</Link>
										</li>
										{/* <li class="nav-item">
											<Link to="/excel" class="nav-link">
												<i className="icon-user-plus"></i>
												<span>Import ZWC Accounts</span>
											</Link>
										</li> */}
										<Link to="/citizens" class="nav-link">
											<i className="icon-user-tie" />
											<span> ZWC Accounts</span>
										</Link>
									</li>

									{Cookies.get("user_role_id") == "1" && (
										<>
											<li class="nav-item">
												<Link to="/aggregators" class="nav-link">
													{" "}
													<i className="icon-users" />
													<span> Aggregators</span>
												</Link>
											</li>
											<li class="nav-item">
												<Link to="/warriors" class="nav-link">
													<i className="icon-users4"></i>
													<span>Warriors</span>
												</Link>
											</li>
											<li class="nav-item">
												<Link to="/collectors" class="nav-link">
													<i className="icon-collaboration" />
													<span>Collectors</span>
												</Link>
											</li>
											<li class="nav-item">
												<Link to="/brands" class="nav-link">
													<i className="icon-star-full2"></i>
													<span>Brands</span>
												</Link>
											</li>
											<li class="nav-item">
												<Link to="/recyclers" class="nav-link">
													<i className="icon-spinner10"></i>
													<span>Recyclers</span>
												</Link>
											</li>
											<li class="nav-item">
												<Link to="/userroles" class="nav-link">
													<i className="icon-user-check"></i>
													<span>User Roles</span>
												</Link>
											</li>
											<li class="nav-item">
												<Link to="/adduser" class="nav-link">
													<i className="icon-user-plus"></i>
													<span>Add User</span>
												</Link>
											</li>
										</>
									)}
								</ul>
							</li>
							{Cookies.get('user_role_id')!=='11'?
							<li
								onClick={transitions}
								id="link3"
								class=" nav-item nav-item-submenu "
							>
								<a href="#" class="nav-link">
									<i class="icon-cash"></i>
									<span>Transactions</span>
								</a>

								<ul id="ul3" class="nav-group-sub collapse">
									{Cookies.get("user_role_id") !== "3" && (
										<li class="nav-item">
											<Link to="/transitions" class="nav-link">
												<i className="icon-plus3" />
												<span> Pickup Requests</span>
											</Link>
										</li>
									)}
									{/* <li class="nav-item"><Link to="/aggregators" class="nav-link">         <i className="icon-database" />
                    <span> Collections</span></Link></li> */}
									<li class="nav-item">
										<Link to="/deposits" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Deposits</span>
										</Link>
									</li>
									<li class="nav-item">
										<Link to="/transfers" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Stock Transfer</span>
										</Link>
									</li>
									{/* <li class="nav-item">
										<Link to="/stock_journal" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Stock Journal</span>
										</Link>
									</li> */}
									{/* <li class="nav-item">
										<Link to="/collectors" class="nav-link">
											<span>Transfer Deposits</span>
										</Link>
									</li> */}
								</ul>
							</li>:''}
							<li
								onClick={reports}
								id="link4"
								class=" nav-item nav-item-submenu "
							>
								<a href="#" class="nav-link">
									<i class="icon-cash"></i>
									<span>Reports</span>
								</a>

								<ul id="ul4" class="nav-group-sub collapse">
									{/* {Cookies.get("user_role_id") !== "3" && ( */}
										<li class="nav-item">
											<Link to="/wastecollection" class="nav-link">
												<i className="icon-plus3" />
												<span>Waste Collection </span>
											</Link>
										</li>
									{/* )} */}
									{/* <li class="nav-item"><Link to="/aggregators" class="nav-link">         <i className="icon-database" />
                    <span> Collections</span></Link></li> */}
									<li class="nav-item">
										<Link to="/collectionwise" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Category Wise Collection</span>
										</Link>
									</li>
									<li class="nav-item">
										<Link to="/enviourment" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Environment Saved</span>
										</Link>
									</li>
							
									{/* <li class="nav-item">
										<Link to="/collectors" class="nav-link">
											<span>Transfer Deposits</span>
										</Link>
									</li> */}
								</ul>
							</li>
							{Cookies.get('user_role_id')!=='11'?

							<li
								onClick={redeem}
								id="link5"
								class=" nav-item nav-item-submenu "
							>
								<a href="#" class="nav-link">
									<i class="icon-cash"></i>
									<span>Redeem Requests</span>
								</a>

								<ul id="ul5" class="nav-group-sub collapse">
									{/* {Cookies.get("user_role_id") !== "3" && ( */}
										<li class="nav-item">
											<Link to="/allrequests" class="nav-link">
												<i className="icon-plus3" />
												<span>All Requests </span>
											</Link>
										</li>
									{/* )} */}
									{/* <li class="nav-item"><Link to="/aggregators" class="nav-link">         <i className="icon-database" />
                    <span> Collections</span></Link></li> */}
									<li class="nav-item">
										<Link to="/pendingrequests" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Pending Requests</span>
										</Link>
									</li>
									<li class="nav-item">
										<Link to="/completedrequests" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Completed Requests</span>
										</Link>
									</li>
									<li class="nav-item">
										<Link to="/rejectedrequests" class="nav-link">
											<i className="icon-box-add"></i>
											<span>Rejected Requests</span>
										</Link>
									</li>
								
							
									{/* <li class="nav-item">
										<Link to="/collectors" class="nav-link">
											<span>Transfer Deposits</span>
										</Link>
									</li> */}
								</ul>
							</li>
							:''}
							{/* <li className="nav-item">
                <Link to="/" className="nav-link ">
                  <i className="icon-box" />
                  <span>Inventory Management</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link ">
                  <i className="icon-book" />
                  <span>Passbook</span>
                </Link>
              </li> */}

							{/* Forms */}

							{/* /forms */}
							{/* Components */}

							{/* /components */}
							{/* Layout */}

							{/* /page kits */}
						</ul>
					</div>
					{/* /main navigation */}
				</div>
				{/* /sidebar content */}
			</div>
			{/* /main sidebar */}
			{/* <Content/> */}
			{/* </div> */}
		</div>
	);
}
