import React, { useState, useContext, useEffect } from "react";
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Step1() {
	const context = useContext(HandleContext);
	const {
		GetAllBranchType,
		branchType,
		Step1,
		States,
		state,
		District,
		district,
		City,
		city,
		Area,
		area,
	} = context;
	console.log(state);
	useEffect(() => {
		States();
		GetAllBranchType("branchType");
	}, []);

	const [credentials, setCredentials] = useState({
		branch_type_id: "",
		branch_name: "",
		branch_contact: "",
		branch_email: "",
		branch_address: "",
		area_id: "",
		is_collection_request: "",
		is_direct_submission: "",
		is_sale: "",
		is_stock_transfer: "",
		is_waste_processing: "",
		is_purchase: "",
	});

	const handleOnchange = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value });
	};

	const addStep1 = (e) => {
		console.log("Submitted");
		e.preventDefault();
		console.log(credentials);
		Step1("bankBranch/add_branch", JSON.stringify(credentials));
	};

	console.log(branchType);

	let navigate = useNavigate();

	return (
		<div className="card">
			<div className="card-body">
				<form id="w0" className="row" onSubmit={addStep1}>
					{/* <div className="modal-body"> */}
					<input
						type="hidden"
						name="_csrf-frontend"
						defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
					/>
					<div className="form-group col-md-6">
						<label>Select Type of Branch</label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="branch_type_id"
							onChange={(e) => {
								setCredentials({
									...credentials,
									branch_type_id: e.target.value,
								});
							}}
							required
						>
							<option>-- Select Type of Branch --</option>
							{branchType &&
								branchType.map((e) => {
									return <option value={e.id}>{e.branch_type}</option>;
								})}
						</select>
					</div>
					<div className="form-group col-md-6">
						<label>Branch Name</label>
						<input
							type="text"
							className="form-control"
							value={credentials.branch_name}
							name="branch_name"
							onChange={handleOnchange}
							required
						/>
					</div>
					<div className="form-group col-md-4">
						<label>Contact Number </label>
						<input
							type="text"
							className="form-control"
							value={credentials.branch_contact}
							name="branch_contact"
							onChange={handleOnchange}
							required
						/>
					</div>
					<div className="form-group col-md-4">
						<label>Email ID </label>
						<input
							type="text"
							className="form-control"
							value={credentials.branch_email}
							name="branch_email"
							onChange={handleOnchange}
							required
						/>
					</div>
					<div className="form-group col-md-4">
						<label>Address</label>
						<input
							type="text"
							className="form-control"
							value={credentials.branch_address}
							name="branch_address"
							onChange={handleOnchange}
							required
						/>
					</div>
					<div className="form-group col-md-3">
						<label>Select State </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="country_id"
							onChange={(e) => {
								Cookies.set("state_id", e.target.value);
								District(JSON.stringify({ state_id: Cookies.get("state_id") }));
							}}
							required
						>
							<option>-- Select State --</option>
							{state &&
								state.map((e) => {
									console.log(e);
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					<div className="form-group col-md-3">
						<label>Select District </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="country_id"
							onChange={(e) => {
								Cookies.set("district_id", e.target.value);
								City(
									JSON.stringify({ district_id: Cookies.get("district_id") })
								);
							}}
							required
						>
							<option>-- Select District --</option>
							{district &&
								district.map((e) => {
									console.log(e);
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					<div className="form-group col-md-3">
						<label>Select City </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="country_id"
							onChange={(e) => {
								Cookies.set("city_id", e.target.value);
								Area(JSON.stringify({ city_id: Cookies.get("city_id") }));
							}}
							required
						>
							<option>-- Select City --</option>
							{city &&
								city.map((e) => {
									console.log(e);
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					<div className="form-group col-md-3">
						<label>Select Area </label>
						<select
							id="usercities-state"
							class="form-control select-search"
							name="area_id"
							onChange={(e) => {
								setCredentials({ ...credentials, area_id: e.target.value });
							}}
							required
						>
							<option>-- Select Area --</option>
							{area &&
								area.map((e) => {
									console.log(e);
									return <option value={e.id}>{e.name}</option>;
								})}
						</select>
					</div>
					<div className="col-md-6" style={{ marginBottom: "20px" }}>
						<div style={{ marginBottom: "10px" }}>
							Is Collection Request Avaliable ?
						</div>
						<div className="row">
							<div className="col-md-4">
								<input type="radio" value="1" name="is_collection_request" />{" "}
								Yes <br />
							</div>
							<div className="col-md-4">
								<input
									type="radio"
									value="0"
									name="is_collection_request"
									checked
								/>{" "}
								No
							</div>
						</div>
					</div>
					<div className="col-md-6" style={{ marginBottom: "20px" }}>
						<div style={{ marginBottom: "10px" }}>Is Direct Submission ?</div>
						<div className="row">
							<div className="col-md-4">
								<input type="radio" value="1" name="is_direct_submission" /> Yes{" "}
								<br />
							</div>
							<div className="col-md-4">
								<input
									type="radio"
									value="0"
									name="is_direct_submission"
									checked
								/>{" "}
								No
							</div>
						</div>
					</div>
					<div className="col-md-6" style={{ marginBottom: "20px" }}>
						<div style={{ marginBottom: "10px" }}>Is Sale Avaliable ?</div>
						<div className="row">
							<div className="col-md-4">
								<input type="radio" value="1" name="is_sale" required /> Yes{" "}
								<br />
							</div>
							<div className="col-md-4">
								<input type="radio" value="0" name="is_sale" checked /> No
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div style={{ marginBottom: "10px" }}>
							Is Stock Transfer Avaliable ?
						</div>
						<div className="row">
							<div className="col-md-4">
								<input type="radio" value="1" name="is_stock_transfer" /> Yes{" "}
								<br />
							</div>
							<div className="col-md-4">
								<input
									type="radio"
									value="0"
									name="is_stock_transfer"
									checked
								/>{" "}
								No
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div style={{ marginBottom: "10px" }}>
							Is Waste Processsing Avaliable ?
						</div>
						<div className="row">
							<div className="col-md-4">
								<input type="radio" value="1" name="is_waste_processing" /> Yes{" "}
								<br />
							</div>
							<div className="col-md-4">
								<input
									type="radio"
									value="0"
									name="is_waste_processing"
									checked
								/>{" "}
								No
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div style={{ marginBottom: "10px" }}>Is Purchase Avaliable ?</div>
						<div className="row">
							<div className="col-md-4">
								<input type="radio" value="1" name="is_purchase" /> Yes <br />
							</div>
							<div className="col-md-4">
								<input type="radio" value="0" name="is_purchase" checked /> No
							</div>
						</div>
					</div>
					{/* </div> */}
					{/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
					<button type="submit" className="btn btn-primary mt-4">
						Submit
					</button>
					{/* </div> */}
				</form>
			</div>
		</div>
	);
}
