import React, { useEffect, useContext } from 'react'
import HandleContext from '../../../Context/HandleContext'
import Sidebar from '../../Sidebar'
import Navbar from '../../Navbar'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import '../branch.css'
import $ from 'jquery'

export default function GetBranch() {
   
    $(document).ready(function () {
        $('#myTable1').DataTable();
      });
    $(document).ready(function () {
        $('#myTable2').DataTable();
      });
    $(document).ready(function () {
        $('#myTable3').DataTable();
      });
      $(document).ready(function () {
        $('#myTable4').DataTable();
      });
      $(document).ready(function () {
        $('#myTable6').DataTable();
      });

      setTimeout(() => {
        let searchid1=document.getElementById('myTable1_filter')
        let id1=document.getElementById('myTable1_length');
        id1.style.padding='10px'
        searchid1.style.padding='10px'
        let searchid2=document.getElementById('myTable2_filter')
        let id2=document.getElementById('myTable2_length');
        id2.style.padding='10px'
        searchid2.style.padding='10px'
        let searchid3=document.getElementById('myTable3_filter')
        let id3=document.getElementById('myTable3_length');
        id3.style.padding='10px'
        searchid3.style.padding='10px'

        let searchid4=document.getElementById('myTable4_filter')
        let id4=document.getElementById('myTable4_length');
        id4.style.padding='10px'
        searchid4.style.padding='10px'
        
        let searchid6=document.getElementById('myTable6_filter')
        let id6=document.getElementById('myTable6_length');
        id6.style.padding='10px'
        searchid6.style.padding='10px'
      }, 2000);
        
    let url = 'https://api.zerowastecitizen.in/'

    let count = 0;
    let count2 = 0;
    let count3 = 0;
    let count4 = 0;
    let count5 = 0;    

    const context = useContext(HandleContext);
    const { detailbankBranchWorkingAreas, BranchData, detailbankBranchWasteProcess, detailbankBranchWasteCategory, GetBankBranch, detailbankBranch, PostData,bankBranchCitizens} = context;

    console.log(detailbankBranch);
    // console.log(detailbankBranch.users);
    // let users=detailbankBranch.users

    useEffect(() => {
        GetBankBranch("bankBranch/get_branch", JSON.stringify({ id: Cookies.get('branch_id') }))
    }, [])
    
    const DeleteUser = (url, id) => {
        PostData(url, JSON.stringify({ branch_id: Cookies.get('branch_id'), user_id: id }))
        setTimeout(() => {
            GetBankBranch("bankBranch/get_branch", JSON.stringify({ id: Cookies.get('branch_id') }))
        }, 200);
    }

    const DeleteProcess = (url, id) => {
        PostData(url, JSON.stringify({ branch_id: Cookies.get('branch_id'), branch_process_id: id }))
        setTimeout(() => {
            GetBankBranch("bankBranch/get_branch", JSON.stringify({ id: Cookies.get('branch_id') }))
        }, 200);
    }

    const DeleteWorkingArea = (url, id) => {
        PostData(url, JSON.stringify({ branch_id: Cookies.get('branch_id'), working_area_id: id }))
        setTimeout(() => {
            GetBankBranch("bankBranch/get_branch", JSON.stringify({ id: Cookies.get('branch_id') }))
        }, 200);
    }

    const DeleteCategory = (url, id) => {
        PostData(url, JSON.stringify({ branch_id: Cookies.get('branch_id'), waste_accepted_id: id }))
        setTimeout(() => {
            GetBankBranch("bankBranch/get_branch", JSON.stringify({ id: Cookies.get('branch_id') }))
        }, 200);
    }
    
    return (
        <div>
            {/* Assign user*/}
            <div class="modal fade" id="staticBackdrop22" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Branch User</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Step2 />
                    </div>
                </div>
            </div>
            {/* Assign waste type*/}
            <div class="modal fade" id="staticBackdrop23" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Waste Type</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Step3 />
                    </div>
                </div>
            </div>
            {/* Assign working areas*/}
            <div class="modal fade" id="staticBackdrop24" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Working Areas</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Step4 />
                    </div>
                </div>
            </div>
            {/* Assign waste process*/}
            <div class="modal fade" id="staticBackdrop25" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Waste Process</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Step5 />
                    </div>
                </div>
            </div>
            <Navbar />
            <div className='page-content'>
                <Sidebar />
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="page-header page-header-light">
                            <div className="page-header-content header-elements-md-inline">
                                <div className="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                                    <h4> <span className="font-weight-semibold">Bank Branch</span></h4>

                                    <div className="header-elements ">

                                    </div>
                                </div>
                                <hr style={{ margin: "0px" }} />

                            </div>

                            <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                                <div className="d-flex">
                                    <ul className="breadcrumb"><Link to="/">Home</Link><Link to="/" className="breadcrumb-item"><i style={{ marginRight: '6px' }} className="ph-house-line mr-4"></i> Home </Link><span className="breadcrumb-item active">Bank Branch</span></ul>

                                    <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className='content'>
                        {/* <div id="exTab1" class="container"> */}


                                {/* Table with togglable columns */}
                                {BranchData ? 
                                // branch box
                                <div className='row'>
                                <div className='col-md-6'>
                                    <div className='card'>

                                        <div className="card-body" style={{overflow:'hidden' ,display: 'flex', justifyContent: 'space-between' }}>
                                            <div>    <code>Branch Details</code></div>

                                        </div>

                                        <div id="w0" className="grid-view">
                                            <div className='row'>
                                    {/* <div className='col-md-6'> */}
                                        <div className='heads'>
                                            <span className='lab'>Branch Name</span>
                                            <span className='value'>{BranchData.branch_name}</span>
                                        </div>
                                        <div className='heads'>
                                            <span className='lab'>Branch Email</span>
                                            <span className='value'>{BranchData.branch_email}</span>
                                        </div>
                                        <div className='heads'>
                                            <span className='lab'>Branch Address</span>
                                            <span className='value'>{BranchData.branch_address}</span>
                                        </div>
                                        <div className='heads'>
                                            <span className='lab'>Branch Contact</span>
                                            <span className='value'>{BranchData.branch_contact}</span>
                                        </div>
                                    {/* </div> */}
                                    {/* <div className='col-md-6'> */}
                                        <div className='heads'>
                                            <span className='lab'>Area</span>
                                            <span className='value'>{BranchData.area}</span>
                                        </div>
                                        <div className='heads'>
                                            <span className='lab'>City</span>
                                            <span className='value'>{BranchData.city}</span>
                                        </div>
                                        <div className='heads'>
                                            <span className='lab'>District</span>
                                            <span className='value'>{BranchData.district}</span>
                                        </div>
                                        <div className='heads'>
                                            <span className='lab'>State</span>
                                            <span className='value'>{BranchData.state}</span>
                                        </div>
                                    {/* </div>      */}
                                                                           </div>
                                            
                                        </div>
                                        
                                        </div>
                                    </div>
                                    {/* users */}
                                    <div className='col-md-6'>
                                    <div className='card'>
                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>   List of <code>Users</code></div>
                                            <div data-toggle="modal" data-target="#staticBackdrop22" style={{ cursor: 'pointer' }} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Branch User</div>
                                        </div>

                                        <div id="w0" className="grid-view">
                                            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable1">
                                                {detailbankBranch.length > 0 ?
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Phone Number</th>
                                                                {/* <th>Password</th> */}
                                                                <th>Role</th>
                                                                <th className="action-column">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailbankBranch && detailbankBranch.map((e) => {
                                                                console.log(e);
                                                                count++;
                                                                return <tr key={e.id}>
                                                                    <td>{count}</td>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.email}</td>
                                                                    <td>{e.phone_num}</td>
                                                                    {/* <td>{e.password}</td> */}
                                                                    <td>{e.role_name}</td>
                                                                    <td >
                                                                        <button className='btn btn-danger btn-sm' onClick={() => {
                                                                            console.log(e.id);
                                                                            DeleteUser('bankBranch/remove_user', e.user_id)
                                                                        }}><i className='ph-trash'></i></button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </>
                                                    : <thead style={{ fontSize: '15px', fontWeight: '600', margin: '0px 10px', textAlign: 'center' }}>There's no user in this branch</thead>}
                                            </table>
                                        </div>
                                        {/* <div style={{ background: '#f0f4f9' }} className='card-footer'></div> */}
                                    </div>
                                    </div>
                                    {/* citizens */}
                                    <div className='col-md-7'>
                                        <div className='card'>
                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>   List of <code>Citizens</code></div>
                                            {/* <div data-toggle="modal" data-target="#staticBackdrop22" style={{ cursor: 'pointer' }} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Branch User</div> */}
                                        </div>

                                        <div id="w0" className="grid-view">
                                            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable6">
                                                {bankBranchCitizens.length > 0 ?
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Phone Number</th>
                                                                {/* <th>Password</th> */}
                                                                <th>Role</th>
                                                                <th className="action-column">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bankBranchCitizens && bankBranchCitizens.map((e) => {
                                                                console.log(e);
                                                                count2++;
                                                                return <tr key={e.id}>
                                                                    <td>{count2}</td>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.email}</td>
                                                                    <td>{e.phone_num}</td>
                                                                    {/* <td>{e.password}</td> */}
                                                                    <td>{e.role_name}</td>
                                                                    <td >
                                                                        <button className='btn btn-danger btn-sm' onClick={() => {
                                                                            console.log(e.id);
                                                                            DeleteUser('bankBranch/remove_user', e.user_id)
                                                                        }}><i className='ph-trash'></i></button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </>
                                                    : <thead style={{ fontSize: '15px', fontWeight: '600', margin: '0px 10px', textAlign: 'center' }}>There's no user in this branch</thead>}
                                            </table>
                                        </div>
                                       </div>
                                    </div>
                                    {/* waste category */}
                                    <div className='col-md-5'>
                                        <div className='card'>
                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>   List of <code>Waste Category</code></div>
                                            <div data-toggle="modal" data-target="#staticBackdrop23" style={{ cursor: 'pointer' }} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Waste Type</div>

                                        </div>
                                        <div id="w0" className="grid-view">

                                            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable2">
                                                {detailbankBranchWasteCategory.length > 0 ?
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Cateory Image</th>
                                                                <th>Category Name</th>
                                                                <th className="action-column">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailbankBranchWasteCategory && detailbankBranchWasteCategory.map((e) => {
                                                                console.log(e);
                                                                count5++;
                                                                return <tr key={e.id}>
                                                                    <td>{count5}</td>
                                                                    <td><img src={url + e.category_image} style={{ width: '80px', height: '30px' }} /></td>
                                                                    <td>{e.category_name}</td>
                                                                    <td >

                                                                        <button className='btn btn-danger btn-sm' onClick={() => {
                                                                            console.log(e.id);
                                                                            DeleteCategory("bankBranch/remove_waste_type", e.waste_accepted_id)
                                                                        }}><i className='ph-trash'></i></button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </> : <thead style={{ fontSize: '15px', fontWeight: '600', margin: '0px 10px', textAlign: 'center' }}>There's no Category in this branch</thead>}
                                            </table>
                                        </div>
                                       </div>
                                    </div>
                                    {/* working area */}
                                    <div className='col-md-6'>
                                        <div className='card'>
                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>   List of <code>Working Areas</code></div>
                                            <div data-toggle="modal" data-target="#staticBackdrop24" style={{ cursor: 'pointer' }} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Working Areas</div>

                                        </div>
                                        <div id="w0" className="grid-view">

                                            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable3">
                                                {detailbankBranchWorkingAreas.length > 0 ?
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Area</th>
                                                                <th>City</th>
                                                                <th>District</th>
                                                                <th>State</th>
                                                                <th className="action-column">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailbankBranchWorkingAreas && detailbankBranchWorkingAreas.map((e) => {
                                                                console.log(e.id);
                                                                count3++;
                                                                return <tr key={e.id}>
                                                                    <td>{count3}</td>
                                                                    <td>{e.area}</td>
                                                                    <td>{e.city}</td>
                                                                    <td>{e.district}</td>
                                                                    <td>{e.state}</td>
                                                                    <td >

                                                                        <button className='btn btn-danger btn-sm' onClick={() => {
                                                                            console.log(e.working_area_id);
                                                                            DeleteWorkingArea("bankBranch/remove_working_area", e.working_area_id)
                                                                        }}><i className='ph-trash'></i></button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </> : <thead style={{ fontSize: '15px', fontWeight: '600', margin: '0px 10px', textAlign: 'center' }}>There's no Working Area in this branch</thead>}
                                            </table>
                                        </div>
                                      </div>
                                    </div>
                                    {/* waste process */}
                                    <div className='col-md-6'>
                                        <div className='card'>
                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>   List of <code>Waste Processes</code></div>
                                            <div data-toggle="modal" data-target="#staticBackdrop25" style={{ cursor: 'pointer' }} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Waste Process</div>

                                        </div>
                                        <div id="w0" className="grid-view">
                                            {detailbankBranchWasteProcess.length > 0 ?
                                                <>
                                                    <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable4">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Process Name</th>
                                                                <th>Solution Name</th>
                                                                <th className="action-column">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailbankBranchWasteProcess && detailbankBranchWasteProcess.map((e) => {
                                                                console.log(e);
                                                                count4++;
                                                                return <tr key={e.id}>
                                                                    <td>{count4}</td>
                                                                    <td>{e.process_name}</td>
                                                                    <td>{e.machine_name}</td>
                                                                    <td >

                                                                        <button className='btn btn-danger btn-sm' onClick={() => {
                                                                            console.log(e.id);
                                                                            DeleteProcess("bankBranch/remove_waste_processes", e.branch_process_id)
                                                                        }}><i className='ph-trash'></i></button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </> : <thead style={{ fontSize: '15px', fontWeight: '600', margin: '0px 10px', textAlign: 'center', alignItems: 'center',display:'flex',justifyContent:'center' }}>There's no Process in this branch</thead>}
                                        </div>
                                      </div>
                                    </div>
                                </div> : ""}
                            </div>
                             



                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}
