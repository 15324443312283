import React, { useEffect, useContext } from 'react'
import Cookies from 'js-cookie';
import LOGO from '../../../Images/earth.jpg'
import AddCategory from './AddCategory';
import UpdateCAtegory from './UpdateCAtegory';
import HandleContext from '../../../Context/HandleContext'
import $ from 'jquery'


export default function WasteCategory() {
  setTimeout(() => {
    let searchid=document.getElementById('myTable1_filter')
    let id=document.getElementById('myTable1_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);
  $(document).ready(function () {
    $('#myTable1').DataTable();
  });

  let url = 'https://backend.zerowastecitizen.in/'

  const context = useContext(HandleContext);
  const { GetWasteCategory, wastecategory, PostData } = context;

  useEffect(() => {
    GetWasteCategory("category")
  }, [])
  let count = 0;

  return (
    <div>
      {/* Add Waste Category */}

      <div class="modal fade" id="staticBackdrop15" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add Category</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddCategory />
          </div>
        </div>
      </div>
      {/* Upadate Waste Category */}

      <div class="modal fade" id="staticBackdrop16" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update Category</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UpdateCAtegory />
          </div>
        </div>
      </div>
      <div className='card' style={{ marginTop: '30px' }}>
        {wastecategory ?
          <>
            <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>   All <code>Waste Category</code></div>
              <div style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#staticBackdrop15"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Category</div>
            </div>
            <div id="w0" className="grid-view">
              <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable1">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category Image</th>
                    <th>Category Name</th>
                    <th className="action-column">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {wastecategory && wastecategory.map((e) => {
                    count++;
                    return <tr>
                      <td>{count}</td>
                      <td><img src={url + e.category_image} style={{ width: '80px', height: '30px' }} /></td>
                      <td>{e.category_name}</td>
                      <td >
                        <button className='btn btn-success btn-sm' onClick={() => {
                          // console.log(e.id);
                          Cookies.set('category_id', e.id)
                        }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop16" ><i className='ph-note-pencil'></i></button>

                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </>
          : ""}
      </div>
    </div>
  )
}
