import React,{useEffect,useContext,useState} from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import Cookies from 'js-cookie'

export default function Updatecity() {
  const context = useContext(HandleContext);
  const { GetData, data, States, state, PostData,District ,City} = context;

  const [credentials, setCredentials] = useState({ name: "",pincode:"",city_id:"", district_id:"" })


  const updateCity = (e) => {
    console.log("Submitted");
    e.preventDefault();
    console.log(credentials);
    PostData("city/update_city", JSON.stringify(credentials))
    setTimeout(() => { 
      City(JSON.stringify({ district_id: Cookies.get('district_id') }))
      setCredentials({})
setCredentials  ({name: "",pincode:""}) 
 }, 200);
  }
  return (
    <form id="w0" onSubmit={updateCity}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className="form-group">
        <label>City Name</label>
        <input type="text" className="form-control" name="name" value={credentials.name} placeholder='' onChange={(e) => {
          setCredentials({ ...credentials, name: e.target.value,district_id: Cookies.get('district_id') ,city_id:Cookies.get('cityid')})
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
      <div className="form-group">
        <label>Pincode</label>
        <input type="text" className="form-control" value={credentials.pincode} name="pincode" placeholder='' onChange={(e) => {
          setCredentials({ ...credentials, pincode: e.target.value })
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
  )
}
