// import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { writeFile } from 'xlsx';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import HandleContext from '../Context/HandleContext';
import ExcelJS from 'exceljs';

import Cookies from 'js-cookie';

function AddSignature() {
    const [credentials, setCredentials] = useState({ branch_id: Cookies.get('branch'), position: "", signature_name: "", signature_label: "", file: "" })

    const [position, setPosition] = useState({ branch_id: Cookies.get('branch'), position: "" })
    const context = useContext(HandleContext);

    const { GetAllBankBranch, bankBranch, PostData, userExcel, BranchUserExcel, AddSignature, AddExcel } = context;

    console.log(userExcel);

    const handleUpload = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        const { position, signature_label, signature_name, file } = credentials
        console.log(credentials);
        formData.append("branch_id", Cookies.get('branch'))
        formData.append("position", position)
        formData.append("signature_label", signature_label)
        formData.append("signature_name", signature_name)
        formData.append("file", file)
        console.log(credentials.file);
        console.log(formData);
        AddSignature(formData)
    };

    const addZwcSignature = (e) => {
        e.preventDefault();
        console.log(position);
        PostData("bankBranch/add_zwc_signature", JSON.stringify(position))
    }

    return (
        // In your render method:
        <div>
            <div>
                <Navbar />
                <div className='page-content'>
                    <Sidebar />
                    <div class="page-content">
                        <div class="content-wrapper">
                            <div class="page-header page-header-light">
                                <div class="page-header-content header-elements-md-inline">
                                    <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                                        <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">Add Signature</span></h4>

                                        <div class="header-elements ">

                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px" }} />

                                </div>

                                <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                                    <div class="d-flex">
                                        <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Add Signature</span></ul>

                                        <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='content'>

                                <div className='card' >
                                    <>
                                        <div className="card-body" >
                                            <form onSubmit={handleUpload}>
                                                <div className='row'>
                                                <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label className='label'> Signature Holder Name</label>
                                                            <input type="text" className="form-control" name="signature_label" value={credentials.signature_name} placeholder='' onChange={(e) => {
                                                                setCredentials({ ...credentials, signature_name: e.target.value })
                                                            }} required />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label className='label'> Signature Label</label>
                                                            <input type="text" className="form-control" name="signature_label" value={credentials.signature_label} placeholder='' onChange={(e) => {
                                                                setCredentials({ ...credentials, signature_label: e.target.value })
                                                            }} required />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label className='label'>Select Position</label>
                                                            <select id="usercities-state" class="form-control select-search" name="position" value={credentials.position} onChange={(e) => {
                                                                setCredentials({ ...credentials, position: e.target.value })
                                                            }} required >
                                                                <option>-- Select Position --</option>
                                                                <option value='1'>1</option>
                                                                <option value='2'>2</option>
                                                                <option value='3'>3</option>
                                                                <option value='4'>4</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                  
                                                    
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label className='label'>Select File</label>
                                                            <input type="file" class="form-control-file" id="exampleFormControlFile1" name='file' onChange={(e) => {
                                                                setCredentials({ ...credentials, file: e.target.files[0] })
                                                            }} required />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <button type='submit' className='btn btn-success' >Add</button>
                                                    </div>

                                                </div>
                                            </form>

                                        </div>

                                    </>
                                </div>
                                <div className='card'>
                                    <div className='card-body'>

                                        <form onSubmit={addZwcSignature}>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className='label'>Add Position For ZWC Signature</label>
                                                    <select id="usercities-state" class="form-control select-search" name="position" value={position.position} onChange={(e) => {
                                                        setPosition({ ...position,branch_id:Cookies.get('branch'), position: e.target.value })
                                                    }} required >
                                                        <option>-- Select Position --</option>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
                                                        <option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <button type='submit' className='btn btn-success' >Add</button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSignature;