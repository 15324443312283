import React, { useState, useContext, useEffect } from 'react'
import HandleContext from '../../../Context/HandleContext'
import Cookies from 'js-cookie';

export default function UpdateUser() {

    const context = useContext(HandleContext);
    const {GetUser, GetUserRole, data, PostData, States, state, District, district, City, city, Area, area } = context;
  
    const [credentials, setCredentials] = useState({id:Cookies.get('user_id'), name: "", email: "", phone_num: "", area_id: "", role_id: "", password: "", confirm_password: "", address: "" })

    useEffect(() => {
      States()
      GetUserRole("userRole")
    }, [])
  
    const handleOnchange = (e) => {
      setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
  
    const updateUser = (e) => {
      console.log("Submitted");
      e.preventDefault();
      console.log(credentials);
      PostData("userDetail/update_user", JSON.stringify(credentials))
      setTimeout(() => {
        GetUser("userDetail")
      }, 200);
    }
  
  return (
    <form id="w0" onSubmit={updateUser}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className='row'>
        <div className="form-group col-md-12">
          <label>User Name</label>
          <input type="text" className="form-control" name="name" onChange={handleOnchange} placeholder='' required />
        </div>
        <div className="form-group col-md-6">
          <label>Phone Number</label>
          <input type="text" className="form-control" name="phone_num" onChange={handleOnchange} required />
        </div>
        <div className="form-group col-md-6">
          <label className=''>Select User Role</label>
          <select id="usercities-state" class="form-control select-search" name="role_id" onChange={(e) => {
            setCredentials({ ...credentials, role_id: e.target.value })
          }} required >
            <option>-- Select User Role --</option>
            {data && data.map((e) => {
              // console.log(e);
              return <option value={e.id}>{e.role_name}</option>
            })}
          </select>

        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label className=''>Select State</label>
            <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
              Cookies.set('state_id', e.target.value)
              District(JSON.stringify({ state_id: Cookies.get('state_id') }))
            }} required >
              <option>-- Select State --</option>
              {state && state.map((e) => {
                // console.log(e);
                return <option value={e.id}>{e.name}</option>
              })}
            </select>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <label className=''>Select District</label>

            </div>
            <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
              Cookies.set('district_id', e.target.value)
              City(JSON.stringify({ district_id: Cookies.get('district_id') }))
            }} required >
              <option>-- Select District --</option>
              {district && district.map((e) => {
                console.log(e);
                return <option value={e.id}>{e.name}</option>
              })}
            </select>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <label className=''>Select City</label>
            </div>              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
              Cookies.set('city_id', e.target.value)
              Area(JSON.stringify({ city_id: Cookies.get('city_id') }))

            }} required >
              <option>-- Select City --</option>
              {city && city.map((e) => {
                console.log(e);
                return <option value={e.id}>{e.name}</option>
              })}
            </select>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <label className=''>Select Area</label>
            </div>
            <select id="usercities-state" class="form-control select-search" name="area_id" onChange={(e) => {
            setCredentials({ ...credentials, area_id: e.target.value })
          }} required >
              <option>-- Select Area --</option>
              {area && area.map((e) => {
                console.log(e);
                return <option value={e.id}>{e.name}</option>
              })}
            </select>
          </div>
        </div>
        <div className="form-group col-md-12">
          <label>Address</label>
          <input type="text" className="form-control"  name="address" onChange={handleOnchange} required />
        </div>
        
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
  )
}
