import React, { useEffect, useContext } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Content from './Content'
import { Link } from 'react-router-dom'
import HandleContext from '../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import State from './State/State'
import City from './City/City'
import District from './District/District'
import Area from './Area/Area'
import Navs from './Navs'
import './Style.css'

export default function AreaMaster() {
  return (
    <div>
      <Navbar />
      <div className='page-content'>
        <Sidebar />
        <div class="page-content">
          <div class="content-wrapper">
            <div class="page-header page-header-light">
              <div class="page-header-content header-elements-md-inline">
                <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                  <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">Area Master</span></h4>

                  <div class="header-elements ">
                    {/* <div class="d-flex justify-content-center">
                <Link class="btn btn-link btn-float text-default" to="/addarea"><i class="ph-plus-circle text-primary"></i>
                <div style={{color:'black',fontWeight:'500'}}>Add Area</div></Link>    
                            </div> */}
                  </div>
                </div>
                <hr style={{ margin: "0px" }} />

              </div>

              <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                <div class="d-flex">
                  <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Area Master</span></ul>

                  <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                </div>
              </div>
            </div>
            <div className='content'>
              <nav style={{borderRadius:'0px'}}>
                <div class="nav nav-tabs" id="nav-tab" role="tablist" style={{color:'black'}}>
                  <button class="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">State</button>
                  <button class="nav-link" id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">District</button>
                  <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">City</button>
                  <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#nav-area" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Area</button>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><State/></div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><District/></div>
                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab"><City/></div>
                <div class="tab-pane fade" id="nav-area" role="tabpanel" aria-labelledby="nav-contact-tab"><Area/></div>
              </div>
              {/* <Navs/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
