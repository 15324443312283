import React, { useEffect, useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link, useNavigate } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import Cookies from 'js-cookie'
import AddCity from './Addcity'
import Updatecity from './Updatecity'
import AddDistrict from '../District/AddDistrict'
import $ from 'jquery'

export default function City() {

  $(document).ready(function () {
    $('#myTable').DataTable();
  });

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const { GetData, data, States, state, District, district, City, city, PostData } = context;

  useEffect(() => {
    States(JSON.stringify({ username: '' }))
  }, [])

  // console.log(data);
  // console.log(data && data.name);

  let count = 0;

  const Delete = (id) => {
    PostData('/city/delete_city', JSON.stringify({ city_id: id }))
    setTimeout(() => {
      City(JSON.stringify({ district_id: Cookies.get('district_id') }))
    }, 200);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitted");
    City(JSON.stringify({ district_id: Cookies.get('district_id') }))
  }

  return (
    <>
      {/*Add District Modal */}
      <div class="modal fade" id="staticBackdrop9" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add District</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddDistrict />
          </div>
        </div>
      </div>

      {/*Add City Modal */}
      <div class="modal fade" id="staticBackdrop3" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add City</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddCity />
          </div>
        </div>
      </div>

      {/*Update City Modal */}
      <div class="modal fade" id="staticBackdrop4" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update City</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Updatecity />
          </div>
        </div>
      </div>
      <div className='card' style={{ marginTop: '30px' }}>
        <div className='card-header'>
          <form id="w0" onSubmit={handleSubmit}>
            <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
            <div className='row'>
              {/* <div className='col-md-4'>
            <div className="form-group">
              <label className='label'>Select Country</label>
              <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e)=>{
                    Cookies.set('country_id',e.target.value)
                    States( JSON.stringify({ country_id: Cookies.get('country_id') }))
              }}  required >
                <option >India</option>
                {data && data.map((e)=>{
                  console.log(e);
                  return <option value={e.id}>{e.name}</option>
                })}
              </select>
            </div>
            </div> */}
              <div className='col-md-5'>
                <div className="form-group">
                  <label className='label'>Select State</label>
                  <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                    Cookies.set('state_id', e.target.value)
                    District(JSON.stringify({ state_id: Cookies.get('state_id') }))
                  }} required >
                    <option>-- Select State --</option>
                    {state && state.map((e) => {
                      // console.log(e);
                      return <option value={e.id}>{e.name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-5'>
                <div className="form-group">
                  <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <label className='label'>Select District</label>
                    <i style={{ color: 'green', cursor: 'pointer' }} className='ph-plus-circle' data-toggle="modal" data-target="#staticBackdrop9"></i>
                  </div>
                  <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                    Cookies.set('district_id', e.target.value)
                  }} required >
                    <option>-- Select District --</option>
                    {district && district.map((e) => {
                      // console.log(e);
                      return <option value={e.id}>{e.name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-2' style={{ marginTop: "22px" }}>

                <button type='submit' className="btn btn-success">Submit</button>
              </div>

            </div>
          </form>
        </div>
        {/* </div> */}

        {/* Table with togglable columns */}
        {city ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>   All list of <code>Cities</code></div>
            <div style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#staticBackdrop3"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add City</div>
          </div>
          <div id="w0" className="grid-view">
            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>City</th>
                  <th className="action-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {city && city.map((e) => {
                  // console.log(e);
                  count++;
                  return <tr key={e.id}>
                    <td>{count}</td>
                    <td>{e.name}</td>
                    <td >
                      <button className='btn btn-success btn-sm' onClick={() => {
                        Cookies.set('cityid', e.id)
                        console.log(e.id);
                      }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop4" ><i className='ph-note-pencil'></i></button>
                      <button className='btn btn-danger btn-sm' onClick={() => {
                        console.log(e.id);
                        Delete(e.id)
                      }}><i className='ph-trash'></i></button>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
      {/* /table with togglable columns */}



    </>
  )
}
