import React, { useEffect, useContext, useState } from "react";
import Sidebar from "./../../Sidebar";
import Navbar from "../.././Navbar";
// import Content from '../Content'
import { Link } from "react-router-dom";
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import DataTables from "datatables.net-dt";

export default function TransferDetails() {

    const url = 'https://api.zerowastecitizen.in/';

    const context = useContext(HandleContext);
    const { GetSpecificUser, specificuser } = context;

    useEffect(() => {
        GetSpecificUser("transaction/get_transfer", JSON.stringify({ id: Cookies.get('transfer_id') }))
    }, [])

    console.log(specificuser);
    let count = 0;
    let count1 = 0;
    return (
        <div>
            <div>
                <Navbar />
                <div className="page-content">
                    <Sidebar />
                    <div class="page-content">
                        <div class="content-wrapper">
                            <div class="page-header page-header-light">
                                <div class="page-header-content header-elements-md-inline">
                                    <div
                                        class="page-title d-flex"
                                        style={{ justifyContent: "space-between" }}
                                    >
                                        <h4>
                                            {" "}
                                            <span
                                                style={{ fontSize: "18px" }}
                                                class="font-weight-semibold"
                                            >
                                                Transfer

                                            </span>
                                        </h4>

                                        <div class="header-elements "></div>
                                    </div>
                                    <hr style={{ margin: "0px" }} />
                                </div>

                                <div
                                    class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
                                    style={{
                                        paddingLeft: "14px",
                                        marginBottom: "6px",
                                        paddingRight: "20px",
                                    }}
                                >
                                    <div class="d-flex">
                                        <ul class="breadcrumb">
                                            <Link to="/">Home</Link>
                                            <Link to="/" class="breadcrumb-item"> <i
                                                style={{ marginRight: "6px" }}
                                                class="ph-house-line mr-4" ></i>
                                                Home
                                            </Link>
                                            <span class="breadcrumb-item active">Deposit</span>
                                        </ul>

                                        <a
                                            href="#"
                                            class="header-elements-toggle text-default d-md-none"
                                        >
                                            <i className="icon-more"></i>
                                        </a>
                                        <div style={{flexGrow:'1'}}></div>
                                        <Link to='/uploadtransfer' style={{cursor:'pointer',color:'black'}}>
                                        <i
												style={{ color: "green", marginRight: "5px" }}
												className="ph-plus-circle"
											></i>
											<span>Upload Document</span>    
                                                     </Link>            
                                                                </div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">

                                                <div className='heads'>
                                                    <span className='label'>Transaction Id</span>
                                                    <span className='value'>{specificuser && specificuser.transaction_id}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Transaction Date</span>
                                                    <span className='value'>{specificuser && specificuser.tdate}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <div className="col-md-6">
                                        <div className="card"> <div className="card-body">
                                            <div className='heads'>
                                                <span className='label'>From Branch</span>
                                                <span className='value'>{specificuser && specificuser.from_branch}</span>
                                            </div>
                                            <div className='heads'>
                                                <span className='label'>To Branch</span>
                                                <span className='value'>{specificuser && specificuser.to_branch}</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>Products</h4>

                                                <table
                                                    className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
                                                    id="myTable"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Product Name</th>
                                                            <th>Quantity</th>
                                                            <th>Rate</th>
                                                            <th>Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {specificuser && specificuser.products.map((e) => {
                                                            console.log(e.product_name);
                                                            count++
                                                            return <>
                                                                <tr key={e.id}>
                                                                    <td>{count}</td>
                                                                    <td>{e.product_name}</td>
                                                                    <td>{e.quom} {e.uom}</td>
                                                                    <td>{e.rate}</td>
                                                                    <td>{e.total_amount}</td>
                                                                </tr>

                                                            </>
                                                        })}

                                                    </tbody>
                                                </table>





                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>Documents</h4>
                                                <table
                                                    className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
                                                    id="myTable"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Document Type</th>
                                                            <th>Document No.</th>
                                                            <th>Document Path</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {specificuser && specificuser.documents.map((e) => {
                                                            count1++
                                                            return <>
                                                                <tr key={e.id}>
                                                                    <td>{count1}</td>
                                                                    <td>{e.document_type}</td>
                                                                    <td>{e.document_no}</td>
                                                                    <td><a href={url + e.document_path}><button className="btn btn-success"><i className="ph-eye"></i></button></a></td>
                                                                </tr>

                                                            </>
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
