import React, { useState, useContext, useEffect } from 'react'
import HandleContext from '../../Context/HandleContext'
import Cookies from 'js-cookie';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { Link,useNavigate } from 'react-router-dom';

export default function AddCitizens() {
  let navigate=useNavigate()

  const context = useContext(HandleContext);
  const {GetUser, GetUserRole, data, PostData, States,GetAllBankBranch,bankBranch, area } = context;

  const [credentials, setCredentials] = useState({ name: "", email: "", phone_num: "", password: "", confirm_password: "", branch_id:"" ,address:""})

  useEffect(() => {
    States()
    GetUserRole("userRole")
    GetAllBankBranch("bankBranch")

  }, [])

  const handleOnchange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  const addUser = (e) => {
    console.log("Submitted");
    e.preventDefault();
    console.log(credentials);
    PostData("userDetail/add_citizen", JSON.stringify(credentials))
    setTimeout(() => {
      GetUser("userDetail")
      setCredentials({ name: "", email: "", phone_num: "",  password: "", confirm_password: "", address: "",branch_id:"" })
    }, 200);
  }

  return (
    <div>
    <div>
 <Navbar />
 <div className='page-content'>
   <Sidebar />
   <div class="page-content">
     <div class="content-wrapper">
       <div class="page-header page-header-light">
         <div class="page-header-content header-elements-md-inline">
           <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
             <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">Add Citizens</span></h4>

             <div class="header-elements ">
               {/* <div class="d-flex justify-content-center">
           <Link class="btn btn-link btn-float text-default" to="/importcitizens"><i class="ph-plus-circle text-primary"></i>
           <div style={{color:'black',fontWeight:'500'}}>Import Citizens</div></Link>    
                       </div> */}
             </div>
           </div>
           <hr style={{ margin: "0px" }} />

         </div>

         <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
           <div class="d-flex">
             <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Add Citizens</span></ul>

             <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
             <div style={{ flexGrow: 1 }}></div>
										<div
											style={{ cursor: "pointer" , marginRight: "15px"}}
											onClick={() => {
												navigate("/excel");
											}}
										>
											<i
												style={{ color: "green" }}
												className="ph-plus-circle"
											></i>
											<span>Import Citizens</span>
										</div>
           </div>
         </div>
       </div>
       <div className='content'>
       <div className='card'>
    
       <div className='card-body'>
       <form id="w0" onSubmit={addUser}>
      {/* <div className="modal-body"> */}
        <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
        <div className='row'>
          <div className="form-group col-md-4">
            <label>User Name</label>
            <input type="text" className="form-control" name="name" value={credentials.name} onChange={handleOnchange} placeholder='' required />
          </div>
          <div className="form-group col-md-4">
            <label>Email</label>
            <input type="email" className="form-control" name="email" value={credentials.email} onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Phone Number</label>
            <input type="text" className="form-control" name="phone_num" value={credentials.phone_num} onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Password</label>
            <input type="password" className="form-control" name="password" value={credentials.password} onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Confirm Password</label>
            <input type="password" className="form-control" name="confirm_password" value={credentials.confirm_password} onChange={handleOnchange} required />
          </div>
          <div className="form-group col-md-4">
            <label>Select Branch</label>
            <select id="usercities-state" class="form-control select-search" value={credentials.branch_id} name="country_id" onChange={(e) => {
setCredentials({...credentials,branch_id:e.target.value})                
              }} required >
                <option>-- Select Branch --</option>
                {bankBranch &&
									bankBranch.map((e, index) => {
										
										// Cookies.set("branch_name", e.branch_name);
										return (
											<option key={index} value={e.id} >
												{e.branch_name}
											</option>
										);
									})}
              </select>
          </div>
         
        
         
          <div className="form-group col-md-6">
            <label>Address</label>
            <input type="text" className="form-control"  name="address" value={credentials.address} onChange={handleOnchange} required />
          </div>
         
        </div>
      {/* </div> */}
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
        <button type="submit" className="btn btn-primary">Add</button>
      {/* </div> */}
    </form>
       </div>
       </div>
       </div>
     </div>
   </div>
 </div>
</div>
</div>
  )
}
