import React, { useEffect, useContext } from 'react'
import HandleContext from '../../../Context/HandleContext'
import Cookies from 'js-cookie'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom'
import AddUser from './AddUser'
import UpdateUser from './UpdateUser'
import DataTables from 'datatables.net-dt'

export default function AllUser() {
setTimeout(() => {
  let searchid=document.getElementById('myTable_filter')
  let id=document.getElementById('myTable_length');
  id.style.padding='10px'
  searchid.style.padding='10px'
}, 2000);

  let navigate = useNavigate()

  $(document).ready(function () {
    $('#myTable').DataTable();
  });

  const handleButtonClick = async (id) => {
    // Set the cookie value
    await Cookies.set('specificuser_id', id);
  
    // Navigate to a new page
    navigate('/specificuser');
  };

  const context = useContext(HandleContext);
  const { GetUser, user, States, city, PostData } = context;

  useEffect(() => {
    GetUser("userDetail")
  }, [])
  
  console.log(user);

  let count = 0;
  return (
    <>
      {/* Update User Modal */}
      <div class="modal fade" id="staticBackdrop20" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update User </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UpdateUser />
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      <div class="modal fade" id="staticBackdrop32" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add User </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddUser />
          </div>
        </div>
      </div>

      <div className='card' style={{ marginTop: '30px' }}>
        {/* Table with togglable columns */}
        {user ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div >   All list of <code>Users</code></div>
            <div style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#staticBackdrop32"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add User</div>
          </div>
          <div id="w0" className="grid-view">
            <table id='myTable' className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" >
              <thead>
                <tr>
                  <th>#</th>
                  <th> Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>District</th>
                  <th>City</th>
                  <th className="action-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {user && user.map((e) => {
                  console.log(e);
                  count++;
                  return <tr key={e.id} onClick={() => { handleButtonClick(e.id) }} >
                    <td >{count}</td>
                    <td >{e.name}</td>
                    <td >{e.email}</td>
                    <td >{e.phone_num}</td>
                    <td >{e.address}</td>
                    <td >{e.state}</td>
                    <td >{e.district}</td>
                    <td >{e.city}</td>
                    <td >
                      <button className='btn btn-success btn-sm' onClick={() => {
handleButtonClick(e.id)                        // console.log(e.id);
                      }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop20" ><i className='ph-note-pencil'></i></button>
                      {/* <button className='btn btn-danger btn-sm' onClick={() => {
                    console.log(e.id);
                  }}><i className='ph-trash'></i></button> */}
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
    </>
  )
}
