import React, { useEffect, useState, useContext } from "react";
// import Chart from "react-google-charts";
import axios from "axios";
import zingchart from "zingchart";
import "./Style.css";
import HandleContext from "../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js";
import WasteCollectionChart from "./WasteCollectionChart";
import EnvSavedGraph from "./EnvSavedGraph";
import ReactApexChart from "react-apexcharts";

export default function Content() {
	let navigate = useNavigate();
	const context = useContext(HandleContext);
	const { Dashboard, dashboard, drilldown, barchart } = context;

	function handleDataIdClick(id) {
		console.log(id);
	}

	setTimeout(() => {
		status = true;
	}, 1000);

	let status = false;

	useEffect(() => {
		console.log(Cookies.get("branch"));
		Cookies.get("branch");
		Dashboard(
			"dashboard",
			JSON.stringify({
				branch: Cookies.get("branch"),
				fromdate: Cookies.get("fromdate"),
				todate: Cookies.get("todate"),
				gap:Cookies.get('gap')
			})
		);
	}, []);

	let chartConfig = {
		type: "bar",
		title: {
			text: dashboard && dashboard.waste_collection_graph.first.title,
		},
		plot: {
			tooltip: {
				visible: true,
			},

			valueBox: {
				text: "%t",
				color: "#FFF",
				fontSize: "14px",
				fontWeight: "none",
			},
			animation: {
				attributes: {},
				delay: 0,
				effect: "ANIMATION_EXPAND_VERTICAL",
				method: "ANIMATION_BACK_EASE_OUT",
				// sequence: 'ANIMATION_BY_PLOT_AND_NODE',
				speed: "ANIMATION_FAST",
			},
			cursor: "hand",
			detach: false,
			shadow: true,
		},
		series: dashboard && [
			dashboard.waste_collection_graph.first && {
				text: "ZWC Accounts",
				values:
					barchart &&
					barchart.value.map((e) => {
						return e;
					}),
				backgroundColor: "#367640",
				"data-id":
					barchart &&
					barchart.id.map((e) => {
						return e;
					}),
			},
		],
		scaleX: {
			values: dashboard && dashboard.waste_collection_graph.first.label,
			item: {
				color: "#555",
				fontSize: "12px",
				maxChars: 9,
			},
			label: {
				text: "Type",
				color: "#555",
				fontSize: "16px",
				fontWeight: "none",
			},
			lineColor: "#555",
			tick: {
				lineColor: "#555",
			},
		},
	};

	let chart_config = {
		series: [
			{
				name: dashboard && dashboard.waste_collection_graph.first.title,
				data: barchart && barchart.value,
			},
		],
		options: {
			chart: {
				height: 400,
				type: "bar",
			},
			plotOptions: {
				bar: {
					dataLabels: {
						orientation: "vertical",
						position: "top", // bottom/center/top
					},
				},
			},
			dataLabels: {
				orientation: "vertical",
				enabled: true,
				formatter: function (val) {
					if (val === 0) {
						return "";
					}
					return val;
				},
				offsetY: -20,
				position: "top",
				style: {
					fontSize: "12px",
					colors: ["#304758"],
				},
			},
			xaxis: {
				categories: dashboard && dashboard.waste_collection_graph.first.label,
				position: "bottom",
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			crosshairs: {
				fill: {
					type: "gradient",
					gradient: {
						colorFrom: "#D8E3F0",
						colorTo: "#BED1E6",
						stops: [0, 100],
						opacityFrom: 0.4,
						opacityTo: 0.5,
					},
				},
			},
			tooltip: {
				enabled: true,
			},
			title: {
				text: dashboard && dashboard.waste_collection_graph.first.title,
				align: "center",
			},
		},
		yaxis: {
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (val) {
					return val + "%";
				},
			},
		},
	};

	// zingchart.render({
	// 	id: "myChart",
	// 	data: chartConfig,
	// 	height: "400px",
	// 	width: "100%",
	// });

	/**
	 *  Secondary Charts
	 */
	let drilldownConfig = {
		type: "bar",
		title: {
			text: "Security Tools",
		},
		plot: {
			tooltip: {
				text: "Quantity: %v",
				borderWidth: "0px",
				fontSize: "18px",
				shadow: true,
				shadowAlpha: 0.5,
				shadowBlur: 2,
				shadowColor: "#c4c4c4",
				shadowDistance: 3,
			},
			animation: {
				delay: 10,
				effect: "ANIMATION_EXPAND_BOTTOM",
				method: "ANIMATION_BACK_EASE_OUT",
				sequence: "ANIMATION_BY_PLOT_AND_NODE",
				speed: "10",
			},
		},
		plotarea: {
			margin: "dynamic",
		},
		scaleX: {
			// values: drilldown && drilldown[Cookies.get('data_id')].value,
			item: {
				color: "#555",
				fontSize: "12px",
				maxChars: 9,
			},
			label: {
				text: "Type",
				color: "#555",
				fontSize: "16px",
				fontWeight: "none",
			},
			lineColor: "#555",
			tick: {
				lineColor: "#555",
			},
		},
		scaleY: {
			guide: {
				visible: false,
			},
			item: {
				color: "#555",
				fontSize: "12px",
			},
			label: {
				text: "Quantity",
				color: "#555",
				fontSize: "16px",
				fontWeight: "none",
			},
			lineColor: "#555",
			tick: {
				lineColor: "#555",
			},
		},
		shapes: [
			{
				type: "triangle",
				id: "backwards",
				padding: "5px",
				angle: -90,
				backgroundColor: "#C4C4C4",
				cursor: "hand",
				size: "10px",
				x: "20px",
				y: "20px",
			},
		],
		series: [
			{
				// values: drilldown && drilldown[Cookies.get('data_id')].data,
				styles: ["#1565C0", "#42A5F5", "#1E88E5", "#90CAF9"],
			},
		],
	};

	/**
	 * Manage drilldown config
	 */ let drilldownDataStructure = {
		// [Cookies.get('data_id')]: {
		//   colors: ['#5E35B1', '#4527A0', '#7E57C2'],
		//   data:drilldown[Cookies.get('data_id')].data && drilldown[Cookies.get('data_id')].data,
		//   scaleLabels: drilldown[Cookies.get('data_id')].dataldown && drilldown[Cookies.get('data_id')].scaleLabels,
		//   title: drilldown[Cookies.get('data_id')].data && drilldown[Cookies.get('data_id')].title,
		// },
		//
		// dt: {
		//   colors: ['#26C6DA', '#80DEEA', '#00838F', '#00ACC1'],
		//   data: [20, 8, 35, 20],
		//   scaleLabels: ['IDE', 'File-Management', 'Image-Generation', 'QA-testing'],
		//   title: 'Dev Tools',
		// },
		// sp: {
		//   colors: ['#26A69A', '#80CBC4', '#00695C', '#00897B'],
		//   data: [15, 5, 35, 20],
		//   scaleLabels: [
		//     'Speed-test',
		//     'Error-tracking',
		//     'Load-testing',
		//     'User-monitoring',
		//   ],
		//   title: 'Site Performance',
		// },
		// st: {
		//   colors: ['#1565C0', '#42A5F5', '#1E88E5', '#90CAF9'],
		//   data: [35, 15, 25, 10],
		//   scaleLabels: ['Firewall', 'Cache-control', 'Link-access', 'HTTP-Comp'],
		//   title: 'Security Tools',
		// },
		// vt: {
		//   colors: ['#EF5350', '#E53935', '#C62828'],
		//   data: [10, 25, 35],
		//   scaleLabels: ['Grid-component', 'Map-tool', 'Web-charting'],
		//   title: 'Visualization Tools',
		// },
	};

	/*
	 * Built in zingchart API event used to capture node click events.
	 * Starting from this scope you will handle drilldown functionality.
	 */
	zingchart.node_click = function (p) {
		console.log(p["data-id"]);
		Cookies.set("data_id", p["data-id"]);
		// You could use this data to help construct drilldown graphs check it out...
		if (drilldownDataStructure[p["data-id"]]) {
			drilldownConfig["title"]["text"] =
				drilldownDataStructure[p["data-id"]]["title"];
			drilldownConfig["scaleX"]["values"] =
				drilldownDataStructure[p["data-id"]]["scaleLabels"];
			drilldownConfig["series"][0]["values"] =
				drilldownDataStructure[p["data-id"]]["data"];
			drilldownConfig["series"][0]["styles"] =
				drilldownDataStructure[p["data-id"]]["colors"];
			zingchart.exec("myChart", "destroy");
			zingchart.render({
				id: "drilldown1",
				data: drilldownConfig,
				height: "450px",
				width: "100%",
			});
		}
	};

	/*
	 * Handle history buttons. You can assign
	 * shapes id's and based on id you can go
	 * 'forward' or 'backwards'. You could
	 * also handle this with HTML and register
	 * click events to those DOM elements.
	 */
	zingchart.shape_click = function (p) {
		let shapeId = p.shapeid;

		switch (shapeId) {
			case "forwards":
			case "backwards":
			case "default":
				zingchart.exec("drilldown1", "destroy");
				zingchart.render({
					id: "myChart",
					data: chartConfig,
					height: "450px",
					width: "100%",
				});
				break;
		}
	};

	return (
		<div className="page-content">
			<div className="content-wrapper">
				<div className="content-inner">
					{Cookies.get("user_role_id") == "1" ? (
						<div className="row" style={{ margin: "10px 10px" }}>
							<div className="col-lg-2">
								{/* Members online */}
								<div className="card bg-teal-400 card-dash-1">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
							Cookies.get('branch')!=='0'?navigate("/citizens"):navigate('/allcitizens')
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> ZWC Accounts</h2>
												<div className="initials">
													{dashboard && dashboard.citizens}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold mb-0 card-icon">
											<i className="fa fa-user-plus" />
										</h3>
									</div>
								</div>
								{/* /members online */}
							</div>
							<div className="col-lg-2">
								{/* Current server load */}
								<div className="card bg-teal-400 card-dash-1 card-purple">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/collectors");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> Collectors</h2>
												<div className="initials">
													{" "}
													{dashboard && dashboard.collectors}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold card-icon mb-0">
											<i className="fa fa-user-secret" />
										</h3>
									</div>
								</div>
								{/* /current server load */}
							</div>
							<div className="col-lg-2">
								{/* Traffic sources */}
								<div className="card bg-teal-400 card-dash-1 card-pink">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/aggregators");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> Aggregators</h2>
												<div className="initials">
													{dashboard && dashboard.aggregators}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold card-icon mb-0">
											<i className="fa fa-user-secret" />
										</h3>
									</div>
								</div>
								{/* /traffic sources */}
							</div>
							<div className="col-lg-2">
								<div className="card bg-teal-400 card-dash-1">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/recyclers");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> Recyclers</h2>
												<div className="initials">
													{" "}
													{dashboard && dashboard.recyclers}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>{" "}
										<h3 className="font-weight-semibold mb-0 card-icon">
											<i className="fa fa-user" />
										</h3>
									</div>
								</div>
							</div>
							<div className="col-lg-2">
								{/* Current server load */}
								<div className="card bg-teal-400 card-dash-1 card-purple">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/brands");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> Brands</h2>
												<div className="initials">
													{" "}
													{dashboard && dashboard.brands}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold card-icon mb-0">
											<i className="fa fa-user-secret" />
										</h3>
									</div>
								</div>
								{/* /current server load */}
							</div>
							<div className="col-xl-2">
								{/* Traffic sources */}
								<div className="card bg-teal-400 card-dash-1 card-pink">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/warriors");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> Warriors</h2>
												<div className="initials">
													{" "}
													{dashboard && dashboard.warriors}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold card-icon mb-0">
											<i className="fa fa-user-secret" />
										</h3>
									</div>
								</div>
								{/* /traffic sources */}
							</div>
						</div>
					) : (
						<div className="row" style={{ margin: "10px 10px" }}>
							<div className="col-lg-6">
								{/* Members online */}
								<div className="card bg-teal-400 card-dash-1">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/citizens");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> ZWC Accounts</h2>
												<div className="initials">
													{dashboard && dashboard.citizens}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold mb-0 card-icon">
											<i className="fa fa-user-plus" />
										</h3>
									</div>
								</div>
								{/* /members online */}
							</div>
							<div className="col-xl-6">
								{/* Traffic sources */}
								<div className="card bg-teal-400 card-dash-1 card-pink">
									<div
										style={{ color: "white", cursor: "pointer" }}
										onClick={() => {
											navigate("/warriors");
										}}
									>
										<div className="card-body">
											<div className="text-right view-more"></div>
											<div>
												<h2> Warriors</h2>
												<div className="initials">
													{" "}
													{dashboard && dashboard.warriors}
												</div>
											</div>
										</div>
										<div className="container-fluid">
											<div id="members-online" />
										</div>
										<h3 className="font-weight-semibold card-icon mb-0">
											<i className="fa fa-user-secret" />
										</h3>
									</div>
								</div>
								{/* /traffic sources */}
							</div>
						</div>
					)}
					<div className="row" style={{ margin: "10px 10px" }}>
						<div className="col-md-8">
							<ReactApexChart
								options={chart_config.options}
								series={chart_config.series}
								type="bar"
								height={400}
							/>
							<div id="myChart" class="chart--container">
								<a class="zc-ref" href="https://www.zingchart.com/">
									Powered by ZingChart
								</a>
							</div>
							<div id="drilldown1" class="chart--container">
								<a class="zc-ref" href="https://www.zingchart.com/">
									Powered by ZingChart
								</a>
							</div>
						</div>
						<div className="col-md-4">
							<EnvSavedGraph />
						</div>
						<div className="col-md-8">
							<WasteCollectionChart />
						</div>
						<div className="col-md-4" style={{ marginTop: "20px" }}></div>
					</div>
				</div>
			</div>
		</div>
	);
}
