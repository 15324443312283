import React ,{useState,useContext}from 'react'
import HandleContext from '../../../Context/HandleContext'
import Cookies from 'js-cookie';

export default function Updatepassword() {
    
    const context = useContext(HandleContext);
    const {GetUserRole, PostData,GetSpecificUser} = context;
  
    const [credentials, setCredentials] = useState({ id: "",password:"",confirm_password:"" })
  
  
    const updatepassword = (e) => {
      console.log("Submitted");
      e.preventDefault();
      console.log(credentials);
      PostData("userDetail/update_password", JSON.stringify(credentials))
      setTimeout(() => { 
        GetSpecificUser("userDetail/get_user", JSON.stringify({ id: Cookies.get('user_id') }))
      }, 200);
    }
  return (
    <div>
         <form id="w0" onSubmit={updatepassword}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className="form-group">
        <label>Password</label>
        <input type="password" className="form-control" name="password" placeholder='' onChange={(e) => {
          setCredentials({ ...credentials, password: e.target.value,id:Cookies.get('user_id') })
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
      <div className="form-group">
        <label>Confirm Password</label>
        <input type="password" className="form-control" name="confirm_password" placeholder='' onChange={(e) => {
          setCredentials({ ...credentials, confirm_password: e.target.value })
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
    </div>
  )
}
