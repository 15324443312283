import React,{useEffect,useContext,useState} from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import Cookies from 'js-cookie'
import $ from 'jquery'

export default function UpdateDistrict() {
  const context = useContext(HandleContext);
  const { GetData, data, States, state, PostData,District } = context;


  const [credentials, setCredentials] = useState({name:"",state_id:"",district_id:""})

 
  const updateDistrict = (e) => {
    console.log(Cookies.get('districtid'));
    console.log(credentials.district_id);
    console.log("Submitted");
    e.preventDefault();
    console.log(credentials);
    PostData("district/update_district", JSON.stringify(credentials))
    setTimeout(() => { 
      District(JSON.stringify({ state_id: Cookies.get('state_id') }))
      // window.location.reload();
    setCredentials({name:""})
    }, 200);
  }
 
  return (
    <form id="w0" onSubmit={updateDistrict}>
    <div className="modal-body">
      <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
      <div className="form-group">
        <label>District Name</label>
        <input type="text" className="form-control" name="name" value={credentials.name} placeholder='' onChange={(e) => {
          setCredentials({ ...credentials, name: e.target.value,district_id:Cookies.get('districtid'),state_id:Cookies.get('state_id') })
        }} style={{ border: '2px solid darkseagreen' }} required />
      </div>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button id='modalbtn' type="submit" className="btn btn-primary">Save changes</button>
    </div>
  </form>
  )
}
