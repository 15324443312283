import React, { useState, useEffect } from "react";
import { useContext } from "react";
import HandleContext from "../Context/HandleContext";
import axios from "axios";
import zingchart from "zingchart";
import Cookies from "js-cookie";
import ReactApexChart from "react-apexcharts";

const WasteCollectionChart = () => {
	const context = useContext(HandleContext);
	const { Dashboard, dashboard } = context;

	useEffect(() => {
		Dashboard(
			"dashboard",
			JSON.stringify({
				branch: Cookies.get("branch"),
				fromdate: Cookies.get("fromdate"),
				todate: Cookies.get("todate"),
				gap:Cookies.get('gap')
			})
		);
	}, []);
	console.log(dashboard && dashboard);

	let chartConfig = {
		type: "bar",
		title: {
			text: "Category Collection (in Kgs)",
		},
		plot: {
			tooltip: {
				visible: true,
			},
			valueBox: {
				text: "%t",
				color: "#FFF",
				fontSize: "14px",
				fontWeight: "none",
			},
			animation: {
				attributes: {},
				delay: 10,
				effect: "ANIMATION_EXPAND_VERTICAL",
				method: "ANIMATION_BACK_EASE_OUT",
				// sequence: 'ANIMATION_BY_PLOT_AND_NODE',
				speed: "ANIMATION_FAST",
			},

			cursor: "hand",
			detach: false,
			shadow: true,
		},

		series: dashboard && [
			dashboard.category_collection_graph && {
				text: "",
				values: dashboard && dashboard.category_collection_graph.data,
				backgroundColor: "#9B5E51",
				"data-id": dashboard && dashboard.category_collection_graph.data,
			},
		],
		scaleX: {
			values: dashboard && dashboard.category_collection_graph.scaleLabels,
			item: {
				color: "#555",
				fontSize: "12px",
				maxChars: 9,
			},
			label: {
				text: "Labels",
				color: "#555",
				fontSize: "16px",
				fontWeight: "none",
			},
			lineColor: "#555",
			tick: {
				lineColor: "#555",
			},
		},
	};

	let chart_config = {
		series: [
			{
				name: "Category Collection (in Kgs)",
				data: dashboard && dashboard.category_collection_graph.data,
			},
		],
		options: {
			title: {
				text: "Category Collection (in Kgs)",
				align: "center",
				style: { fontSize: "24px" },
			},
			chart: {
				height: 400,
				type: "bar",
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: "center", // bottom/center/top
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (val) {
					if (val === 0) {
						return "";
					}
					return val;
				},
				offsetY: -20,
				position: "top",
				style: {
					fontSize: "12px",
					colors: ["#304758"],
				},
			},
			xaxis: {
				categories:
					dashboard && dashboard.category_collection_graph.scaleLabels,
				position: "bottom",
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			crosshairs: {
				fill: {
					type: "gradient",
					gradient: {
						colorFrom: "#D8E3F0",
						colorTo: "#BED1E6",
						stops: [0, 100],
						opacityFrom: 0.4,
						opacityTo: 0.5,
					},
				},
			},
			tooltip: {
				enabled: true,
			},
		},
		yaxis: {
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (val) {
					return val + "%";
				},
			},
		},
		// title: {
		// 	text: "Monthly Inflation in Argentina, 2002",
		// 	// offsetY: 330,
		// 	// align: "top",
		// 	style: {
		// 		fontSize: "20px",
		// 		color: "#000",
		// 	},
		// },
	};

	// zingchart.render({
	// 	id: "myChart1",
	// 	data: chartConfig,
	// 	height: "300px",
	// 	width: "100%",
	// });

	return (
		<>
			<ReactApexChart
				options={chart_config.options}
				series={chart_config.series}
				type="bar"
				height={400}
			/>
			<div id="myChart1" className="chart--container">
				{/* <a class="zc-ref" href="https://www.zingchart.com/">Powered by ZingChart</a> */}
			</div>
			<div id="drilldown1" className="chart--container">
				{/* <a class="zc-ref" href="https://www.zingchart.com/">Powered by ZingChart</a> */}
			</div>
		</>
	);
};

export default WasteCollectionChart;
