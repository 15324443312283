import React from 'react'
import Sidebar from '../../Sidebar'
import Navbar from '../../Navbar'
import { Link } from 'react-router-dom'
import Step1 from './Step1'
import Step2 from './Step2'

export default function AddWaste() {
    return (
        <div>
            <div>
                <Navbar />
                <div className='page-content'>
                    <Sidebar />
                    <div class="page-content">
                        <div class="content-wrapper">
                            <div class="page-header page-header-light">
                                <div class="page-header-content header-elements-md-inline">
                                    <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                                        <h4> <span class="font-weight-semibold">Waste Management</span></h4>

                                        <div class="header-elements ">

                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px" }} />

                                </div>

                                <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                                    <div class="d-flex">
                                        <ul class="breadcrumb">
                                        <Link to="/">Home</Link>
                                        <Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link>
                                        <Link to="/wastemanagement" class="breadcrumb-item"> Waste Management </Link>                                        <span class="breadcrumb-item active">Add Waste</span></ul>

                                        <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='content'>
                            <div class="row">
  <div class="col-3">
    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <button class="nav-link active" id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Add Product</button>
      <button class="nav-link" id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Enviourment Saving Formula</button>

    </div>
  </div>
  <div class="col-9">
    <div class="tab-content" id="v-pills-tabContent">
      <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"><Step1/></div>
      <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><Step2/></div>
   
    </div>
  </div>
</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
