import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
// import Content from '../Content'
import { Link } from "react-router-dom";
import HandleContext from "../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import DataTables from "datatables.net-dt";

export default function Pending() {
	const [credentials, setCredentials] = useState({
		fromdate: "",
		todate: "",
		branch: "",
        status:"0"
	});
	setTimeout(() => {
		let searchid = document.getElementById("myTable_filter");
		let id = document.getElementById("myTable_length");
		id.style.padding = "10px";
		searchid.style.padding = "10px";
	}, 2000);

	$(document).ready(function () {
		$("#myTable").DataTable();
	});

	let navigate = useNavigate();
	const context = useContext(HandleContext);
	const { Pending,pending, } = context;

	

	let count = 0;

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("submitted");
		Pending("transaction/redeem_requests", JSON.stringify(credentials));
	};



	return (
		<div>
			<div>
				<Navbar />
				<div className="page-content">
					<Sidebar />
					<div class="page-content">
						<div class="content-wrapper">
							<div class="page-header page-header-light">
								<div class="page-header-content header-elements-md-inline">
									<div
										class="page-title d-flex"
										style={{ justifyContent: "space-between" }}
									>
										<h4>
											{" "}
											<span
												style={{ fontSize: "18px" }}
												class="font-weight-semibold"
											>
												Pending Requests
											</span>
										</h4>

										<div class="header-elements "></div>
									</div>
									<hr style={{ margin: "0px" }} />
								</div>

								<div
									class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
									style={{
										paddingLeft: "14px",
										marginBottom: "6px",
										paddingRight: "20px",
									}}
								>
									<div class="d-flex">
										<ul class="breadcrumb">
											<Link to="/">Home</Link>
											<Link to="/" class="breadcrumb-item">
												<i
													style={{ marginRight: "6px" }}
													class="ph-house-line mr-4"
												></i>
												Home
											</Link>
											<span class="breadcrumb-item active">Pending Requests</span>
										</ul>

										<a
											href="#"
											class="header-elements-toggle text-default d-md-none"
										>
											<i className="icon-more"></i>
										</a>
										<div style={{ flexGrow: 1 }}></div>
										{/* <div
											style={{ cursor: "pointer" }}
											onClick={() => {
												navigate("/adddeposits");
											}}
										>
											<i
												style={{ color: "green", marginRight: "5px" }}
												className="ph-plus-circle"
											></i>
											<span>Add Deposit</span>
										</div> */}
									</div>
								</div>
							</div>
							<div className="content">
								<>
									<div className="card">
									<div className="card-body">
										<form id="w0" onSubmit={handleSubmit}>
											<input
												type="hidden"
												name="_csrf-frontend"
												defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg=="
											/>
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label className="label">From Date</label>
														<input
															type="date"
															class="form-control"
															id="exampleCheck1"
															name="fromdate"
															onChange={(e) => {
																setCredentials({
																	...credentials,
																	fromdate: e.target.value,
																});
															}}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label className="label">To Date</label>
														<input
															type="date"
															class="form-control"
															id="exampleCheck1"
															name="todate"
															onChange={(e) => {
																setCredentials({
																	...credentials,
																	todate: e.target.value,
																	branch: Cookies.get("branch"),
																});
															}}
														/>
													</div>
												</div>
												<div className="col-md-2">
													<button
														type="submit"
														style={{ marginTop: "20px" }}
														className="btn btn-success"
													>
														Submit
													</button>
												</div>

												<div className="col-md-2" style={{ marginTop: "22px" }}>
												
													{/* <Link to='/adddeposits' style={{ cursor: 'pointer',color:'black',textDecoration:'none' }}><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Deposits</Link> */}
												</div>
											</div>
										</form>
										{pending ? (
											<>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<div>
															{" "}
															All list of <code>Requests</code>
														</div>
													</div>
											
												<div id="w0" className="grid-view">
													<table
														className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
														id="myTable"
													>
														<thead>
															<tr>
																<th>#</th>
																<th>Name</th>
																<th>Email</th>
																<th>Phone No.</th>
																<th>Account NO.</th>
																<th>IFSC Code</th>
																<th>UPI</th>
																<th>Amount</th>
																<th>Date</th>
																
															</tr>
														</thead>
														<tbody>
															{pending &&
																pending.map((e) => {
																	
																	console.log(e);
																	count++;
																	return (
																		<tr key={e.id} >
																			<td>{count}</td>
																			<td>{e.name}</td>
																			<td>{e.email}</td>
																			<td>{e.phone_num}</td>
																			<td>{e.account_no}</td>
																			<td>{e.ifsc_code}</td>
																			<td>{e.upi_id}</td>
																			<td>{e.redeem_amount}</td>
																			<td>{e.request_date_time}</td>
																			
																		</tr>
																	);
																})}
														</tbody>
													</table>
												</div>
											</>
										) : (
											""
											)}
											</div>
								</div>
								</>
							</div>
						</div>
					</div> </div> </div> </div> );
}
