import Cookies from 'js-cookie';
import React, { useEffect, useContext } from 'react'
import HandleContext from '../../../Context/HandleContext'
import AddRole from './AddRole';
import UpdateRole from './UpdateRole';
import $ from 'jquery'
import DataTables from 'datatables.net-dt';
import Sidebar from '../../Sidebar';
import Navbar from '../../Navbar';
import { Link } from 'react-router-dom';

export default function UserRoles() {

  setTimeout(() => {
    let searchid=document.getElementById('myTable2_filter')
    let id=document.getElementById('myTable2_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);
  
  $(document).ready(function () {
    $('#myTable2').DataTable();
  });

    const context = useContext(HandleContext);
    const { GetUserRole, data,PostData } = context;
  
    useEffect(() => {
      GetUserRole("userRole")
    }, [])
    console.log(data);

    const Delete = (id) => {
        PostData('userRole/delete_role', JSON.stringify({ role_id: id }))
        setTimeout(() => {
            GetUserRole("userRole")
        }, 200);
      }

    let count=0;
  return (
    <> <div>
    <div>
 <Navbar />
 <div className='page-content'>
   <Sidebar />
   <div class="page-content">
     <div class="content-wrapper">
       <div class="page-header page-header-light">
         <div class="page-header-content header-elements-md-inline">
           <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
             <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">User Management</span></h4>

             <div class="header-elements ">
               {/* <div class="d-flex justify-content-center">
           <Link class="btn btn-link btn-float text-default" to="/addarea"><i class="ph-plus-circle text-primary"></i>
           <div style={{color:'black',fontWeight:'500'}}>Add Area</div></Link>    
                       </div> */}
             </div>
           </div>
           <hr style={{ margin: "0px" }} />

         </div>

         <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
           <div class="d-flex">
             <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">User Management</span></ul>

             <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
           </div>
         </div>
       </div>
       <div className='content'>
                 {/* Add user role */}

     <div class="modal fade" id="staticBackdrop10" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add User Role</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddRole />
          </div>
        </div>
      </div>
      {/* UPdate user role */}
     <div class="modal fade" id="staticBackdrop19" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update Role</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UpdateRole />
          </div>
        </div>
      </div>
  
    <div className='card' >

    {/* Table with togglable columns */}
    {data ? <>
      <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>   All list of <code>User Roles</code></div>
        <div style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#staticBackdrop10"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Role</div>
      </div>
      <div id="w0" className="grid-view">
        <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable2">
          <thead>
            <tr>
              <th>#</th>
              <th> Name</th>
              <th className="action-column">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((e) => {
              console.log(e);
              count++;
              return <tr key={e.id}>
                <td>{count}</td>
                <td>{e.role_name}</td>
                <td >
                  <button className='btn btn-success btn-sm' onClick={() => {
                   console.log(e.id);
                   Cookies.set('role_id',e.id)
                  }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop19" ><i className='ph-note-pencil'></i></button>
                  <button className='btn btn-danger btn-sm' onClick={() => {
                    console.log(e.id);
                    Delete(e.id)
                  }}><i className='ph-trash'></i></button>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </> : ""}
       </div>
     </div>
   </div>
 </div>
</div>
</div>

  </div>
  </>
  )
}
