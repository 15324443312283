import React,{useContext,useState} from 'react'
import HandleContext from '../Context/HandleContext'
import { Link } from 'react-router-dom';

export default function ForgotPassword() {

  const context = useContext(HandleContext);
  const { PostData} = context;

  const [credentials, setCredentials] = useState({username:""})

  const handleChange=(e)=>{
    setCredentials({...credentials,[e.target.name]:e.target.value})
  }
  
  const handleSubmit=async(e)=>{
    e.preventDefault();
    PostData('user/forgotpassword',JSON.stringify(credentials))
  }
  
  return (
    <div>
      <div className="content d-flex justify-content-center align-items-center" style={{transform:'translate(0px,100px)'}}>
         
				{/* Password recovery form */}
<form className="login-form" onSubmit={handleSubmit}>
  <div className="card mb-0">
    <div className="card-body">
      <div className="text-center mb-3">
        <div className="d-inline-flex bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-3 mb-3 mt-1">
          <i className="ph-arrows-counter-clockwise ph-2x" />
        </div>
        <h5 className="mb-0">Password recovery</h5>
        <span className="d-block text-muted">We'll send you instructions in email</span>
      </div>
      <div className="mb-3">
        <label className="form-label">Your email</label>
        <div className="form-control-feedback form-control-feedback-start">
          <input type="email" className="form-control" placeholder="john@doe.com" name='username' value={credentials.username} required onChange={handleChange}/>
          <div className="form-control-feedback-icon">
            <i className="ph-at text-muted" />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3 mt-3">

      <Link to="/login" className="text-center">Back to Login</Link>
</div>
      <button type="submit" className="btn btn-success w-100 mt-3">
        <i className="ph-arrow-counter-clockwise me-2" />
        Reset password
      </button>
    </div>
  </div>
</form>
{/* /password recovery form */}

        </div>
    </div>
  )
}
