import React, { useEffect, useContext, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import HandleContext from '../Context/HandleContext';

export default function Profile() {
  const context = useContext(HandleContext);
  const { Profile, profile, GetUserRole, data, GetSpecificUser, specificuser, PostData, States, state, District, district, City, city, Area, area } = context;

  const [apiData, setApiData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    phone_num: "",
    area: '',
    city: '',
    pincode: '',
    district: '',
    state: '',
    country: '',
    states: '',
    area_id: '',
    user_role_id: ''
  });

  useEffect(() => {
    Profile()


  }, [])
  useEffect(() => {
    setApiData(profile);
    setFormData(profile);
  }, [profile])


  // console.log(formData.states);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('hb');
    console.log(formData);
    console.log(formData.user_role_id);
    console.log(formData.name);
    PostData("user/update_profile", formData)
  }

  return (
    <div>

      <Navbar />
      <div className='page-content'>
        <Sidebar />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="page-header page-header-light">
              <div className="page-header-content header-elements-md-inline">
                <div className="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                  <h4> <span className="font-weight-semibold">User Profile</span></h4>
                </div>
                <hr style={{ margin: "0px" }} />

              </div>

              <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                <div className="d-flex">
                  <ul className="breadcrumb"><Link to="/">Home</Link><Link to="/" className="breadcrumb-item"><i style={{ marginRight: '6px' }} className="ph-house-line mr-4"></i> Home </Link><span className="breadcrumb-item active">User Profile</span></ul>

                  <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                </div>
              </div>
            </div>
            <div className='content'>
              <div className='card'>
                <div className='card-header'>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: '600' }}> User Profile</div>

                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className="form-group col-md-4">
                        <label htmlFor="exampleInputEmail1">User Name</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={formData && formData.name} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="exampleInputPassword1">Address</label>
                        <input type="text" className="form-control" id="exampleInputPassword1" name="address" value={formData && formData.address} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="exampleInputPassword1">Email</label>
                        <input type="email" className="form-control" value={formData && formData.email} id="exampleInputPassword1" />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="exampleInputPassword1">Phone Number</label>
                        <input type="text" className="form-control" id="exampleInputPassword1" name="phone_num" value={formData && formData.phone_num} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="exampleInputPassword1">State</label>
                        <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                          Cookies.set('state_id', e.target.value)
                          District(JSON.stringify({ state_id: Cookies.get('state_id') }))
                        }} required >
                          <option>{formData && formData.district}</option>
                          {state && state.map((e) => {
                            if (e.id === formData && formData.state_id)
                              return <option value={e.id} selected>{e.name}</option>
                            else
                              return <option value={e.id}>{e.name}</option>
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="exampleInputPassword1">District</label>
                        <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                          Cookies.set('district_id', e.target.value)
                          City(JSON.stringify({ district_id: Cookies.get('district_id') }))
                        }} required>
                          <option>{formData && formData.district}</option>
                          {district && district.map((e) => {
                            if (e.id === formData && formData.district_id)
                              return <option value={e.id} selected>{e.name}</option>
                            else
                              return <option value={e.id}>{e.name}</option>
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="exampleInputPassword1">City</label>
                        <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e) => {
                          Cookies.set('city_id', e.target.value)
                          Area(JSON.stringify({ city_id: Cookies.get('city_id') }))
                        }} required >
                          <option>{formData && formData.city}</option>
                          {city && city.map((e) => {
                            if (e.id === formData && formData.city_id)
                              return <option value={e.id} selected>{e.name}</option>
                            else
                              return <option value={e.id}>{e.name}</option>
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="exampleInputPassword1">Area</label>
                        <select id="usercities-state" class="form-control select-search" name="area_id" value={formData && formData.area_id} onChange={handleChange} required >
                          <option value={formData && formData.area_id}>{formData && formData.area}</option>
                          {area && area.map((e) => {
                            if (e.id === formData && formData.area_id)
                              return <option value={e.id} selected>{e.name}</option>
                            else
                              return <option value={e.id}>{e.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Save</button>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
