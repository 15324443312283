import React, { useEffect, useContext } from 'react'
import Cookies from 'js-cookie';
import LOGO from '../../../Images/earth.jpg'
import HandleContext from '../../../Context/HandleContext'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'

import AddWaste from './AddWaste';
import UpdateWaste from './UpdateWaste';
export default function AllWaste() {
  setTimeout(() => {
    let searchid=document.getElementById('myTable3_filter')
    let id=document.getElementById('myTable3_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);
  $(document).ready(function () {
    $('#myTable3').DataTable();
  });
let count=0;
  const context = useContext(HandleContext);
  const { GetAllWaste, allWaste, PostData } = context;

  console.log(allWaste);

  useEffect(() => {
    GetAllWaste("product")
  }, [])

    let navigate=useNavigate()
    return (
        <div>
              {/* Add Waste  */}
         <div class="modal fade" id="staticBackdrop15" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">Add Waste</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <AddWaste/>
              </div>
            </div>
          </div>
              {/* Upadate Waste Category */}
    
         <div class="modal fade" id="staticBackdrop17" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">Update Category</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <UpdateWaste/>
              </div>
            </div>
          </div>
            <div className='card' style={{ marginTop: '30px' }}>
                    {allWaste ? 
                    <>
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>   All <code>Waste Products</code></div>
                            <div style={{ cursor: 'pointer' }} onClick={()=>{navigate('/addwaste')}}><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Waste</div>
                        </div>
                        <div id="w0" className="grid-view">
                            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable3">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Waste Category</th>
                                        <th>Waste Name</th>
                                        <th>Unit Of Meausure</th>
                                        <th>Sale Price</th>
                                        <th>Purchase Price</th>
                                        <th>GST</th>
                                        <th>Waste Type</th>
                                        {/* <th>Status</th> */}
                                        <th className="action-column">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {allWaste && allWaste.map((e) => {
                                  count++;
                                       return <tr>
                                            <td>{count}</td>
                                            <td>{e.category}</td>
                                            <td>{e.product_name}</td>
                                            <td>{e.unit}</td>
                                            <td>{e.sale_price}</td>
                                            <td>{e.purchase_price}</td>
                                            <td>{e.gst}</td>
                                            <td>{e.waste_type}</td>
                                            {/* <td> */}
                                              {/* <div class="form-check pl-0">
  <input id="stackedCheck1" class="form-check-input" type="checkbox" data-toggle="toggle" checked/>
  <label for="stackedCheck1" class="form-check-label">Enabled</label>
</div> */}
{/* </td> */}

                                            <td >
                                                <button className='btn btn-success btn-sm' onClick={() => {
                                                    // console.log(e.id);
                                                    Cookies.set('waste_id', e.id)
                                                }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop17" ><i className='ph-note-pencil'></i></button>
                                                
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </> 
                    : ""} 
                </div>
        </div>
      )
    }
    