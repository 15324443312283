import React, { useEffect, useContext, useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import '../Style.css'
import AddState from './AddState'
import UpdateState from './UpdateState'

export default function State() {

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const { GetData, data, States, state, PostData } = context;

  const [credentials, setCredentials] = useState({ name: "", country_id: '1' })

  useEffect(() => {
    States(JSON.stringify({ username: '' }))
  }, [])

  const Delete = (id) => {
    PostData('state/delete_state', JSON.stringify({ state_id: id }))
    setTimeout(() => {
      States(JSON.stringify({ username: '' }))
    }, 200);
  }

  let count = 0;

  // console.log(state);

  return (
    <>


      {/*Add State Modal */}
      {/* <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add State</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddState/>
          </div>
        </div>
      </div> */}

      {/*Update State Modal */}
      {/* <div class="modal fade" id="staticBackdrop2" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update State</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UpdateState/>
          </div>
        </div>
      </div> */}

      <div className='card' style={{ marginTop: '30px' }}>
        {/* <div className='card-header'>
      <form id="w0" onSubmit={handleSubmit}>
        <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
        <div className='row'>
          <div className='col-md-6'>
        <div className="form-group">
          <label className='label'>Select Country</label>
          <select id="usercities-state" class="form-control select-search" name="country_id" onChange={(e)=>{
                Cookies.set('country_id',e.target.value)
          }}  required >
            <option >India</option>
            {data && data.map((e)=>{
              console.log(e);
              return <option value={e.id}>{e.name}</option>
            })}
          </select>
        </div>
        </div>
        <div className='col-md-6' style={{marginTop:"22px"}}>
          
        <button type='submit' className="btn btn-success " >Submit</button>
        </div>
          
        </div>
      </form>
      </div> */}
        {/* </div> */}

        {/* Table with togglable columns */}
        {state ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>   All list of <code>States</code></div>
            {/* <div style={{ cursor: 'pointer' }}  data-toggle="modal" data-target="#staticBackdrop"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add State</div> */}
          </div>
          <div id="w0" className="grid-view">
            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="DataTables_Table_0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>State</th>
                  {/* <th className="action-column">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {state && state.map((e) => {
                  // console.log(e);
                  count++;
                  return <tr key={e.id}>
                    <td>{count}</td>
                    <td>{e.name}</td>
                    {/* <td >
                      <button className='btn btn-success btn-sm' data-toggle="modal" data-target="#staticBackdrop2" onClick={() => {
                        Cookies.set('state_id', e.id)
                      }} style={{ marginRight: '6px' }}><i className='ph-note-pencil'></i></button>
                      <button className='btn btn-danger btn-sm' onClick={() => {
                        console.log(e.id);
                        Delete(e.id)
                      }}><i className='ph-trash'></i></button>
                    </td> */}
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
      {/* /table with togglable columns */}



    </>
  )
}
