import React, { useEffect, useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Content from '../Content'
import { Link } from 'react-router-dom'
import HandleContext from '../../Context/HandleContext'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import $ from 'jquery';
import DataTables from 'datatables.net-dt'

export default function Completed() {
  setTimeout(() => {
    let searchid=document.getElementById('myTable_filter')
    let id=document.getElementById('myTable_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);

  $(document).ready(function () {
    $('#myTable').DataTable();
  });

  let navigate = useNavigate()
  const context = useContext(HandleContext);
  const {Transitions, transitions } = context;

  useEffect(() => {
    Transitions("wastePickupRequests/requests",JSON.stringify({branch:Cookies.get('branch'),status:'1'}))
  }, [])

  console.log(transitions);

  let count = 0;

  return (
   
           <>
      <div className='card' style={{marginTop:'30px'}}>
       
        {transitions ? <>
          <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>   All list of <code>Pickup Requests</code>
            </div>
          </div>
          <div id="w0" className="grid-view">
            <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable">
              <thead>
                <tr  >
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Request No.</th>
                  <th>Request Image</th>
                  <th>Approx Weight</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>
                {transitions && transitions.map((e) => {
                  console.log(e);
                  count++;
                  let status=e.request_status;
                  if(status=='0'){
                    status='Pending'
                  }
                  else if(status=='1'){
                    status='Completed'
                  }
                  else if(status=='2'){
                    status='Rejected'
                  }
                  else{
                    status='Accepted'
                  }
                  return <tr key={e.id} >
                    <td>{count}</td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.phone_num}</td>
                    <td>{e.request_no}</td>
                    <td><img src={e.request_image} style={{ width: '80px', height: '30px' }} /></td> 
                   <td>{e.approx_weight}</td>
                   <td>{status}</td>
                 
                    
                   
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </> : ""}
      </div>
    </>
        
   
  )
}
