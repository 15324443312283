import Cookies from 'js-cookie';
import React, { useEffect, useContext } from 'react'
import HandleContext from '../../../Context/HandleContext'
import { useNavigate } from 'react-router-dom';
import AddBranchTypes from './AddBranchTypes';
import $ from 'jquery'


export default function AllBranchTypes() {
  $(document).ready(function () {
    $('#myTable5').DataTable();
  });
  setTimeout(() => {
    let searchid=document.getElementById('myTable5_filter')
    let id=document.getElementById('myTable5_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);
  let navigate=useNavigate()
  
  const context = useContext(HandleContext);
  const { GetAllBranchType, branchType,PostData } = context;

  useEffect(() => {
    GetAllBranchType("branchType")
  }, [])
  console.log(branchType);
let count=0;
 
  return (
    <>
         <div class="modal fade" id="staticBackdrop21" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add Branch Type</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddBranchTypes/>
          </div>
        </div>
      </div>
   
    <div className='card' style={{ marginTop: '30px' }}>

    {/* Table with togglable columns */}
    {branchType ? <>
      <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>   All list of <code>Branches Types</code></div>
        <div data-toggle="modal" data-target="#staticBackdrop21" style={{ cursor: 'pointer' }} ><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Branch Type</div>
        
      </div>
      <div id="w0" className="grid-view">
        <table id='myTable5' className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" >
          <thead>
            <tr>
              <th>#</th>
              <th>Branch Type Name</th>
              {/* <th className="action-column">Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {branchType && branchType.map((e) => {
              console.log(e);
              count++;
              return <tr key={e.id}>
                <td>{count}</td>
                <td>{e.branch_type}</td>
                {/* <td >
                  <button className='btn btn-success btn-sm' onClick={() => {
                   console.log(e.id);
                   Cookies.set('role_id',e.id)
                  }} style={{ marginRight: '6px' }} branchType-toggle="modal" branchType-target="#staticBackdrop19" ><i className='ph-note-pencil'></i></button>
                  <button className='btn btn-danger btn-sm' onClick={() => {
                    console.log(e.id);
                    Delete(e.id)
                  }}><i className='ph-trash'></i></button>
                </td> */}
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </> : ""}
  </div>
  </>
  )
}
