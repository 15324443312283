import React from 'react'
import PickupRequest from './PickupRequest'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'
import Pending from './Pending'
import Completed from './Completed'
import Rejected from './Rejected'
import Accepted from './Accepted'

export default function Transitions() {
  return (
    <div>
    <div>
 <Navbar />
 <div className='page-content'>
   <Sidebar />
   <div class="page-content">
     <div class="content-wrapper">
       <div class="page-header page-header-light">
         <div class="page-header-content header-elements-md-inline">
           <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
             <h4> <span style={{fontSize:'18px'}} class="font-weight-semibold">Transitions</span></h4>

             <div class="header-elements ">
    
             </div>
           </div>
           <hr style={{ margin: "0px" }} />

         </div>

         <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
           <div class="d-flex">
             <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Transitions</span></ul>

             <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
           </div>
         </div>
       </div>
       <div className='content'>
         <nav style={{borderRadius:'0px'}}>
           <div class="nav nav-tabs" id="nav-tab" role="tablist" style={{color:'black'}}>
             <button class="nav-link active" id="nav-branch-tab" data-toggle="tab" data-target="#nav-branch" type="button" role="tab" aria-controls="nav-branch" aria-selected="false">All Requests</button>
             <button class="nav-link " id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Pending </button>
             <button class="nav-link " id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Completed </button>
             <button class="nav-link " id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Rejected </button>
             <button class="nav-link " id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Accepted </button>
            
     
           </div>
         </nav>
         <div class="tab-content" id="nav-tabContent">
           <div class="tab-pane fade show " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><PickupRequest/></div>
           <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><Pending/></div>
           <div class="tab-pane fade show active" id="nav-branch" role="tabpanel" aria-labelledby="nav-branch-tab"><Completed/></div>
           <div class="tab-pane fade show active" id="nav-branch" role="tabpanel" aria-labelledby="nav-branch-tab"><Rejected/></div>
           <div class="tab-pane fade show active" id="nav-branch" role="tabpanel" aria-labelledby="nav-branch-tab"><Accepted/></div>
         
         </div>
        
       </div>
     </div>
   </div>
 </div>
</div>
</div>
  )
}
