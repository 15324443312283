import React, { useEffect, useContext, useState } from "react";
import Sidebar from "./../../Sidebar";
import Navbar from "../.././Navbar";
// import Content from '../Content'
import { Link } from "react-router-dom";
import HandleContext from "../../../Context/HandleContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import DataTables from "datatables.net-dt";

export default function DepositDetail() {
    const context = useContext(HandleContext);
    const { GetSpecificUser, specificuser } = context;

    useEffect(() => {
        GetSpecificUser("wastePickupRequests/get_deposit", JSON.stringify({ id: Cookies.get('deposit_id') }))
    }, [])
    console.log(specificuser);
let count=0;
    return (
        <div>
            <div>
                <Navbar />
                <div className="page-content">
                    <Sidebar />
                    <div class="page-content">
                        <div class="content-wrapper">
                            <div class="page-header page-header-light">
                                <div class="page-header-content header-elements-md-inline">
                                    <div
                                        class="page-title d-flex"
                                        style={{ justifyContent: "space-between" }}
                                    >
                                        <h4>
                                            {" "}
                                            <span
                                                style={{ fontSize: "18px" }}
                                                class="font-weight-semibold"
                                            >
                                                Deposits
                                            </span>
                                        </h4>

                                        <div class="header-elements "></div>
                                    </div>
                                    <hr style={{ margin: "0px" }} />
                                </div>

                                <div
                                    class="breadcrumb-line breadcrumb-line-light header-elements-md-inline"
                                    style={{
                                        paddingLeft: "14px",
                                        marginBottom: "6px",
                                        paddingRight: "20px",
                                    }}
                                >
                                    <div class="d-flex">
                                        <ul class="breadcrumb">
                                            <Link to="/">Home</Link>
                                            <Link to="/" class="breadcrumb-item"> <i
                                                style={{ marginRight: "6px" }}
                                                class="ph-house-line mr-4" ></i>
                                                Home
                                            </Link>
                                            <span class="breadcrumb-item active">Deposit</span>
                                        </ul>

                                        <a
                                            href="#"
                                            class="header-elements-toggle text-default d-md-none"
                                        >
                                            <i className="icon-more"></i>
                                        </a>
                                        <div style={{ flexGrow: 1 }}></div>

                                    </div>
                                </div>
                            </div>
                            <div className="content">
                            <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                              
                                            <div className='heads'>
                                                        <span className='label'>Transaction Id</span>
                                                        <span className='value'>{specificuser && specificuser.collection_id}</span>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div> <div className="col-md-6"> <div className="card"> <div className="card-body"> <div className='heads'> <span className='label'>Transaction Date</span> <span className='value'>{specificuser && specificuser.tdate}</span> </div> </div> </div> </div>
                                   
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>User Detail</h4>
                                                <div className='heads'>
                                                    <span className='label'>Name</span>
                                                    <span className='value'>{specificuser && specificuser.name}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Email</span>
                                                    <span className='value'>{specificuser && specificuser.email}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Address</span>
                                                    <span className='value'>{specificuser && specificuser.address}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Contact</span>
                                                    <span className='value'>{specificuser && specificuser.phone_num}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>Branch Detail</h4>
                                                <div className='heads'>
                                                    <span className='label'>Branch Name</span>
                                                    <span className='value'>{specificuser && specificuser.branch_name}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Branch Email</span>
                                                    <span className='value'>{specificuser && specificuser.branch_email}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Branch Address</span>
                                                    <span className='value'>{specificuser && specificuser.branch_address}</span>
                                                </div>
                                                <div className='heads'>
                                                    <span className='label'>Branch Contact</span>
                                                    <span className='value'>{specificuser && specificuser.branch_contact}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>Enviorment Saved</h4>
                                                {specificuser && specificuser.environment_saved.map((e) => {
                                                    return<>
                                                     <div className='heads'>
                                                        <span className='label'>{e.environment_parameter}</span>
                                                        <span className='value'>{e.param_amount}</span>
                                                    </div>
                                                    
                                                    <hr/>
                                                    </>
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>Category Wise Collection</h4>
                                                {specificuser && specificuser.category_wise_collection.map((e) => {
                                                    console.log(e);
                                                    return<>
                                                     <div className='heads'>
                                                        <span className='label'>{e.param}</span>
                                                        <span className='value'>{e.amount}</span>
                                                    </div>
                                                 
                                                    <hr/>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4>Products</h4>
                                              
                                                    <table
														className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer"
														id="myTable"
													>
														<thead>
															<tr>
																<th>#</th>
																<th>Product Name</th>
																<th>Quantity</th>
																<th>Rate</th>
																<th>Sub Total</th>
															</tr>
														</thead>
														<tbody>
                                                        {specificuser && specificuser.products.map((e) => {
                                                    console.log(e.product_name);
                                                    count++
                                                    return <>
                                                    
																	
																		<tr key={e.id}>
																			<td>{count}</td>
																			<td>{e.product_name}</td>
																			<td>{e.quom} {e.uom}</td>
																			<td>{e.rate}</td>
																			<td>{e.total_amount}</td>
																			
																		</tr>
																
                                                                        </>
                                                })}
<tr>
    <td></td>
    <td></td>
    <td></td>
    <td>Grand Total</td>
    <td>{specificuser && specificuser.transaction_amount}</td>
</tr>
														</tbody>
													</table>
                                                    
                                                     
                                                   
                                                       
                                                  
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
