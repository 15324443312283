import Cookies from 'js-cookie';
import React, { useEffect, useContext } from 'react'
import HandleContext from '../../Context/HandleContext'
import AddProcess from './AddProcess';
import Updateprocess from './Updateprocess';
import $ from 'jquery';

export default function WasteProcess() {
  setTimeout(() => {
    let searchid=document.getElementById('myTable_filter')
    let id=document.getElementById('myTable_length');
    id.style.padding='10px'
    searchid.style.padding='10px'
  }, 2000);
  $(document).ready(function () {
    $('#myTable').DataTable();
  });
    const context = useContext(HandleContext);
    const { GetWasteProcess, wasteprocess, PostData } = context;

    useEffect(() => {
        GetWasteProcess("wasteProcess")
    }, [])
    // console.log(data);

    // const Delete = (id) => {
    //     PostData('userRole/delete_role', JSON.stringify({ role_id: id }))
    //     setTimeout(() => {
    //         GetUserRole("userRole")
    //     }, 200);
    //   }

    let count = 0;
    return (
        <>
              {/* Add Waste Process */}

     <div class="modal fade" id="staticBackdrop13" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Add Process</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddProcess/>
          </div>
        </div>
      </div>
      {/* UPdate Waste Process */}
     <div class="modal fade" id="staticBackdrop14" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Update Process</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Updateprocess />
          </div>
        </div>
      </div>
            <div className='card' style={{ marginTop: '30px' }}>
                {wasteprocess ? <>
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>   All <code>Waste Process</code></div>
                        <div style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#staticBackdrop13"><i style={{ color: 'green' }} className='ph-plus-circle'></i> Add Process</div>
                    </div>
                    <div id="w0" className="grid-view">
                        <table className="table datatable-reorder table-togglable table-hover footable footable-1 breakpoint-lg dataTable no-footer" id="myTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Process Name</th>
                                    <th>Solution Name</th>
                                    <th className="action-column">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wasteprocess && wasteprocess.map((e) => {
                                    // console.log(e);
                                    count++;
                                    <Updateprocess name={e.process_name} />
                                    return <tr key={e.id}>
                                        <td>{count}</td>
                                        <td>{e.process_name}</td>
                                        <td>{e.machine_name}</td>
                                        <td >
                                            <button className='btn btn-success btn-sm' onClick={() => {
                                                console.log(e.id);
                                                Cookies.set('process_id', e.id)
                                            }} style={{ marginRight: '6px' }} data-toggle="modal" data-target="#staticBackdrop14" ><i className='ph-note-pencil'></i></button>
                                            {/* <button className='btn btn-danger btn-sm' onClick={() => {
                                                console.log(e.id);
                                            }}><i className='ph-trash'></i></button> */}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </> : ""}
            </div>
        </>
    )
}
