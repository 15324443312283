import React, { useContext, useState } from 'react'
import HandleContext from '../Context/HandleContext'
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar'
import Navbar from './Navbar'

export default function ChangePassword() {
    const context = useContext(HandleContext);
    const { PostData } = context;

    const [credentials, setCredentials] = useState({ old_password: "",password:"",confirm_password:""})

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        PostData('user/change_password', JSON.stringify(credentials))
    }

    return (
        <>

            <Navbar />
            <div className='page-content'>
                <Sidebar />
                <div class="page-content">
                    <div class="content-wrapper">
                        <div class="page-header page-header-light">
                            <div class="page-header-content header-elements-md-inline">
                                <div class="page-title d-flex" style={{ justifyContent: 'space-between' }}>
                                    <h4> <span class="font-weight-semibold">Change Password</span></h4>


                                </div>
                                <hr style={{ margin: "0px" }} />

                            </div>

                            <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" style={{ paddingLeft: '14px', marginBottom: '6px' }}>
                                <div class="d-flex">
                                    <ul class="breadcrumb"><Link to="/">Home</Link><Link to="/" class="breadcrumb-item"><i style={{ marginRight: '6px' }} class="ph-house-line mr-4"></i> Home </Link><span class="breadcrumb-item active">Change Password</span></ul>

                                    <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                {/* Content area */}
                                <div className="content">
                                    {/* Table with togglable columns */}

                                    <form className="login-form" onSubmit={handleSubmit}>
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                <div className="text-center mb-3">
                                                    <div className="d-inline-flex bg-primary bg-opacity-10 text-primary lh-1 rounded-pill p-3 mb-3 mt-1">
                                                        <i className="ph-arrows-counter-clockwise ph-2x" />
                                                    </div>
                                                    <h5 className="mb-4">Change Your Password</h5>

                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">Old Password</label>
                                                    <div className="form-control-feedback form-control-feedback-start">
                                                        <input type="password" className="form-control" placeholder="•••••••••••" name='old_password' value={credentials.username} required onChange={handleChange} />
                                                        <div className="form-control-feedback-icon">
                                                            <i className="ph-lock text-muted" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">New Password</label>
                                                    <div className="form-control-feedback form-control-feedback-start">
                                                        <input type="password" className="form-control" placeholder="•••••••••••" minLength='6' name='password' value={credentials.username} required onChange={handleChange} />
                                                        <div className="form-control-feedback-icon">
                                                            <i className="ph-lock text-muted" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">Confirm Password</label>
                                                    <div className="form-control-feedback form-control-feedback-start">
                                                        <input type="password" className="form-control" placeholder="•••••••••••" name='confirm_password' value={credentials.username} required onChange={handleChange} />
                                                        <div className="form-control-feedback-icon">
                                                            <i className="ph-lock text-muted" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-success w-100 mt-3">
                                                    <i className="ph-arrow-counter-clockwise me-2" />
                                                    Set password
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </>
    )
}
