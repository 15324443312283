import Cookies from 'js-cookie';
import React, { useState, useContext, useEffect } from 'react'
import HandleContext from '../../../Context/HandleContext'

export default function AddCategory() {
  const context = useContext(HandleContext);
  const { GetWasteCategory, wastecategory, AddCategory } = context;

  const [credentials, setCredentials] = useState({ category_name: "", file: "", parent_category: "" })
  useEffect(() => {
    GetWasteCategory("category")
  }, [])

  const addCategory = (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log("Submitted");
    const {category_name, file, parent_category}=credentials
    formData.append("category_name",category_name)
    formData.append("file",file)
    formData.append("parent_category",parent_category)
    console.log(credentials);
    console.log(formData);
    AddCategory( formData)
    setTimeout(() => {
      GetWasteCategory("category")
      setCredentials({ category_name: "", file: "", parent_category: "" })
    }, 200);
  }

  return (
    <form id="w0" onSubmit={addCategory}>
      <div className="modal-body">
        <input type="hidden" name="_csrf-frontend" defaultValue="Bc6b2x1QBHQYHFfazLr_GOPyf8k2V9aco3fXB021NhUzgPSETwFoEUBqGJn63owhlbEz-XQQne_hArE_f95DZg==" />
        <div className="form-group">
          <label>Category Name</label>
          <input type="text" className="form-control" name="category_name" value={credentials.category_name} placeholder='' onChange={(e) => {
            setCredentials({ ...credentials, category_name: e.target.value })
          }} style={{ border: '2px solid darkseagreen' }} required />
        </div>
        <div className="form-group">
          <label>Select Parent Category</label>
          <select id="usercities-state" class="form-control select-search" value={credentials.parent_category} name="parent_category" onChange={(e) => {
            setCredentials({ ...credentials, parent_category: e.target.value })
          }} required >
            <option >-- Select Parent Category --</option>
            {wastecategory && wastecategory.map((e) => {
              return <option>{e.parent_category}</option>
            })}
          </select>
        </div>

        <div className="form-group">
          <input type="file" class="form-control-file" id="exampleFormControlFile1" value={credentials.file} name='file' onChange={(e) => {
            setCredentials({ ...credentials, file: e.target.files[0] })
          }} required />
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Save changes</button>
      </div>
    </form>
  )
}
